import { useState } from 'react';
import { useQuery } from 'react-query';
import { CharacterType } from '../Types/CharacterType';
import { fetchAbilityTemplateByRaceClassKit } from '../../api/apiUtil';
import _ from 'lodash';
import { AbilityScoreType } from '../CharacterCard/CharacterStats';

interface AbilityScoreTypeForm {
  handleSave: (char: Partial<CharacterType>) => void;
  handleClose: () => void;
  classType: string;
  classKit: string;
  raceName: string;
}

export interface AbilityTemplate {
  minStr: number;
  minDex: number;
  minCon: number;
  minWis: number;
  minInt: number;
  minCha: number;
  bonusStr: number;
  bonusDex: number;
  bonusCon: number;
  bonusWis: number;
  bonusInt: number;
  bonusCha: number;
  isFighter?: boolean;
}

export interface AbilityScoreForm {
  abilityScoreTemplate: AbilityTemplate;
  abilityScores: AbilityScoreType;
}

const emptyAbilityScoreState = {
  STR: 0,
  DEX: 0,
  CON: 0,
  WIS: 0,
  INT: 0,
  CHA: 0,
};

const STAT_OPTIONS: { label: string; key: keyof AbilityScoreType }[] = [
  { label: 'Strength', key: 'STR' },
  { label: 'Dexterity', key: 'DEX' },
  { label: 'Constitution', key: 'CON' },
  { label: 'Intelligence', key: 'INT' },
  { label: 'Wisdom', key: 'WIS' },
  { label: 'Charisma', key: 'CHA' },
];
export function AbilityScoreForm(props: AbilityScoreTypeForm) {
  const { handleSave, handleClose, classType, classKit, raceName } = props;
  const [stats, setStats] = useState<AbilityScoreType>(emptyAbilityScoreState);
  const [storedScores, setStoredScores] = useState<AbilityScoreType>();
  const [availablePoints, setAvailablePoints] = useState<number>(0);
  const [ability, setAbility] = useState<keyof AbilityScoreType>();

  const {
    data: abilityScoreForm,
    isLoading,
    isError,
    refetch,
  } = useQuery<AbilityScoreForm>(['ABILITY_SCORE', raceName, classType, classKit], fetchAbilityTemplate, {
    enabled: !!classType && !!classKit && !!raceName,
    onSuccess: handleOnSuccess,
  });

  function handleOnSuccess(data: AbilityScoreForm) {
    if (data.abilityScoreTemplate.isFighter && !data.abilityScores?.FIGHT_STR) {
      const FIGHT_STR = rollD100();
      setStats({ ...data.abilityScores, FIGHT_STR });
    } else {
      setStats(data.abilityScores);
    }
  }

  function handleReRoll() {
    setAvailablePoints(0);
    refetch();
  }

  function fetchAbilityTemplate() {
    return fetchAbilityTemplateByRaceClassKit(raceName, classType, classKit);
  }

  function _handleSave() {
    if (availablePoints > 0) {
      if (window.confirm('Proceed without using all points?')) {
        handleSave({ abilityScores: stats });
      }
    }
    handleSave({ abilityScores: stats });
  }

  const totals = abilityScoreForm?.abilityScores
    ? _.chain(Object.entries(stats))
        .filter((x) => x[0] !== 'FIGHT_STR')
        .map((x) => x[1])
        .sum()
        .value() + availablePoints
    : 0;

  function handleAddPoint(ability: keyof AbilityScoreType) {
    setAbility(ability);
    if (availablePoints > 0 && typeof stats[ability] === 'number') {
      let currentScore = stats[ability] || 0;

      if (canAddPoint(currentScore, ability, abilityScoreForm?.abilityScoreTemplate)) {
        currentScore++;
        setStats({ ...stats, [ability]: currentScore });
        setAvailablePoints(availablePoints - 1);
      }
    }
  }

  function handleMinusPoint(ability: keyof AbilityScoreType) {
    setAbility(ability);
    if (typeof stats[ability] === 'number') {
      let currentScore = stats[ability] || 0;
      if (canSubtractPoint(currentScore, ability, abilityScoreForm?.abilityScoreTemplate)) {
        currentScore--;
        setStats({ ...stats, [ability]: currentScore });
        setAvailablePoints(availablePoints + 1);
      }
    }
  }

  function handleStore() {
    if (stats) {
      setStoredScores(stats);
    }
  }

  function handleRecall() {
    if (storedScores) {
      setStats(storedScores);
    }
  }

  return (
    <div className="flex-col flex-1-8 flex-between">
      <div className="flex flex-center">
        <div className="flex-col es-ch-form__races ">
          {isLoading && 'Loading...'}
          {!isLoading && (
            <>
              {STAT_OPTIONS.map((stat) => {
                const { key, label } = stat;
                return (
                  <div key={label} className="flex f-a-c flex-between">
                    <div className="es-pill es-pill--main-theme flex flex-1 flex-between">
                      <div>{label}</div>
                      <div className="es-char-card__stat">
                        {key === 'STR' && typeof stats['FIGHT_STR'] === 'number' && stats[key] === 18 ? (
                          <span>
                            {stats[key]} / {stats['FIGHT_STR']}{' '}
                          </span>
                        ) : (
                          <span> {stats ? stats[key] : 0}</span>
                        )}
                      </div>
                    </div>
                    <div className="flex flex-center ">
                      <button
                        className="es-button es-button--primary es-button--small es-ch-form__add-button"
                        id={`${stat}Add`}
                        type="button"
                        onClick={() => handleAddPoint(key)}>
                        +
                      </button>
                      <button
                        className="es-button es-button--primary es-button--small es-ch-form__add-button"
                        id={`${stat}Subtract`}
                        type="button"
                        onClick={() => handleMinusPoint(key)}>
                        -
                      </button>
                    </div>
                  </div>
                );
              })}
              <div className="flex flex-between">
                <div className="es-pill es-pill--main-theme flex flex-1 flex-between">
                  <div className="es-char-card__stat-block">Total</div>
                  <span className="es-char-card__stat es-char-card__stat-total">{totals}</span>
                </div>
                <div className="es-pill es-pill--main-theme flex">
                  <div className="es-char-card__stat-block">Unspent</div>

                  <span className="es-char-card__stat es-char-card__stat-total">{availablePoints}</span>
                </div>
              </div>
              <div className="es-ch-form__ability-button-grp">
                <button
                  className="es-button es-button--primary es-ch-form__option-btn"
                  id={'reRoll'}
                  type="button"
                  disabled={isLoading || isError || !abilityScoreForm}
                  onClick={handleReRoll}>
                  REROLL
                </button>
                <button
                  className="es-button es-button--primary es-ch-form__option-btn"
                  id={'storeScores'}
                  type="button"
                  onClick={handleStore}>
                  STORE
                </button>
                <button
                  className="es-button es-button--primary es-ch-form__option-btn"
                  id={'recallScores'}
                  type="button"
                  disabled={typeof storedScores === 'undefined'}
                  onClick={handleRecall}>
                  RECALL
                </button>
              </div>
            </>
          )}
        </div>
        <div className="flex-col flex-between">
          <div className="es-ch-form__description">
            <p>
              {ability
                ? renderAbilityDescription(ability, abilityScoreForm?.abilityScoreTemplate)
                : DEFAULT_ABILITY_TEXT}
            </p>
            {isError && 'Error fetching data'}
          </div>
        </div>
      </div>
      <div className="flex f-a-c flex-center" style={{ margin: '2rem' }}>
        <button
          className="es-button es-button--primary es-ch-form__option-btn"
          id={'cancelCurrentStep'}
          type="button"
          onClick={handleClose}>
          Cancel
        </button>
        <button
          className="es-button es-button--primary es-ch-form__option-btn"
          id={'saveAlignment'}
          type="button"
          onClick={_handleSave}>
          Done
        </button>
      </div>
    </div>
  );
}
function canAddPoint(
  currentPoints: number,
  ability: keyof AbilityScoreType,
  abilityScoreTemplate: AbilityTemplate | undefined
): boolean {
  const max = getMaxPointsForAbility(ability, abilityScoreTemplate);
  return currentPoints < max;
}

function getMaxPointsForAbility(
  ability: keyof AbilityScoreType,
  abilityScoreTemplate: AbilityTemplate | undefined
): number {
  if (abilityScoreTemplate) {
    return (
      {
        STR: abilityScoreTemplate?.bonusStr,
        DEX: abilityScoreTemplate?.bonusDex,
        CON: abilityScoreTemplate?.bonusCon,
        WIS: abilityScoreTemplate?.bonusWis,
        INT: abilityScoreTemplate?.bonusInt,
        CHA: abilityScoreTemplate?.bonusCha,
        FIGHT_STR: 99,
      }[ability] + 18
    );
  }
  return 18;
}

function canSubtractPoint(
  currentPoints: number,
  ability: keyof AbilityScoreType,
  abilityScoreTemplate: AbilityTemplate | undefined
): boolean {
  const min = getMinPointForAbility(ability, abilityScoreTemplate);
  return currentPoints > min;
}

function getMinPointForAbility(
  ability: keyof AbilityScoreType,
  abilityScoreTemplate: AbilityTemplate | undefined
): number {
  if (abilityScoreTemplate) {
    return {
      STR: abilityScoreTemplate?.minStr,
      DEX: abilityScoreTemplate?.minDex,
      CON: abilityScoreTemplate?.minCon,
      WIS: abilityScoreTemplate?.minWis,
      INT: abilityScoreTemplate?.minInt,
      CHA: abilityScoreTemplate?.minCha,
      FIGHT_STR: 0,
    }[ability];
  }
  return 3;
}

function renderAbilityDescription(ability: keyof AbilityScoreType, abilityScoreTemplate?: AbilityTemplate) {
  const minValue = getMinPointForAbility(ability, abilityScoreTemplate);
  const maxValue = getMaxPointsForAbility(ability, abilityScoreTemplate);

  const renderDescription = {
    STR: "Strength measures a character's muscle, endurance, and stamina. It is the prime requisite of Fighters.",
    DEX: "Dexterity measures a character's hand-eye coordination, agility, reflexes, and balance. It is the prime requisite of the Thief.",
    CON: "Constitution measures a character's fitness, health, and physical resistance to hardship, injury, and disease. This ability is important to the Ranger.",
    WIS: "Wisdom measures a character's enlightenment, judgment, and common sense. It is the prime requisite of priests.",
    INT: (
      <span>
        Intelligence measures a character's memory, reasoning, and learning ability. It is the prime requisite of the
        Mage.
        <br /> <br />
        Note: A character with an Intelligence score of 8 or less is illiterate and cannot use scrolls or most wands
        regardless of his class.{' '}
      </span>
    ),
    CHA: "Charisma measures a character's persuasiveness, personal magnetism, and ability to lead. This ability is important to the Druid, Bard, and Paladin.",
    FIGHT_STR: '',
  }[ability];

  return (
    <p>
      {renderDescription} <br />
      <br />
      Minimum: &nbsp; {minValue} <br />
      Maximum: &nbsp; {maxValue} <br />
    </p>
  );
}

const DEFAULT_ABILITY_TEXT =
  'These are the basic statistics that make up your character. Minimums and maximums vary somewhat according to class and race prerequisists.';

function getRandomInt(max: number) {
  return Math.floor(Math.random() * max);
}

function rollD100() {
  return getRandomInt(100) + 1;
}
