import { AbilityScoreType } from '../components/CharacterCard/CharacterStats';
import { AbilityScoreForm } from '../components/CharacterForm/AbilityScoreForm';
import { CharacterType, TGame, ISkillTemplate, ITertiaryStats } from '../components/Types/CharacterType';

export const PARTY_API = '/v1/api/party';
export const REROLL_API = '/v1/api/stats/reroll';
export const DOWNLOAD_API = '/v1/api/download/single/character';
export const DOWNLOAD_PARTY_API = '/v1/api/download/party';

export const GET_PORTAITS_API = '/v1/formApi/portraits';
export const GET_RACES_API = '/v1/formApi/races';
export const GET_CLASSES_API = '/v1/formApi/classes';
export const GET_KITS_API = '/v1/formApi/kits';
export const GET_ALIGNMENTS_API = '/v1/formApi/alignments';
export const GET_ABILITY_TEMPLATE = '/v1/formApi/abilityTemplate';
export const GET_SKILL_TEMPLATE = '/v1/formApi/skillTemplate';
export const GET_TERTIARY_STATS = '/v1/formApi/tertiaryStats';

const csrfToken = document.cookie.replace(/(?:(?:^|.*;\s*)XSRF-TOKEN\s*\=\s*([^;]*).*$)|^.*$/, '$1');


interface ITertiaryStatRequest {
  classType: string;
  kitType: string;
  raceType: string;
  gameType: string;
  abilityScoreMap: AbilityScoreType;
}
export async function fetchTertiaryStatsAPI(request: ITertiaryStatRequest): Promise<ITertiaryStats> {
  return post(GET_TERTIARY_STATS, request)
    .then(handleResponse)
    .catch((error) => {
      throw new Error(error.message);
    });
}

interface ISkillTemplateRequest {
  className: string;
  kitName: string;
  raceName: string;
  gameType: string;
  dex: number;
  wis: number;
}
export async function fetchSkillTemplateAPI(params: ISkillTemplateRequest): Promise<ISkillTemplate> {
  const parameterizedUrl = parameterize(GET_SKILL_TEMPLATE, params);
  return get(parameterizedUrl.toString())
    .then(handleResponse)
    .catch((error) => {
      throw new Error(error.message);
    });
}

export async function fetchAbilityTemplateByRaceClassKit(
  raceName: string,
  className: string,
  kitName: string
): Promise<AbilityScoreForm> {
  return get(`${GET_ABILITY_TEMPLATE}?raceName=${raceName}&className=${className}&kitName=${kitName}`)
    .then(handleResponse)
    .catch((error) => {
      throw new Error(error.message);
    });
}

export async function fetchAlignmentsByClassAndKit(className: string, kitName: string) {
  return get(`${GET_ALIGNMENTS_API}?className=${className}&kitName=${kitName}`)
    .then(handleResponse)
    .catch((error) => {
      throw new Error(error.message);
    });
}

export async function fetchClassKitsByClassRaceGame(gameType: TGame, className: string, raceType: string) {
  return get(`${GET_KITS_API}?gameType=${gameType}&race=${raceType}&className=${className}`)
    .then(handleResponse)
    .catch((error) => {
      throw new Error(error.message);
    });
}

export async function fetchAllClassesByRaceAndGameAPI(gameType: 'BG' | 'IWD', raceType: string) {
  return get(`${GET_CLASSES_API}?gameType=${gameType}&race=${raceType}`)
    .then(handleResponse)
    .catch((error) => {
      throw new Error(error.message);
    });
}

export async function fetchAllRacesAPI() {
  return get(GET_RACES_API)
    .then(handleResponse)
    .catch((error) => {
      throw new Error(error.message);
    });
}

/**
 * fetch all portrait names by game and gender
 */
export async function fetchAllPortraitsByGameAndGender(gameType: 'BG' | 'IWD', gender: 'MALE' | 'FEMALE') {
  return get(`${GET_PORTAITS_API}?gameType=${gameType}&gender=${gender}`)
    .then(handleResponse)
    .catch((error) => {
      throw new Error(error.message);
    });
}

/**
 * fetch Party by game type
 */
export async function fetchPartyByGameType(gameType: 'bg' | 'iwd') {
  return get(`${PARTY_API}/${gameType}`)
    .then(handleResponse)
    .catch((error) => {
      throw new Error(error.message);
    });
}

/**
 * reroll a single character
 */
export function reRollCharacter(character: CharacterType) {
  return post(`${REROLL_API}`, character)
    .then(handleResponse)
    .catch((error) => {
      console.log("error:", error)
      throw new Error(error.message);
    });
}

export function handleDownloadSingleCharacter(character: CharacterType) {
  return post(DOWNLOAD_API, character)
    .then((res) => {
      if (!res.ok) {
        throw new Error('Error downloading file: ' + res.text + ', ' + res.status + ', ' + res.statusText);
      } else {
        return res.arrayBuffer();
      }
    })
    .then((res) => handleDownloadArrayBuffer(res, 'autochar.chr'));
}

export function handleDownloadParty(characters: CharacterType[]) {
  return post(DOWNLOAD_PARTY_API, characters)
    .then((res) => {
      if (!res.ok) {
        throw new Error('Error downloading party: ' + res.text + ', ' + res.status + ', ' + res.statusText);
      } else {
        return res.arrayBuffer();
      }
    })
    .then((res) => handleDownloadArrayBuffer(res, 'party.zip'));
}

/**
 * handles downloading an array buffer to the UI
 * creates an anchor element and assigns a href to the blob in a window object url
 */
function handleDownloadArrayBuffer(res: ArrayBuffer, fileName: string) {
  const elementA = document.createElement('a');
  const blob = new Blob([res], { type: 'application/octet-stream' });
  elementA.href = window.URL.createObjectURL(blob);
  elementA.download = fileName;
  elementA.click();
  elementA.remove();
}

/**
 * general response handler for json data
 * TODO: implement other types (text/error handling)
 */
export function handleResponse(res: Response) {
  if (res.ok) {
    return res.json();
  } else {
    throw new Error(`${res.status}: ${res.statusText}`);
  }
}

/**
 * general 'get' request with fetch API
 */
export function get(url: string, options?: RequestInit) {
  return fetch(url, {
    method: 'GET',
    mode: 'cors',
    credentials: 'same-origin',
    headers: {
      'Content-Type': 'application/json',
    },
    ...options,
  });
}

/**
 * general 'post' request with fetch API
 */
export function post(url: string, request: object, options?: RequestInit) {
  return fetch(url, {
    method: 'POST',
    mode: 'cors',
    body: JSON.stringify(request),
    credentials: 'same-origin',
    headers: {
      'Content-Type': 'application/json',
      'X-XSRF-TOKEN': csrfToken
    },
    ...options,
  });
}

export function parameterize(url: string, params: object) {
  return Object.entries(params).reduce((url, param) => {
    return `${url}${url.indexOf('?') === -1 ? '?' : '&'}${param[0]}=${param[1]}`;
  }, url);
}
