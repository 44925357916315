import { useState } from 'react';
import { useQuery } from 'react-query';
import { CharacterType, TGame } from '../Types/CharacterType';
import { fetchAllClassesByRaceAndGameAPI, fetchClassKitsByClassRaceGame } from '../../api/apiUtil';
import _ from 'lodash';
import cn from 'classnames';
import { CLASS_DESCRIPTIONS } from '../../modules/descriptions';

interface IRaceForm {
  handleSave: (char: Partial<CharacterType>) => void;
  gameType: TGame;
  raceType: string;
  handleClose: () => void;
}
export function ClassForm(props: IRaceForm) {
  const { handleSave, gameType, handleClose, raceType } = props;
  const [selectedClass, setSelectedClass] = useState<string>();
  const [selectedKit, setSelectedKit] = useState<string>();
  const [mode, setMode] = useState<'class' | 'kit'>('class');

  const hasKits = selectedClass && classHasKits(selectedClass);

  const { data: classes = [], isLoading } = useQuery(['CLASSES', gameType, raceType], fetchAllCassesForRaceAndGame, {
    staleTime: 30 * 60 * 60 * 1000,
    enabled: !!gameType,
  });

  const { data: kits = [], isLoading: loadingKits } = useQuery(
    ['KITS', gameType, raceType, selectedClass],
    fetchAllKitsForRaceClassGame,
    {
      staleTime: 30 * 60 * 60 * 1000,
      enabled: !!hasKits,
    }
  );

  function fetchAllCassesForRaceAndGame() {
    return fetchAllClassesByRaceAndGameAPI(gameType, raceType);
  }

  function fetchAllKitsForRaceClassGame() {
    if (typeof selectedClass === 'string') {
      return fetchClassKitsByClassRaceGame(gameType, selectedClass, raceType);
    }
  }

  function _handleSave() {
    if (selectedClass && !hasKits) {
      handleSave({ className: selectedClass, classKit: 'TRUE_CLASS' });
    } else if (selectedClass && hasKits && selectedKit) {
      handleSave({ className: selectedClass, classKit: selectedKit });
    }
  }

  function handleClassSelect(charClass: string) {
    setSelectedClass(charClass);
    if (classHasKits(charClass)) {
      setMode('kit');
      setSelectedKit('TRUE_CLASS');
    }
  }

  function handleBack() {
    if (mode === 'class') {
      handleClose();
    }
    if (mode === 'kit') {
      setMode('class');
      setSelectedKit(undefined);
    }
  }

  return (
    <div className="flex-col flex-between">
      <div className="flex flex-center">
        <div className="flex-col es-ch-form__races ">
          {!isLoading &&
            mode === 'class' &&
            classes.map((charClass: string, i: number) => (
              <button
                key={_.camelCase(charClass) + i}
                className={cn('es-button es-button--primary es-ch-form__option-btn', {
                  'es-button--selected': charClass === selectedClass,
                })}
                id={`${_.camelCase(charClass)}Btn`}
                type="button"
                onClick={() => handleClassSelect(charClass)}>
                {_.lowerCase(charClass)}
              </button>
            ))}
          {!loadingKits &&
            mode === 'kit' &&
            kits.map((kit: string, i: number) => (
              <button
                key={_.camelCase(kit) + i}
                className={cn('es-button es-button--primary es-ch-form__option-btn', {
                  'es-button--selected': kit === selectedKit,
                })}
                id={`${_.camelCase(kit)}Btn`}
                type="button"
                onClick={() => setSelectedKit(kit)}>
                {kit === 'TRUE_CLASS' ? _.lowerCase(selectedClass) : _.lowerCase(kit)}
              </button>
            ))}
        </div>
        <div className="flex-col flex-0-5 es-ch-form__description">
          {mode === 'class' && (
            <p style={{ fontSize: '1rem', padding: '1rem' }}>
              {selectedClass ? CLASS_DESCRIPTIONS[selectedClass] : 'Select a class'}
            </p>
          )}
          {mode === 'kit' && (
            <p style={{ fontSize: '1rem', padding: '1rem' }}>
              {(selectedKit && selectedClass)
                ? selectedKit === 'TRUE_CLASS'
                  ? CLASS_DESCRIPTIONS[selectedClass]
                  : CLASS_DESCRIPTIONS[selectedKit]
                : 'Select a kit'}
            </p>
          )}
        </div>
      </div>
      <div className="flex f-a-c flex-center">
        <button
          className="es-button es-button--primary es-ch-form__option-btn"
          id={'cancelCurrentStep'}
          type="button"
          onClick={handleBack}>
          Back
        </button>
        <button
          className="es-button es-button--primary es-ch-form__option-btn"
          id={'saveRace'}
          type="button"
          onClick={_handleSave}>
          Done
        </button>
      </div>
    </div>
  );
}

function classHasKits(className: string): boolean {
  return typeof className === 'string' && className.split('_').length === 1 && className !== 'SHAMAN';
}
