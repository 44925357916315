import _ from 'lodash';

interface IWeaponForm {
  availablePts: number;
  availableWeapons: string[];
  weaponMap: Record<string, number>;
  handleAddSkill: (weapon: string) => void;
  handleMinusSkill: (weapon: string) => void;
}
export function WeaponForm(props: IWeaponForm) {
  const { availablePts, availableWeapons, weaponMap, handleAddSkill, handleMinusSkill } = props;
  return (
    <>
      <div className="flex">
        <div className="es-pill es-pill--main-theme flex flex-between flex-1-7">
          <div>Proficiency Slots</div>
        </div>

        <div className="es-pill es-pill--main-theme flex flex-between" style={{ width: '2.5rem', textAlign: 'center' }}>
          <div>{availablePts}</div>
        </div>
      </div>
      {availableWeapons.map((weapon: string) => {
        return (
          <div key={weapon} className="flex flex-between f-a-c">
            <div className="es-pill es-pill--main-theme flex flex-between flex-1-7">
              <div className="capital">{_.lowerCase(weapon)}</div>
            </div>

            <div
              className="es-pill es-pill--main-theme flex flex-between"
              style={{ width: '2.5rem', textAlign: 'center' }}>
              <div>{typeof weaponMap[weapon] === 'number' ? weaponMap[weapon] : '0'}</div>
            </div>
            <div className="flex flex-center flex-1">
              <button
                style={{
                  height: '2rem',
                  width: '2rem',
                  margin: '0 5px',
                }}
                className="es-button es-button--primary es-button--small"
                id={`${_.camelCase(weapon)}Add`}
                type="button"
                onClick={() => handleAddSkill(weapon)}>
                +
              </button>
              <button
                style={{
                  height: '2rem',
                  width: '2rem',
                  margin: '0 0 0 5px',
                }}
                className="es-button es-button--primary es-button--small"
                id={`${_.camelCase(weapon)}Subtract`}
                type="button"
                onClick={() => handleMinusSkill(weapon)}>
                -
              </button>
            </div>
          </div>
        );
      })}
    </>
  );
}
