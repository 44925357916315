import { CharacterType } from "./components/Types/CharacterType";


export function hasSpells(character: CharacterType) {
    return (
      hasArcaneSpells(character) ||
      hasDivineSpells(character) ||
      hasDruidSpells(character)
    );
  }
  
  export function hasArcaneSpells(character: CharacterType): boolean {
    const { memorizedArcaneSpells } = character;
    return !!memorizedArcaneSpells && memorizedArcaneSpells.length > 0;
  }
  
  export function hasDivineSpells(character: CharacterType): boolean {
    const { memorizedDivineSpells } = character;
    return !!memorizedDivineSpells && memorizedDivineSpells.length > 0;
  }
  export function hasDruidSpells(character: CharacterType): boolean {
    const { memorizedDruidSpells } = character;
    return !!memorizedDruidSpells && memorizedDruidSpells.length > 0;
  }
  