export const FB_SHARE = 'https://www.facebook.com/sharer/sharer.php?u=https://bgautochar.com';
export const TW_SHARE = 'https://twitter.com/intent/tweet?url=https://bgautochar.com';
export const RD_SHARE = 'https://www.reddit.com/submit?url=https://bgautochar.com';

export const humanDescription =
  'Humans are the predominant race in Faerûn. Humans rule most of the significant empires and kingdoms in the Forgotten Realms. They are the most social and tolerant of races, excepting perhaps the halflings. The only special ability that human possess is that they may advance as any class. Humans are also the only race that can dual-class. Humans may not multi-class.';
export const elfDescription =
  'Elves tend to be shorter and slimmer than humans. Their features are finely chiseled and delicate, and they speak in melodic tones. Elves are looked upon as being frivolous and aloof. They concern themselves with natural beauty, dancing, frolicking, and other similar pursuits. Their humor is clever, as are their songs and poetry. There are six racial divisions of elves within the Realms: gold elves, moon elves, wild elves, sea elves, dark elves (drow), and winged elves (avariel).';
export const halfElfDescription =
  'Half-elves are a mix of human and elven blood. They are handsome folk, with good features from each of their parent races. A half-elf has the curiosity, inventiveness, and ambition of their human ancestors and the refined senses, love of nature, and artistic tastes of their elven ancestors.';
export const dwarfDescription =
  'Dwarves are short and stocky, easily identifiable by their size and shape. They have ruddy cheeks, dark eyes, and dark hair. Dwarves tend to be dour and taciturn. They are given to hard work, and care little for most humor. They enjoy beer, ale, mead, but most of all, they love gold. There are four racial divisions of dwarves within the Realms: shield dwarves, gold dwarves, wild dwarves, and duergar.';
export const gnomeDescription =
  'Kin to dwarves, gnomes are noticeably smaller than their distant cousins. Gnomes, as they proudly maintain, are also less rotund than dwarves. Most have dark tan or brown skin, white hair, and rather large noses. Gnomes have lively and sly senses of humor, especially for practical jokes. They have a love for nature that is only matched by their love for gems and jewelry. There are two racial divisions of gnomes within the Realms: rock gnomes and deep gnomes (svirfneblin).';
export const halflingDescription =
  'Halflings are short, generally plump people, very much like small humans. Their faces are round and broad, and often quite florid. Their hair is typically curly, and the tops of their feet are covered with coarse hair. Overall they prefer the comforts of home to dangerous adventuring. They enjoy good living, rough humor, and homespun tales. There are three racial divisions of halflings within the Realms: the hairfoot, tallfellow, and stout.';
export const halfOrcDescription =
  "Half-orcs are born from the union of human and orc parents. They are as tall as humans, but a little heavier due to their muscular builds. Their greenish pigmentation, sloping forehead, jutting jaw, prominent teeth, and coarse body hair make their lineage plain for all to see. In the Sword Coast, half-orcs are tolerated, as unlike in the north the local people haven't had centuries of warfare with orc kind. Half-orcs are known for their great strength.";

export const raceDescriptions: Record<string, string> = {
  human: humanDescription,
  elf: elfDescription,
  halfElf: halfElfDescription,
  dwarf: dwarfDescription,
  gnome: gnomeDescription,
  halfling: halflingDescription,
  halfOrc: halfOrcDescription,
};

export const classDescriptions: Record<string, string> = {
  fighter:
    'The Fighter is a champion, swordsman, swordswoman, soldier, and brawler who lives or dies by the knowledge of weapons and tactics. Fighters can be found at the front of any battle, contesting toe-to-toe with monsters and villains. A good Fighter needs to be strong and healthy if they hope to survive.',
  berserker:
    "This is a warrior who is in tune with animalistic side and, during combat, can achieve an ecstatic state of mind that will enable one to fight longer, harder, and more savagely than any person has a right to. Berserkers tend to be barbarian-like in nature, but not always. Sometimes it is a conscious choice that a warrior in training makes. Regardless, opponents on the battlefield will be unsettled when they see the savage and inhuman elements of the Berserker's personality. This class is common amongst dwarves, known to them as the Battlerager.",
  wizardSlayer:
    "This warrior has been specially trained by one's sect to excel in hunting and combating spellcasters of all kinds.",
  kensai:
    "This class's name, which means 'sword saint' in the common tongue, refers to a warrior who has been specially trained to be one with his weapon(s). The kensai is deadly, fast, and trained to fight without the protection of armor. Despite its common name, a kensai may use any melee weapon.",
  dwarvenDefender:
    'The Dwarven Defender is a formidable warrior that is reputed to be worth two soldiers of any other race. Trained extensively in the art of dwarven warfare, a handful of these stout fighters can render a defensive line all but unbreakable. ',
  barbarian:
    'Barbarians can be excellent warriors. While not as disciplined or as skilled as a normal fighter, the barbarian can willingly throw himself into a berserker rage, becoming a tougher and stronger opponent.',
  ranger:
    'The ranger is a warrior and a woodsman who is skilled with weapons and is knowledgeable in the ways of the forest. The Ranger often protects and guides lost travelers and honest peasant-folk. A Ranger needs to be strong and wise to the ways of nature to live a full life.',
  archer:
    'The Archer is the epitome of skill with the bow - the ultimate marksman, able to make almost any shot, no matter how difficult. To become so skilled with the bow, the Archer has had to sacrifice some of the proficiency with melee weapons and armor.',
  beastMaster:
    "Beast Masters are wanderers and are not comfortable in civilized lands. Therefore, they maintain a natural affinity for animals; who are the Beast Masters' friends and comrades-in-arms, and the Beast Masters have a limited form of telepathic communication with the animals.",
  stalker:
    'Stalkers serve as covert intelligence-gatherers, comfortable in both wilderness and urban settings. They are spies, informants, and interrogators, and their mastery of stealth makes them deadly opponents. ',
  paladin:
    'A Paladin is a warrior bold and pure, the exemplar of everything good and true. Like the Fighter, the Paladin is a person of action and combat. However, the Paladins live for the ideals of righteousness, justice, honesty, piety, and chivalry and strive to be a living example of these virtues so that others may learn from them as well as gain by their actions.',
  cavalier:
    "This class represents the most common picture of the knight: the chivalrous warrior who epitomizes honor, courage, and loyalty. The cavalier is specialized in battling 'classical' evil monsters such as demons and dragons.",
  inquisitor:
    'The Inquisitors have dedicated their life to finding and eliminating practitioners of evil magic and defeating the forces of darkness, and their god has provided them with special abilities toward that end.',
  undeadHunter:
    "These holy avengers have honed their abilities toward the destruction of the undead and other unnatural creatures and is immune to many of the creatures' most devastating abilities.",
  bard: "The Bard is a rogue, but is very different from the Thief. The Bards' strength is their pleasant and charming personality. With it and their wits, they make their way through the world. Bards are talented musicians and walking storehouses of gossip, tall tales, and lore, they learn a little bit about everything that crosses their paths and are jack-of -all-trades but master of none. While many Bards are scoundrels, their stories and songs are welcome almost everywhere.",
  jester:
    'Jesters are well versed in the arts of ridicule and hilarity, and use their abilities to distract and confuse their enemies, cavorting madly during combat. Do not mistake them for true fools, however, as they can also be quite deadly.',
  skald:
    'Skalds are Nordic bards who are also warriors of great strength, skill, and virtue; their songs are inspiring sagas of battle and valor, and the Skalds devote their lives to those pursuits.',
  blade:
    "The Blades are the expert fighters and adventurers whose bardic acting abilities make them appear more intimidating and fearsome. This Bard's fighting style is flashy and entertaining, but is also lethally dangerous.",
  thief:
    "To accomplish one's goals, for good or ill, the Thief is a skilled pilferer. Cunning, nimbleness, and stealth are a thief's hallmarks. Whether a thief turns talent against innocent passersby and wealthy merchants or oppressors and monsters is a choice for the Thief to make.",
  assassin:
    'Assassins are killers trained in discreet and efficient murder, relying on anonymity and surprise to perform their tasks.',
  bountyhunter:
    'This is a hunter of men, skilled in tracking quarry and bringing them back alive - whether for lawful authorities or underworld masters. Bounty Hunters are specially trained at their task and make fearsome opponents. They have honed their trap-making abilities well beyond that of the average Thief.',
  swashbuckler: 'Swashbucklers are part acrobat, part swordman, and part wit: the epitome of charm and grace.',
  shadowDancer:
    'Shadowdancers can harness the power of magic to increase their stealth abilities. Enigmatic and dangerous, these uniquely skilled Thieves are able to blend seamlessly into the shadows in ways that a normal Thief cannot, striking without warning - sometimes with supernatural speed.',
  monk:
    "Monks are warriors who pursue perfection through contemplation as well as action. They are versatile fighters, especially skilled in combat without weapons or armor. Though Monks cannot cast spells, they have a unique magic of their own: they channel a subtle energy, called ki, which allows them to perform amazing feats. The Monk's best known feat is their ability to stun an opponent with an unarmed blow." +
    'Class features\n' +
    'May not wear any armor \n' +
    'May only use weapons available to the Thief class (except two-handed)\n' +
    'May only become Proficient (one slot) in any weapon class\n' +
    'May only become Proficient (one slot) in Single-Weapon Style and may not put slots into any other style\n' +
    'Moves 2 points faster than other non-barbarian characters. Movement rate further improves by 1 every 5 levels\n' +
    'May make 1 unarmed attack per round. An additional 1/2 attack per round is gained every 3 levels up to level 18. Damage dealt by unarmed attacks increases with level as follows:    Level 1-2:1d6    Level 3-5:1d8    Level 6-8:1d10    Level 9-14:1d12    Level 15+:1d20\n' +
    "Unarmed attacks have the extended range of two-handed weapons, and at level 9, they're treated as +1 magical weapon and gain -1 THAC0 bonus and +1 damage rolls bonus. This enchantment improves to +2 at level 12, +3 at level 15, and +4 at level 25\n" +
    'Receives a -2 bonus to saving throws vs. spell\n' +
    'Deflect Missiles: -1 bonus to AC vs. missile attacks every 3 levels\n' +
    'Starts with a base Armor Class of 9 at 1st level and gains an additional -1 bonus every 2 levels.\n' +
    'May use the Stunning Blow ability once per day. Gains one use at level 1 and an additional use every 4 levels thereafter.\n' +
    '5th level: Becomes immune to all diseases and cannot be slowed or hasted\n' +
    '7th level: May use the Lay On Hands ability\n' +
    '8th level: Gains a -1 bonus to Speed Factor\n' +
    '9th level: Gains a -1 bonus to all Saving Throws and becomes immune to charm\n' +
    '11th level: Becomes immune to poison\n' +
    '12th level: Gains another -1 bonus to Speed Factor\n' +
    '13th level: May use the Quivering Palm ability once per day\n' +
    '14th level: Gains 3% Magic Resistance per level (starting with 42%) up to max of 78% at 26th level.\n' +
    '20th level: Becomes immune to non-magical weapons\n' +
    'Alignment restricted to lawful\n' +
    'Hit Die: d8\n' +
    'May not Dual-class',

  sunSoulMonk:
    'Sun Soul Monks were once worshipers of Amaunator in the time of the Netheril, but today their worship is divided between Lathander, Selûne, and Sune. Sun Soul Monks adhere strictly to the law, using their martial arts and magical abilities to drive out darkness and corruption wherever they find it.',
  darkMoonMonk:
    'The Order of the Dark Moon is a secretive monastic order that follows the teachings of the dark goddess Shar. Monks of the Dark Moon use physical prowess, dark magic, and many different forms of deception to mask their activities and strike with deadly precision.',
  mage: 'The wizard strives to be a master of magical energies, shaping them and casting them as spells. To do so, they study strange tongues and obscure facts and devotes much of their time to magical research. A wizard must rely on knowledge and wit to survive. Wizards are rarely seen adventuring without a retinue of fighters and men-at-arms. Because there are different types (or schools) of magic there are different types of wizards. The Mages study all types of magic and learn a wide variety of spells; their broad range makes them well suited to the demands of adventuring.',
  wildMage:
    'Wild Mages are wizards who specialize in the study of wild magic. They have access to spells to protect themselves from wild magic and bend it to their wills. Wild magic is extremely unpredictable and should be used with caution.    Wild Magic is a new type of magic that is characterized by powerful and dangerous surges of unpredictable magic. Generally considered to be an unfortunate byproduct of the Time of Troubles, wild magic has recently begun to attract the attention of many a curious or scholarly wizard.',
  cleric:
    'The Cleric is a generic priest (of any mythos) who tends to the spiritual needs of a community. Though Clerics are both protectors and healers, they are not purely defensive - when evil threatens, the Clerics are well suited to seek it out on its own ground and destroy it. The opposite is true of evil Clerics as well, and there are also many variations in between.',
  priestLathander:
    'Lathander is the good god of renewal, creativity, and youth, and is celebrated nearly everywhere. His followers are very popular throughout the realms, and there are numerous wealthy temples devoted to him.',
  priestHelm:
    'Followers of Helm, the neutral god of watchers and protectors are warriors in their own right and are often seen as defenders of the innocent.',
  priestTalos:
    'Talos is the evil god of storms, destruction, and rebellion. Clerics of the Stormlord warn that Talos must be appeased or he will rain destruction upon the land.',
  priestTempus:
    "Tempus, also known as the Lord of Battles or the Foehammer, is random in his favors, yet his chaotic nature favors all sides equally. Lord Tempus may be on an army's side one day and against them the next; such is the nature of war. Tempuran clergy can be found on both sides of a conflict, as none can ever truly know whom the war god will favor.",
  priestTyr:
    "Tyr is the deity of law and justice and is primarily concerned with the punishment of wrongdoers and the general furthering of law and good in the world. The belief in justice through (benevolent) force, or at least armed vigilance, is the reason for Tyr's existence. Tyr holds great prominence in the pantheon due to his position as leader of the Triad, a trio of lawful good gods that are collectively devoted to the concepts of courage, justice, perseverance, relief of suffering, duty, obedience, honor, and to some extent, righteous martyrdom.",
  druid:
    'The Druids serve the cause of nature and neutrality, the wilderness is their community, and they use their special powers to protect it and to preserve balance in the world.',
  totemicDruid:
    "The Totemic Druids closely identify with a particular animal, an animal that they feel represents their individual spirits. This grants the Totemic Druids a special connection to the animal kingdom, and they're able to call upon their spirits to aid them.",
  shapeshifter:
    'The Shapeshifters are not called Shapeshifter because they have access to a great variety of forms, but rather because of their complete dedication to a single alternate form. Shapeshifters have willingly allowed themselves to become infected with lycanthropy, but due to intense study and training they have the ability to control their afflictions. The creatures they become are that of werewolves, the most famous of the lycanthrope shape changers.',
  avenger:
    'A member of a special sect within the druidic order, a Druid of this type is dedicated to fighting those who would defile nature. Avengers have powers the average Druid does not - additional abilities that have been earned through extensive rituals, a process that is very physically draining.',
  sorcerer:
    "Sorcerers are practitioners of magic who are born with the innate ability to cast spells. It is thought that the blood of some powerful creature flows through their veins; perhaps they are the spawn of the gods themselves, or even dragons walking in humanoid form. Regardless, the Sorcerer's magic is intuitive rather than logical. They know fewer spells than Mages, and acquire spells more slowly, but they can cast spells more often and have no need to select and prepare spells ahead of time. Sorcerers cannot specialize in magic the way Mages do. Other than these differences, a Sorcerer is very similar to a Mage.",
  dragonDisciple:
    "Dragon Disciples are powerful Sorcerers with dragons' blood somewhere in their lineage. Their natural magical talents bring out their draconic heritage, allowing them to cast powerful magical spells and exhibit dragon-like abilities.",
  fighterThief:
    'This character can use the abilities of a Fighter and a Thief, though they cannot use their thieving skills while wearing more than studded leather armor. They may Specialize in, but not Master, any weapon they can use.',
  clericMage:
    'This character can use the abilities of a Cleric and a Mage, though weapons are restricted to only those allowed by the Clerics ethos, and Mage spells cannot be cast while wearing armor. Gnomes can choose this multi-class but become Cleric/Illusionists by default. Gnomes are the only race that can combine a specialist Mage class in a multi-class.',
  clericRanger:
    'This character can use the abilities of a Cleric and a Ranger, though weapons are restricted to only those allowed by the Clerics ethos. This character may Specialize in, but not Master, any weapon they can use.',
  clericThief:
    'This character can use the abilities of a Cleric and a Thief, though weapons are restricted to only those allowed by the Clerics ethos. Thieving skills cannot be used while wearing more than studded leather armor.',
  fighterDruid:
    'This character can use the abilities of a Fighter and a Druid, though weapons are restricted to only those allowed by the Druids ethos. This character may Specialize in, but not Master, any weapon they can use. ',
  fighterMage:
    'This character can use the abilities of a Fighter and a Mage, though they cannot cast spells while wearing armor. This character may Specialize in, but not Master, any weapon they can use. Gnomes can choose this multi-class but become Fighter/Illusionists by default. Gnomes are the only race that can combine a specialist Mage class in a multi-class.',
  fighterMageCleric:
    'This character can use the abilities of a Fighter, Mage, and Cleric, though they are restricted to weapons allowed by the Clerics ethos and cannot cast Mage spells while wearing armor. This character may Specialize in, but not Master, any weapon they can use.',
  fighterMageThief:
    'This character can use the abilities of a Fighter, Mage, and Thief, though they cannot use their thieving skills while wearing more than studded leather armor and cannot cast spells while wearing any armor. This character may Specialize in, but not Master, any weapon they can use.',
  fighterCleric:
    "This character can use the abilities of a Fighter and a Cleric, though weapons are restricted to only those allowed by the Cleric's ethos. This character may Specialize in, but not Master, any weapon they can use.",
  mageThief:
    'This character can use the abilities of a Mage and a Thief, though Mage spells cannot be cast while wearing armor. Gnomes can choose this multi-class but become Illusionist/Thieves by default. Gnomes are the only race that can combine a specialist Mage class in a multi-class.',
  shaman:
    'Shamans forge a divine connection with the natural world, similar to the way Druids commune with nature, but Shamans are more spiritual than primal. They directly tap into the energies that flow through the earth and air, through plants and animals, and even through the dead, and shape these energies to obey their will. Nowhere is this more evident than in their ability to summon powerful spirits as guardians and defenders. A being of two worlds, Shamans value balance over dominance.' +
    '\n' +
    'Class features: \n' +
    'May only wear leather, studded leather, and hide armor\n' +
    'May not equip shields larger than bucklers\n' +
    'May only use the following weapons: dagger, club, spear, axe, quarterstaff, dart, sling, and shortbow\n' +
    'May only become Proficient (one slot) in any weapon class\n' +
    'May only become Proficient (one slot) in any fighting style\n' +
    'May cast druidic spells\n' +
    'Does not automatically learn all spells of a given level as Druids do. Instead, Shamans select new spells to learn at each level, which they can cast daily without memorization\n' +
    'Gains immediate access to a number of Shaman-specific spells, which can be cast like any other: Spirit Ward, Writhing Fog, Spiritual Clarity, Spirit Fire, Recall Spirit, Spiritual Lock, and Ether Gate\n' +
    'Gains use of Detect Illusion skill with 20% points as a base, with an additional 4 points per level, up to a maximum of 100% at level 20\n' +
    'May use Shamanic Dance at will. Shamans can summon spirit allies by performing a ritualistic dance. While dancing, the Shaman takes a +4 penalty to Armor Class and cannot move, attack, cast spells, use items and special abilities, or perform any other activity. Each round while the Shaman dances, there is a 35% base chance plus 2% for each level of the Shaman that a spirit will answer the call, up to a maximum of 75% at level 20. Any summoned spirits will disappear if the Shaman moves, stops dancing, or if the spirits leave visual range. The spirits grow in power as the Shaman gains additional levels:\n' +
    'Level 1: Minor animal spirits (snake, fox, hound), up to 2 spirits at the same time\n' +
    'Level 6: Major animal spirits (bear, panther, boar), up to 3 spirits at the same time\n' +
    'Level 12: Minor nature spirits (lesser air spirit, lesser earth spirit, lesser fire spirit), up to 4 spirits at the same time\n' +
    'Level 18: Major nature spirits (air spirit, earth spirit, fire spirit), up to 5 spirits at the same time\n',
};

export const spellDescriptions: Record<string, string> = {
  armor:
    'By means of this spell, the wizard creates a magical field of force that serves as if it were scale mail armor (Armor Class: 6). It is cumulative with Dexterity and, in the case of Fighter/Mages, with the shield bonus. The Armor spell does not hinder movement, adds no weight or encumbrance, and does not prevent spellcasting. It lasts until successfully dispelled or until the duration runs out.',
  armorOfFaith:
    "The caster of the Armor of Faith receives significant protection against melee and magical attacks. This magical armor is a force of energy that absorbs a portion of the damage intended for the caster. At 1st level, the protection is 5%, and every 5 levels of the caster improves this by another 5%: For example, a 20th-level priest would have 25% of his damage 'absorbed' if protected by this spell.",
  bless:
    "Upon uttering the Bless spell, the caster raises the morale of friendly creatures by +1. Furthermore, it raises their attack and damage rolls by +1. The caster determines at what range (up to 40 ft.) the spell will be cast. At the instant the spell is completed, it affects all creatures in a 15-ft. radius centered on the point selected by the caster (thus, affected creatures leaving the area are still subject to the spell's effects; those entering the area after the casting is completed are not).",
  blindness:
    'This 1st-level spell temporarily blinds its target. A Saving Throw is allowed and, if successful, there are no harmful effects. If a victim is blinded, he receives a -4 penalty to his attack rolls and Armor Class.',
  burningHands:
    "When the wizard casts this spell, a jet of searing flame shoots from his fingertips. The wizard's hands must be held so as to send forth a fanlike sheet of flames: his thumbs must touch each other and the fingers must be spread. The burning hands send out flame jets of 5 ft. length in a horizontal arc of about 120 degrees in front of the wizard. The target suffers 1d3 points of damage, +2 points for each level of the caster, to a maximum of 1d3+20 points of fire damage. A creature that successfully saves vs. Spell receives half damage.",
  causeLightWounds:
    "By casting this spell and touching a creature, the priest deals 8 points of damage (save vs. Spell for half) to the creature's body. This spell has no effect on undead, constructs, or extraplanar creatures. It cannot be cast by good-aligned characters. ",
  charmPerson:
    'This spell affects any single person it is cast upon. The term person includes any bipedal human, demihuman, or humanoid of man-size or smaller, such as brownies, dryads, dwarves, elves, gnolls, gnomes, goblins, half-elves, halflings, half-orcs, hobgoblins, humans, kobolds, lizard men, nixies, orcs, pixies, sprites, troglodytes, and others. Thus, a 10th-level fighter could be charmed, but an ogre could not. The person is allowed a Saving Throw vs. Spell to negate the effect. \n' +
    "If the spell's recipient fails his Saving Throw (with a +3 modifier), he regards the caster as a trusted friend and ally to be heeded and protected. The caster may give him orders, and the charmed individual will carry them out as quickly as possible. \n " +
    "If the caster harms—or attempts to harm—the charmed person by some overt action, or if a Dispel Magic spell is cast upon the charmed person, the Charm spell is broken. If two or more Charms simultaneously affect a creature, the most recent Charm takes precedence. A formerly charmed subject is fully aware of what occurred while he was under the spell's influence. Note that a charmed creature cannot leave the area where he is charmed.",
  chillTouch:
    "When the caster completes this spell, a blue glow encompasses the caster's hand. This energy attacks the life force of any living creature upon which the wizard makes a successful melee attack. (Nonliving creatures such as golems and undead are unaffected by this spell.) The creature is punched for 1d2 fist damage (Strength bonuses apply) and must make a Saving Throw vs. Spell or suffer 1d8 points of damage and receive a -2 THAC0 penalty for 5 rounds.",
  chromaticOrb:
    "This spell causes a 2-ft. diameter sphere to appear in the caster's hand. When thrown, the sphere heads unerringly to its target. The effect the orb has upon the target varies with the level of the caster. Each orb will do damage to the target against which there is no save and an effect against which the target must save vs. Spell with a +6 bonus: \n" +
    '1st Level: 1d4 damage and blinds the target for 1 round. \n ' +
    '2nd Level: 1d4 damage and inflicts pain (-1 penalty to Strength, Dexterity, AC, and THAC0) upon the victim. \n ' +
    '3rd Level: 1d6 damage and burns the victim for an additional 1d8 damage. \n ' +
    '4th Level: 1d6 damage and blinds the target for 1 turn. \n ' +
    '5th Level: 1d8 damage and stuns the target for 3 rounds. \n ' +
    '6th Level: 1d8 damage and causes weakness (-4 penalty to THAC0) in the victim. \n ' +
    '7th Level: 1d10 damage and paralyzes the victim for 2 turns. \n ' +
    '10th Level: 1d12 acid damage and turns the victim to stone. \n ' +
    '12th Level: 2d8 acid damage and instantly kills the victim. \n ' +
    'NOTE: The victim saves vs. Spell with a +6 bonus against all the effects and gets no save against the damage. \n ',
  colorSpray:
    "Upon casting this spell, the wizard causes a vivid, fan-shaped spray of clashing colors to spring forth from the caster's hand. 1d6 creatures within the area are affected in order of increasing distance from the wizard. All creatures in the area of effect that have 4 Hit Dice or less must make a successful Saving Throw or be rendered unconscious for 5 rounds.",
  command:
    'This spell enables the priest to command another creature to "die" (sleep) for a single round. At the end of the round, the creature awakens and is unharmed. Creatures with 6 or more Hit Dice (or experience levels) are entitled to a Saving Throw vs. Spell to ignore the command.',
  cureLightWounds:
    "By casting this spell and laying [their] hand upon a creature, the priest causes 8 points of wound or other injury damage to the creature's body to be healed. This healing cannot affect creatures without corporeal bodies, nor can it cure wounds of creatures not living or of extraplanar origin. \n " +
    'Curing is permanent only insofar as the creature does not sustain further damage; caused wounds will heal—or can be cured—just as any normal injury.',
  curse:
    "When uttering the Curse spell, the caster lowers the morale of enemy creatures and any Saving Throws and attack rolls they make by 1. At the instant the spell is completed, it affects all creatures in a 15-ft. radius centered on the point selected by the caster. Affected creatures leaving the area are still subject to the spell's effect; those entering the area after the casting is completed are not. ",
  detectEvil:
    'This spell discovers emanations of evil from any creature. Any evil creature within the range of the spell will glow red briefly.',
  doom: 'This spell causes a feeling of doom to overwhelm the target. For the duration of the spell, the target receives a +2 penalty to all their rolls, including THAC0 and Saving Throws. There is no Saving Throw for this spell.',
  entangle:
    'By means of this spell, the caster is able to cause plants in the area of effect to entangle creatures within the area. The grasses, weeds, bushes, and even trees wrap, twist, and entwine about the creatures, holding them fast. Once per round, a creature can attempt to save vs. Spell with a +3 bonus to avoid entanglement. An entangled creature can still attack, but receives a -2 penalty to its Armor Class.',

  findFamiliar:
    'This spell enables the caster to attempt to summon a familiar to act as his aide and companion. Familiars are typically small creatures. A creature acting as a familiar can benefit a wizard, conveying its sensory powers to its master, conversing with him, and serving as a guard/scout/spy as well. A wizard can have only one familiar at a time, and he has no control over what sort of creature answers the summoning, if any at all come. \n' +
    '(Note: This spell may only be cast by the protagonist.) \n' +
    "The creature is always more intelligent than others of its type (typically by 2 or 3 Intelligence points), and its bond with the wizard confers upon it an exceptionally long life. The wizard has an empathic link with the familiar and can issue it mental commands. The caster receives half the familiar's total Hit Points (rounded down) as bonus Hit Points. However, the caster must take care to treat his familiar well, for if the familiar should die, the caster loses the bonus Hit Points and half the familiar's Hit Points again as damage. Also when a familiar dies, the caster loses 1 point of Constitution PERMANENTLY. \n" +
    'Example: A Mage has 12 Hit Points and casts Find Familiar. The imp summoned has 9 Hit Points, so the caster gets a bonus of 4 Hit Points. The caster now has 16 Hit Points. If the familiar dies, the caster loses those 4 Hit Points (putting him back at 12), loses 1 point of Constitution permanently (which may cause additional Hit Point loss), and takes 4 damage.',
  friends:
    "A Friends spell causes the wizard to temporarily gain 6 points of Charisma. Those who view the caster tend to be very impressed with the spellcaster and make an effort to be his friend and help him, as appropriate to the situation. Officious bureaucrats might decide to become helpful; surly gate guards might wax informative; attacking orcs might spare the caster's life, taking him captive instead.",
  grease:
    'A Grease spell covers a material surface with a slippery layer of a fatty, greasy nature. Any creature entering the area or caught in it when the spell is cast must save vs. Spell at +2 or slip and slide, unable to move effectively. Those who successfully save can move, albeit slowly, for the rest of the round (but will need to save vs. Spell again the following round). Those who remain in the area are allowed a Saving Throw each round until they escape the area.',
  identify:
    "With this spell memorized, go to the description of an unidentified item and press the Identify button. The chance of identifying the item is 100%. The spell identifies the item's name, what it does, and if it is cursed.",
  infravision:
    'Upon the casting of this spell the recipient gains the ability to see with infravision, just as an elf or a dwarf would. This effect lasts for the duration of the spell or until dispelled.',
  larlochsMinorDrain:
    'With this spell, the wizard drains the life force from a target and adds it to his own. The target creature suffers 4 damage, while the Mage gains 4 Hit Points. If the Mage goes over his maximum Hit Point total with this spell, he loses any extra Hit Points after 1 turn.',
  magicMissile:
    "Use of the Magic Missile spell, one of the most popular 1st-level spells, creates up to five missiles of magical energy that dart forth from the wizard's fingertip and unerringly strike their target, which must be a creature of some sort. Each missile inflicts 1d4+1 points of damage. for every 2 extra levels of experience, the wizard gains an additional missile––he has 2 at 3rd level, 3 at 5th level, 4 at 7th level, up to a total of 5 at 9th level.",
  magicalStone:
    'By using this spell, the priest can create a small magical pebble, which then flies out and hits a target opponent. The stone deals 1d4 points of damage to whomever it hits. The stones are considered +1 weapons for determining if a creature can be struck (those struck only by magical weapons, for instance), although they do not have an attack or damage bonus.',
  removeFear:
    'The priest instills courage in the spell recipients, raising their morale to its highest. The recipients morale will gradually reset to normal as the duration runs out. If the recipients are affected by magical fear, this is negated.',
  reflectedImage:
    "Upon casting this spell, the wizard creates 1 image of himself that will travel alongside him. The image will perform all of the actions that the wizard does so that if any enemies are trying to attack the wizard, they will not know which one is real. There is a 50% chance that the attacker will attack the image and a 50% chance that he will attack the caster. The image will disappear with a successful Dispel Magic, when attacked, or when the spell's duration expires.",
  sanctuary:
    'When the priest casts a Sanctuary spell, it causes all of [their] opponents to ignore [their] existence as if [they] were invisible. While protected by this spell, the subject cannot take direct offensive action without breaking the spell, but may use non-attack spells or otherwise act in any way that does not violate the prohibition against offensive action. This allows a warded priest to heal wounds, for example, or to Bless [themselves]. The priest may not cast spells on other creatures without ending the spell.',
  shield:
    "When this spell is cast, an invisible barrier comes into being in front of the wizard. It sets the wizard's Armor Class to 4 against all melee weapons, and 2 against missile weapons. It also grants the wizard immunity from the spell Magic Missile. The effect lasts for the duration of the spell or until dispelled.",
  shillelagh:
    'This spell enables the caster to create a magical cudgel that has a +1 bonus to its attack roll and inflicts 2d4 points of damage on opponents.',
  shockingGrasp:
    "When the wizard touches a creature while this spell is in effect, an electrical charge will deal 1d8 points of damage plus 1 per level of the caster to the creature touched. In addition, the grasp itself does 1d2 fist damage (Strength bonuses apply). The wizard only has one charge, and once an opponent has been touched, the spell's energies have been used. If the wizard misses, the spell is wasted. The wizard has 1 round per level to touch the target creature.",
  sleep:
    "When a wizard casts a Sleep spell, he causes a comatose slumber to come upon one or more enemies (other than undead and certain other creatures specifically excluded from the spell's effects). All creatures to be affected by the Sleep spell must be within a 30-ft. radius. Creatures in the area of effect must make a Saving Throw vs. Death with a -3 penalty or fall asleep. Monsters with 5 Hit Dice or more are unaffected. Attacks against sleeping opponents never miss.",
  protectionFromEvil:
    'When this spell is cast, it creates a magical barrier around the recipient at a distance of one foot. The barrier moves with the recipient and has two major effects…',
  nahalsRecklessDweomer:
    "This spell is the Wild Mage's ultimate last-resort spell. When cast, the Mage releases a sudden flood of wild magical energy in the hope of seizing and shaping that energy into a desired spell effect. The attempt usually fails, but something almost always occurs in the process. \n" +
    "To use Nahal's Reckless Dweomer, simply cast it and then choose from your list of known spells. A burst of magical energy is released, which the Wild Mage will try to manipulate into the desired form. The actual effect of the spell is determined randomly by a dice roll on the Wild Surge Table. \n" +
    "Because the release of energy is planned by the Mage, his level is added to the dice roll. If the result indicates success, the Mage has shaped the magical energy into the desired effect. More often than not, the effect is completely unexpected. The result might be beneficial to the Mage, or it might be completely disastrous: This is the risk the Mage takes in casting Nahal's Reckless Dweomer.",
  sunscorch:
    "When this spell is cast, a brilliant ray of scorching heat slants down from the sky and strikes one target of the caster's choice. Any creature struck by the ray takes 1d6 points of damage, plus 1 point per level of the caster; however, if the target is an undead creature or a monster vulnerable to bright light, the damage is 1d6, plus 2 points per level of the caster. In addition to sustaining damage, victims are also blinded for 3 rounds. The victim is allowed a Save vs. Spell for half damage and to avoid the blindness.",
};
