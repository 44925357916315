import { useState, useEffect } from 'react';
import _ from 'lodash';
import ESModal from '../../common/ESModal';
import { CharacterType, ITertiaryStats } from '../Types/CharacterType';
import { GameTypeForm } from './GameTypeForm';
import { GenderForm } from './GenderForm';
import { RaceForm } from './RaceForm';
import { ClassForm } from './ClassForm';
import { AlignmentForm } from './AlignmentForm';
import { AbilityScoreForm } from './AbilityScoreForm';
import { SkillForm } from './SkillForm';
import { CharacterFormResult } from './CharacterFormResult';
import { CharacterFormPortrait } from './CharacterFormPortrait';
import { CharacterNameForm } from './CharacterNameForm';

import { fetchTertiaryStatsAPI, handleDownloadSingleCharacter } from '../../api/apiUtil';
import { useQuery } from 'react-query';
import { CharacterFormNav } from './ChracterFormNav';

export type TFormOptions =
  | 'GAME'
  | 'GENDER'
  | 'RACE'
  | 'CLASS'
  | 'ALIGNMENT'
  | 'ABILITIES'
  | 'SKILLS'
  | 'APPEARANCE'
  | 'NAME';

export function CharacterForm() {
  const [step, setStep] = useState<TFormOptions>('GAME');
  const [modalForm, setModalForm] = useState<boolean>(false);
  const [character, setCharacter] = useState<Partial<CharacterType>>({});
  const { iwd, raceName, classKit, className, abilityScores } = character;
  useQuery(['TERTIARY_STATS', iwd, raceName, classKit, className, abilityScores], fetchTertiaryStats, {
    staleTime: 30 * 60 * 60 * 1000,
    enabled: !!raceName && !!className && !!classKit && typeof abilityScores === 'object',
    onSuccess,
  });

  function fetchTertiaryStats() {
    if (raceName && classKit && className && abilityScores) {
      return fetchTertiaryStatsAPI({
        raceType: raceName,
        classType: className,
        kitType: classKit,
        gameType: iwd ? 'IWD' : 'BG',
        abilityScoreMap: abilityScores,
      });
    }
  }

  function onSuccess(data: ITertiaryStats) {
    if (data) {
      setCharacter({ ...character, lore: data.lore, carryWeight: data.carryWeight, savingThrows: data.savingThrows });
    }
  }

  useEffect(() => {
    if (step === 'GAME' && typeof character.iwd === 'boolean') {
      setStep('GENDER');
    }
    if (step === 'GENDER' && typeof character.portrait === 'string' && typeof character.gender === 'string') {
      setStep('RACE');
    }
    if (step === 'RACE' && typeof character.raceName === 'string') {
      setStep('CLASS');
    }
    if (step === 'CLASS' && typeof character.className === 'string' && typeof character.classKit === 'string') {
      setStep('ALIGNMENT');
    }
    if (step === 'ALIGNMENT' && typeof character.alignment === 'string') {
      setStep('ABILITIES');
    }
    if (step === 'ABILITIES' && typeof character.abilityScores === 'object') {
      setStep('SKILLS');
    }
    if (step === 'SKILLS' && typeof character.weaponSkills === 'object') {
      setStep('NAME');
    }
  }, [character, step]);

  const canClickBack = FORM_OPTIONS.indexOf(step) !== 0;

  function handleBackClick() {
    const previousStep = FORM_OPTIONS[FORM_OPTIONS.indexOf(step) - 1];
    if (previousStep === 'GAME') {
      setCharacter({ ...character, iwd: undefined });
    }
    if (previousStep === 'GENDER') {
      setCharacter({ ...character, gender: undefined, portrait: undefined });
    }
    if (previousStep === 'RACE') {
      setCharacter({ ...character, raceName: undefined });
    }
    if (previousStep === 'CLASS') {
      setCharacter({ ...character, classKit: undefined, className: undefined });
    }
    if (previousStep === 'ALIGNMENT') {
      setCharacter({ ...character, alignment: undefined });
    }
    if (previousStep === 'ABILITIES') {
      setCharacter({ ...character, abilityScores: undefined, savingThrows: undefined });
    }
    if (previousStep === 'SKILLS') {
      setCharacter({
        ...character,
        weaponSkills: undefined,
        racialEnemy: undefined,
        thiefSkills: undefined,
        knownArcaneSpells: undefined,
        knownDivineSpells: undefined,
        knownDruidSpells: undefined,
        memorizedArcaneSpells: undefined,
        memorizedDivineSpells: undefined,
        memorizedDruidSpells: undefined,
        lore: undefined,
        savingThrows: undefined,
        hp: undefined,
        carryWeight: undefined,
      });
    }
    if (previousStep === 'NAME') {
      setCharacter({ ...character, charName: undefined });
    }
    setStep(previousStep);
  }

  function handleUpdateCharacter(partialChar: Partial<CharacterType>) {
    setCharacter({ ...character, ...partialChar });
    setModalForm(false);
  }

  function handleClose() {
    setModalForm(false);
  }

  async function handleDownloadClick() {
    await handleDownloadSingleCharacter(character as CharacterType).catch((error) => {
      console.error(error);
    });
  }
  function _handleResetForm() {
    if (typeof character.iwd === 'boolean') {
      if (window.confirm('Reset form? You will lose all character data')) {
        setStep('GAME');
        setCharacter({});
      }
    } else {
      setStep('GAME');
      setCharacter({});
    }
  }

  return (
    <section id="characterForm" className="es-character-form">
      <CharacterFormNav />
      <div className="es-character-form__grid">
        <div className="es-character-form__grid--options">
          <CharacterOptions step={step} onClick={() => setModalForm(true)} />
        </div>
        <div className="es-character-form__grid--middle">
          <CharacterFormResult character={character} />
        </div>
        <div className="es-character-form__grid--result">
          <CharacterFormPortrait character={character} />
        </div>
        <div className="es-character-form__grid--actions">
          <CharacterFormActions
            canClickBack={canClickBack}
            handleBackClick={handleBackClick}
            downloadEnabled={step === 'NAME' && typeof character.charName === 'string' && character.charName.trim().length > 0}
            handleDownload={handleDownloadClick}
            handleReset={_handleResetForm}
          />
        </div>
      </div>
      <ESModal
        id="formModal"
        title={step}
        size="large"
        isVisible={modalForm}
        closeOnOutsideClick={false}
        handleClose={() => setModalForm(false)}
        hideCornerCloseButton>
        <div className="es-ch-form__modal-wrapper">
          {step === 'GAME' && <GameTypeForm handleClose={handleClose} handleClick={handleUpdateCharacter} />}
          {step === 'GENDER' && (
            <GenderForm
              handleClose={handleClose}
              gameType={character.iwd ? 'IWD' : 'BG'}
              handleSave={handleUpdateCharacter}
            />
          )}
          {step === 'RACE' && (
            <RaceForm
              handleClose={handleClose}
              gameType={character.iwd ? 'IWD' : 'BG'}
              handleSave={handleUpdateCharacter}
            />
          )}
          {step === 'CLASS' && character.raceName && (
            <ClassForm
              handleClose={handleClose}
              gameType={character.iwd ? 'IWD' : 'BG'}
              handleSave={handleUpdateCharacter}
              raceType={character.raceName}
            />
          )}
          {step === 'ALIGNMENT' && (
            <AlignmentForm
              handleClose={handleClose}
              classType={character?.className ?? ''}
              classKit={character?.classKit ?? ''}
              handleSave={handleUpdateCharacter}
            />
          )}
          {step === 'ABILITIES' && (
            <AbilityScoreForm
              handleClose={handleClose}
              classType={character?.className ?? ''}
              classKit={character?.classKit ?? ''}
              raceName={character?.raceName ?? ''}
              handleSave={handleUpdateCharacter}
            />
          )}
          {step === 'SKILLS' && (
            <SkillForm
              handleClose={handleClose}
              handleSave={handleUpdateCharacter}
              gameType={character.iwd ? 'IWD' : 'BG'}
              className={character?.className ?? ''}
              kitName={character?.classKit ?? ''}
              raceName={character?.raceName ?? ''}
              dex={character?.abilityScores?.DEX ?? 3}
              wis={character?.abilityScores?.WIS ?? 3}
            />
          )}
          {step === 'NAME' && <CharacterNameForm handleClose={handleClose} handleSave={handleUpdateCharacter} />}
        </div>
      </ESModal>
    </section>
  );
}

const FORM_OPTIONS: TFormOptions[] = [
  'GAME',
  'GENDER',
  'RACE',
  'CLASS',
  'ALIGNMENT',
  'ABILITIES',
  'SKILLS',
  // 'APPEARANCE',
  'NAME',
];

interface ICharacterOptions {
  onClick?: () => void;
  step: TFormOptions;
}
export function CharacterOptions(props: ICharacterOptions) {
  const { onClick, step } = props;
  return (
    <section id="characterOptions" className="es-ch-form__options">
      {FORM_OPTIONS.map((option, i) => (
        <button
          key={`${option}_${i}`}
          className="es-button es-button--primary es-ch-form__option-btn"
          id={_.camelCase(option)}
          type="button"
          disabled={option !== step}
          onClick={onClick}>
          {option}
        </button>
      ))}
    </section>
  );
}

interface ICharacterFormActions {
  handleBackClick: () => void;
  canClickBack?: boolean;
  downloadEnabled: boolean;
  handleDownload: () => void;
  handleReset: () => void;
}
export function CharacterFormActions(props: ICharacterFormActions) {
  const { handleBackClick, canClickBack, downloadEnabled, handleDownload, handleReset } = props;
  return (
    <section id="characterFormActions" className="es-ch-form__actions flex">
      <button
        className="es-button es-button--primary"
        id="formBack"
        type="button"
        disabled={!canClickBack}
        onClick={handleBackClick}>
        {'Back'}
      </button>
      <button
        className="es-button es-button--primary"
        id="saveCharacter"
        type="button"
        disabled={!downloadEnabled}
        onClick={handleDownload}>
        {'Download'}
      </button>
      <button
        className="es-button es-button--warning"
        id={'resetCharacter'}
        type="button"
        disabled={!canClickBack}
        onClick={handleReset}>
        Reset
      </button>
    </section>
  );
}
