import { ClassImage } from './ClassImage';
import cn from "classnames"

interface CharacterAvatarProps {
  iwd: boolean;
  portrait: string;
  className: string;
  hp?: number;
  charName?: string;
  gender: 'MALE' | 'FEMALE';
  large?: boolean;
}

export default function CharacterAvatar(props: CharacterAvatarProps) {
  const { iwd, portrait, className, hp, charName, gender, large } = props;
  if (!portrait) {
    return null;
  }

  const classImage: string[] = className ? className.split('_') : [];

  let src = './images/portraits/';
  if (iwd) {
    src += `IWDPortraits/${gender}/${portrait.trim()}.BMP`;
  } else {
    src += `BGPortraits/${gender}/${portrait.trim()}.bmp`;
  }

  return (
    <div className="es-char-card__section es-char-card__main-wrap ">
      <div className="es-char-card__color-bar" />
      <div className="es-char-card__img-container">
        <div
          id="charPortrait"
          className={cn("es-char-card__img", {'es-char-card__img--large': large})}
          data-img={`${src}`}
          style={{ backgroundImage: `url(${src})` }}
        />
        {typeof hp === 'number' && (
          <span title="HP" className="es-char-card__notice--hp">
            {hp}
          </span>
        )}

        {className && <ClassImage classes={classImage} />}
      </div>
      {typeof charName === 'string' && (
        <div className="es-pill es-pill--main-theme col-span-2 grid-center-self">
          <p className="es-char-card__name">{charName}</p>
        </div>
      )}
    </div>
  );
}
