import _ from 'lodash';
import { IThiefSkillMap } from '../Types/CharacterType';

interface IThiefSkillsForm {
  thiefSkills: Record<string, number>;
  thiefPts: number;
  handleAddSkill: (skill: keyof IThiefSkillMap) => void;
  handleMinusSkill: (skill: keyof IThiefSkillMap) => void;
  kitName?: string;
}
export function ThiefSkillsForm(props: IThiefSkillsForm) {
  const { thiefSkills, handleAddSkill, handleMinusSkill, thiefPts, kitName } = props;
  const thiefSkillsMap = { ...thiefSkills };
  delete thiefSkillsMap['raceType'];
  delete thiefSkillsMap['bonusSkillMap'];
  if (kitName === 'SHADOW_DANCER') {
    delete thiefSkillsMap['setTraps'];
  }

  return (
    <>
      <div className="flex">
        <div className="es-pill es-pill--main-theme flex flex-between flex-1-7">
          <div>Skills</div>
        </div>
        <div className="es-pill es-pill--main-theme flex flex-between" style={{ width: '2.8rem', textAlign: 'center' }}>
          <div>{thiefPts}</div>
        </div>
      </div>
      <div className="flex">
        <div id="thiefSkillsAndPoints" className="flex-col">
          {Object.keys(thiefSkillsMap ?? {}).map((skill) => {
            return (
              <div key={skill} className="flex flex-between f-a-c">
                <div className="es-pill es-pill--main-theme flex flex-between flex-1-7">
                  <div className="capital">{_.lowerCase(skill)}</div>
                </div>
                <div
                  className="es-pill es-pill--main-theme flex flex-between"
                  style={{ width: '3.2rem', textAlign: 'center' }}>
                  <div>{typeof thiefSkills[skill] === 'number' ? thiefSkills[skill] : '0'}</div>
                </div>
                <div className="flex flex-center flex-1">
                  <button
                    style={{
                      height: '2rem',
                      width: '2rem',
                      margin: '0 5px',
                    }}
                    className="es-button es-button--primary es-button--small"
                    id={`${_.camelCase(skill)}Add`}
                    type="button"
                    onClick={() => handleAddSkill(skill as keyof IThiefSkillMap)}>
                    +
                  </button>
                  <button
                    style={{
                      height: '2rem',
                      width: '2rem',
                      margin: '0 0 0 5px',
                    }}
                    className="es-button es-button--primary es-button--small"
                    id={`${_.camelCase(skill)}Subtract`}
                    type="button"
                    onClick={() => handleMinusSkill(skill as keyof IThiefSkillMap)}>
                    -
                  </button>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
}
