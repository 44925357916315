import { useState } from 'react';
import { useQuery } from 'react-query';
import { CharacterType } from '../Types/CharacterType';
import { fetchAllRacesAPI } from '../../api/apiUtil';
import _ from 'lodash';
import cn from 'classnames';
import { RACE_DESCRIPTIONS } from '../../modules/descriptions';

interface IRaceForm {
  handleSave: (char: Partial<CharacterType>) => void;
  gameType: 'BG' | 'IWD';
  handleClose: () => void;
}
export function RaceForm(props: IRaceForm) {
  const { handleSave, gameType, handleClose } = props;
  const [selectedRace, setSelectedRace] = useState<string>();
  const {
    data: races = [],
    isLoading,
    isError,
  } = useQuery(['RACES', gameType], fetchAllRaces, {
    staleTime: 30 * 60 * 60 * 1000,
    enabled: !!gameType,
  });

  function fetchAllRaces() {
    return fetchAllRacesAPI();
  }

  function _handleSave() {
    if (selectedRace) {
      handleSave({ raceName: selectedRace });
    }
  }

  return (
    <div className="flex-col">
      <div className="flex flex-center">
        <div className="flex-col es-ch-form__races ">
          {!isLoading &&
            races.map((race: string, i: number) => (
              <button
                key={_.camelCase(race) + i}
                className={cn('es-button es-button--primary es-ch-form__option-btn', {
                  'es-button--selected': race === selectedRace,
                })}
                id={`${_.camelCase(race)}Btn`}
                type="button"
                onClick={() => setSelectedRace(race)}>
                {_.camelCase(race)}
              </button>
            ))}
        </div>
        <div className="flex-col es-ch-form__description">
          <span style={{ fontSize: '1rem', padding: '1rem' }}>
            {selectedRace ? RACE_DESCRIPTIONS[selectedRace] : 'Select a race'}
          </span>
          {isError && <p>{'Error fetching data'}</p>}
          {isLoading && <p>{'Loading...'}</p>}
        </div>
      </div>
      <div className="flex f-a-c flex-center">
        <button
          className="es-button es-button--primary es-ch-form__option-btn"
          id={'cancelCurrentStep'}
          type="button"
          onClick={handleClose}>
          Cancel
        </button>
        <button
          className="es-button es-button--primary es-ch-form__option-btn"
          id={'saveRace'}
          type="button"
          onClick={_handleSave}>
          Done
        </button>
      </div>
    </div>
  );
}
