import { Fragment } from 'react';
import _ from 'lodash';
import { IThiefSkillMap } from '../Types/CharacterType';

interface ThiefSkillsProps {
  thiefSkills: Record<string, number> | IThiefSkillMap;
}

export default function ThiefSkills(props: ThiefSkillsProps) {
  const { thiefSkills } = props;
  return (
    <section className="center-text">
      <h5>Thief Skills</h5>
      <div className="es-char-card__section--thief">
        {Object.entries(thiefSkills)
          .filter((entry) => entry[0] !== 'bonusSkillMap')
          .filter((entry) => typeof entry[1] === 'number')
          .map((entry, index) => {
            return (
              <Fragment key={`thiefSkill-${index}`}>
                <div className="es-char-card__stat-block">
                  <div className="es-pill es-pill--main-theme flex">
                    <span>{_.startCase(entry[0])}: </span>
                    <span className="es-char-card__stat">{entry[1]}</span>
                  </div>
                </div>
              </Fragment>
            );
          })}
      </div>
    </section>
  );
}
