import React from "react";
import classNames from "classnames";
import { FB_SHARE, RD_SHARE, TW_SHARE } from "../../modules/constants";
import useLocalStorage from "../../hooks/useLocalStorage";

export function LinkShare() {
  const [visible, setVisible] = useLocalStorage<boolean>("showShareToggle", true);

  function toggleVisible() {
    setVisible(!visible);
  }

  const handleClick = (link: string, windowName: string, style: string) => (
    event: React.MouseEvent
  ) => {
    event.preventDefault();
    window.open(link, windowName, style);
  };

  return (
    <div className={classNames("es-link", { "es-link--hidden": !visible })}>
      <div className="es-link__btn-grp">
        <button
          className="es-button es-button--fb"
          type="button"
          onClick={handleClick(
            FB_SHARE,
            "facebookShare",
            "width=700,height=700"
          )}
          title="Share on FB"
        >
          <svg className="es-icon es-icon--md es-icon--fb">
            <use href="images/sprite.svg#icon-facebook"></use>
          </svg>
        </button>
        <button
          className="es-button es-button--tw"
          type="button"
          onClick={handleClick(
            TW_SHARE,
            "twitterShare",
            "width=1000,height=1000"
          )}
          title="Tweet"
        >
          <svg className="es-icon es-icon--md es-icon--tw">
            <use href="images/sprite.svg#icon-twitter"></use>
          </svg>
        </button>
        <button
          className="es-button es-button--rd"
          type="button"
          onClick={handleClick(RD_SHARE, "redditShare", "width=800,height=800")}
          title="Post to Reddit"
        >
          <svg className="es-icon es-icon--md es-icon--rd">
            <use href="images/sprite.svg#icon-reddit"></use>
          </svg>
        </button>

        <button
          className={classNames("es-button es-link__toggle-btn", {
            "es-link__toggle-btn--hidden": !visible,
          })}
          type="button"
          onClick={toggleVisible}
          title={visible ? "Hide" : "Show"}
        >
          <svg className="es-icon es-icon--md es-icon--rd">
            <use href="images/sprite.svg#icon-circle-left"></use>
          </svg>
        </button>
      </div>
    </div>
  );
}
