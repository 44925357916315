import React, { useState, useEffect, RefObject } from 'react';
import cn from 'classnames';

interface IESTextInput {
  id: string;
  className?: string;
  value?: string;
  handleChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleFocus?: (e: React.FocusEvent<HTMLInputElement>) => void;
  handleBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  type?: string;
  error?: string;
  label?: string;
  name?: string;
  testId?: string;
  inline?: boolean;
  innerRef?: RefObject<HTMLInputElement>;
}
export default function ESTextInput(props: IESTextInput) {
  const [hasFocus, toggleFocus] = useState(false);
  const {
    id,
    className,
    value,
    handleChange,
    handleFocus,
    handleBlur,
    type,
    error,
    label,
    name,
    testId,
    inline,
    innerRef,
  } = props;
  useEffect(() => {
    if (value && !hasFocus) {
      toggleFocus(true);
    }
  }, [value, hasFocus]);

  function _handleFocus(e: React.FocusEvent<HTMLInputElement>) {
    if (!hasFocus) {
      toggleFocus(true);
    }

    if (handleFocus && typeof handleFocus === 'function') {
      handleFocus(e);
    }
  }

  function _handleBlur(e: React.FocusEvent<HTMLInputElement>) {
    if (hasFocus && !value) {
      toggleFocus(false);
    }

    if (handleBlur && typeof handleBlur === 'function') {
      handleBlur(e);
    }
  }
  return (
    <span className={cn('es-input', { 'es-input--inline': inline })}>
      <label
        htmlFor={id}
        className={cn('es-input__label', {
          'es-input__label--focus': hasFocus,
        })}>
        {label}
      </label>
      <input
        type={type}
        ref={innerRef}
        id={id}
        className={cn('es-input__input', className)}
        data-testid={testId || id}
        value={value}
        name={name}
        onChange={handleChange}
        onBlur={_handleBlur}
        onFocus={_handleFocus}
      />
      {error && <span className="es-input__error">{error}</span>}
    </span>
  );
}
