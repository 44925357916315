import { useNavigate } from '@tanstack/router';
import { GameTypeState } from '../CharacterGeneratorPage/CharacterGeneratorPage';
import GameTypeBanner from '../GameTypeBanner';

interface ActionPanelProps {
  gameType: GameTypeState;
  toggleGameType: () => void;
  fetchParty: () => void;
  downloadParty: (e: React.MouseEvent) => Promise<void>;
}
export default function ActionPanel(props: ActionPanelProps) {
  const { gameType, toggleGameType, fetchParty, downloadParty } = props;
  const navigate = useNavigate({ from: '/' });
  return (
    <aside className="actions flex-col" style={{ flex: '1 1 15%' }}>
      <div className="es-form-control flex-row">
        <GameTypeBanner gameType={gameType} />
        <div className="es-button-group f-a-c">
          <button
            className="es-button es-button--primary"
            id="navigateToForm"
            type="button"
            onClick={() => navigate({ to: '/character-form' })}>
            Character Form
          </button>
          <button className="es-button es-button--primary" id="switchGameType" type="button" onClick={toggleGameType}>
            Game: {gameType}
          </button>
          <button className="es-button es-button--primary" id="rollParty" type="button" onClick={fetchParty}>
            Random Party
          </button>
          <button className="es-button es-button--primary" id="downloadParty" type="button" onClick={downloadParty}>
            Download Party
          </button>
        </div>
      </div>
    </aside>
  );
}
