interface CharacterStatsProps {
  abilityScores: AbilityScoreType;
}

export interface AbilityScoreType {
  CHA: number;
  DEX: number;
  WIS: number;
  STR: number;
  INT: number;
  CON: number;
  FIGHT_STR?: number;
}

export default function CharacterStats(props: CharacterStatsProps) {
  const { abilityScores } = props;
  const { CHA, DEX, WIS, STR, INT, CON, FIGHT_STR } = abilityScores;

  const total: number = Object.keys(abilityScores)
    .filter((key: string) => key !== 'FIGHT_STR')
    .reduce((sum: number, key: string) => {
      const x = key as keyof AbilityScoreType;
      const ability = abilityScores[x];
      if (typeof ability === 'number') {
        return isNaN(ability) ? sum : sum + ability;
      } else {
        return 0;
      }
    }, 0);

  return (
    <div className="es-char-card__section es-char-card__stats">
      <div className="es-char-card__stat-block">
        <div className="es-pill es-pill--main-theme flex">
          <div>STR</div>
          <div className="es-char-card__stat">{FIGHT_STR && STR === 18 ? `${STR}/${FIGHT_STR}` : STR}</div>
        </div>
      </div>
      <div className="es-char-card__stat-block">
        <div className="es-pill es-pill--main-theme flex">
          DEX <span className="es-char-card__stat">{DEX}</span>
        </div>
      </div>
      <div className="es-char-card__stat-block">
        <div className="es-pill es-pill--main-theme flex">
          CON <span className="es-char-card__stat">{CON}</span>
        </div>
      </div>
      <div className="es-char-card__stat-block">
        <div className="es-pill es-pill--main-theme flex">
          INT <span className="es-char-card__stat">{INT}</span>
        </div>
      </div>
      <div className="es-char-card__stat-block">
        <div className="es-pill es-pill--main-theme flex">
          WIS <span className="es-char-card__stat">{WIS}</span>
        </div>
      </div>
      <div className="es-char-card__stat-block">
        <div className="es-pill es-pill--main-theme flex">
          CHA <span className="es-char-card__stat">{CHA}</span>
        </div>
      </div>
      <div className="flex self-center grid-span-2">
        <div className="es-pill es-pill--main-theme flex">
          <div className="es-char-card__stat-block">Total</div>
          <span className="es-char-card__stat es-char-card__stat-total">{total}</span>
        </div>
      </div>

      <div className="es-char-card__reroll"></div>
    </div>
  );
}
