import { useState } from 'react';
import { useQuery } from 'react-query';
import { CharacterType, ISkillTemplate, ISpellType, IThiefSkillMap, TThiefSkill } from '../Types/CharacterType';
import { fetchSkillTemplateAPI } from '../../api/apiUtil';
import _ from 'lodash';
import { WeaponForm } from './WeaponForm';
import { ThiefSkillsForm } from './ThiefSkillsForm';
import cn from 'classnames';
import { SpellForm, TSpellFormMode } from './SpellForm';
import { getThiefSkillsToDisplay } from '../../modules/utility';

interface ISkillForm {
  handleSave: (char: Partial<CharacterType>) => void;
  handleClose: () => void;
  className: string;
  kitName: string;
  raceName: string;
  gameType: string;
  dex: number;
  wis: number;
}

export type ISpellState = Record<TSpellStateKey, ISpellType[]>;
export type TSpellStateKey =
  | 'knownArcaneSpells'
  | 'memorizedArcaneSpells'
  | 'memorizedDruidSpells'
  | 'memorizedDivineSpells';

export function SkillForm(props: ISkillForm) {
  const { handleSave, handleClose, className, kitName, gameType, raceName, dex, wis } = props;
  const [weaponMap, setWeaponMap] = useState<Record<string, number>>({});
  const [availablePts, setAvailablePts] = useState<number>(0);
  const [thiefPts, setAvailableThiefPts] = useState<number>(0);
  const [bonusThiefSkills, setBonusThiefSkills] = useState<Record<TThiefSkill, number>>(
    {} as Record<TThiefSkill, number>
  );
  const [formMode, setFormMode] = useState<'SKILLS' | 'ENEMY' | 'SPELLS'>('SKILLS');
  const [spellFormMode, setSpellFormMode] = useState<TSpellFormMode>();
  const [selectedEnemy, setEnemy] = useState<string>();
  const [spells, setSpells] = useState<ISpellState>({} as ISpellState);

  const {
    data: skillTemplate = {} as ISkillTemplate,
    isLoading,
    refetch,
  } = useQuery<ISkillTemplate, Error>(
    ['SKILL_TEMPLATE', gameType, raceName, className, kitName, dex, wis],
    fetchSkillTemplate,
    {
      enabled: !!gameType && !!raceName && !!className && !!kitName,
      onSuccess: handleSuccess,
    }
  );

  function handleSuccess(data: ISkillTemplate) {
    setAvailablePts(data.weaponPts);
    if (isRanger(className)) {
      setWeaponMap({ TWO_WEAPON_STYLE: 2 });
    } else {
      setWeaponMap({});
    }
    if (data.isThief && typeof data.thiefSkillPoints === 'number') {
      setAvailableThiefPts(data.thiefSkillPoints);
    }
  }

  const thiefSkills = getThiefSkillsToDisplay(skillTemplate.baseThiefSkills, bonusThiefSkills);

  function fetchSkillTemplate() {
    return fetchSkillTemplateAPI({ className, kitName, gameType, raceName, dex, wis });
  }

  function _handleSave() {
    if (availablePts > 0 || thiefPts > 0) {
      alert('you have unspent proficiency points');
    } else {
      if (isRanger(className) && formMode === 'SKILLS') {
        setFormMode('ENEMY');
      } else if (canCastSpells(skillTemplate) && formMode !== 'SPELLS') {
        setFormMode('SPELLS');
        const mode = getNextSpellForm();
        if (mode) {
          setSpellFormMode(mode);
        }
      } else if (formMode === 'SPELLS' && getNextSpellForm()) {
        setSpellFormMode(getNextSpellForm());
      } else {
        const {
          knownArcaneSpells = [],
          memorizedArcaneSpells = [],
          memorizedDivineSpells = [],
          memorizedDruidSpells = [],
        } = spells;

        const isSorc = className === 'SORCERER';

        handleSave({
          weaponSkills: weaponMap,
          thiefSkills: {
            ...skillTemplate.baseThiefSkills,
            bonusSkillMap: bonusThiefSkills,
          },
          hp: skillTemplate.hp,
          isThief: skillTemplate.isThief,
          knownArcaneSpells: knownArcaneSpells.map((x) => x.spellType),
          memorizedArcaneSpells: isSorc
            ? knownArcaneSpells.map((x) => x.spellType)
            : memorizedArcaneSpells.map((x) => x.spellType),
          memorizedDivineSpells: memorizedDivineSpells.map((x) => x.spellType),
          memorizedDruidSpells: memorizedDruidSpells.map((x) => x.spellType),
          racialEnemy: selectedEnemy,
        });
      }
    }
  }

  function handleCancel() {
    if (formMode === 'SKILLS') {
      handleClose();
    }
    if (formMode === 'ENEMY') {
      refetch();
      setFormMode('SKILLS');
    }
    if (formMode === 'SPELLS') {
      if (isRanger(className)) {
        setFormMode('ENEMY');
      } else {
        if (spellFormMode === 'MEM_ARCANE') {
          setSpellFormMode('ARCANE');
        } else {
          setFormMode('SKILLS');
        }
      }
    }
  }

  function getNextSpellForm() {
    if (formMode !== 'SPELLS' && !spellFormMode && skillTemplate.canCastArcane) {
      return 'ARCANE';
    }
    if (formMode !== 'SPELLS' && !spellFormMode && skillTemplate.canCastDivine) {
      return 'DIVINE';
    }
    if (formMode !== 'SPELLS' && !spellFormMode && skillTemplate.canCastDruid) {
      return 'DRUID';
    }
    if (formMode === 'SPELLS' && spellFormMode === 'ARCANE') {
      if (className != 'SORCERER') {
        return 'MEM_ARCANE';
      }
    }
    if (formMode === 'SPELLS' && skillTemplate.canCastDivine && (!spellFormMode || spellFormMode === 'MEM_ARCANE')) {
      return 'DIVINE';
    }
    if (formMode === 'SPELLS' && skillTemplate.canCastDruid && !spellFormMode) {
      return 'DRUID';
    }
    return undefined;
  }

  function handleAddPoint(weapon: string) {
    if (availablePts > 0) {
      const currentPoints = weaponMap[weapon] || 0;
      if (canAddWeaponPt(weapon, currentPoints)) {
        setWeaponMap({ ...weaponMap, [weapon]: currentPoints + 1 });
        setAvailablePts(availablePts - 1);
      }
    }
  }

  function canAddWeaponPt(weapon: string, currentPoint: number) {
    if (currentPoint === 0) {
      return true;
    }
    if (currentPoint === 1 && skillTemplate.twoPtWeapons?.includes(weapon)) {
      return true;
    }
    if (currentPoint === 2 && skillTemplate.threePtWeapons?.includes(weapon)) {
      return true;
    }
    return false;
  }

  function handleMinusPoint(weapon: string) {
    if (isRanger(className) && weapon === 'TWO_WEAPON_STYLE' && weaponMap[weapon] === 2) {
      return;
    }
    if (typeof weaponMap[weapon] === 'number' && weaponMap[weapon] > 0) {
      const pts = weaponMap[weapon] - 1;
      setWeaponMap({ ...weaponMap, [weapon]: pts });
      setAvailablePts(availablePts + 1);
    }
  }

  function handleAddThiefPoints(skill: keyof IThiefSkillMap) {
    if (thiefPts >= 5) {
      const skillKey = `${_.snakeCase(skill)}`.toUpperCase() as TThiefSkill;
      const currentPts = (bonusThiefSkills[skillKey] || 0) + 5;
      setBonusThiefSkills({ ...bonusThiefSkills, [skillKey]: currentPts });
      setAvailableThiefPts(thiefPts - 5);
    }
  }

  function handleMinusThiefPoints(skill: keyof IThiefSkillMap) {
    const skillKey = `${_.snakeCase(skill)}`.toUpperCase() as TThiefSkill;
    const currentPts = bonusThiefSkills[skillKey] || 0;
    if (currentPts >= 5 && currentPts !== 0) {
      setBonusThiefSkills({ ...bonusThiefSkills, [skillKey]: currentPts - 5 });
      setAvailableThiefPts(thiefPts + 5);
    }
  }

  return (
    <div className="flex-col flex-1  flex-between">
      <div className="es-ch-form__skills">
        {formMode === 'SKILLS' && (
          <>
            <div className="flex-col es-ch-form__races flex-1 " style={{ padding: '.5rem 2rem' }}>
              {skillTemplate.availableWeapons && (
                <WeaponForm
                  availableWeapons={skillTemplate.availableWeapons}
                  availablePts={availablePts}
                  weaponMap={weaponMap}
                  handleAddSkill={handleAddPoint}
                  handleMinusSkill={handleMinusPoint}
                />
              )}
            </div>
            <div className="flex-col flex-1" style={{ padding: '.5rem 2rem' }}>
              {skillTemplate.isThief && (
                <ThiefSkillsForm
                  thiefSkills={thiefSkills}
                  thiefPts={thiefPts}
                  kitName={kitName}
                  handleAddSkill={handleAddThiefPoints}
                  handleMinusSkill={handleMinusThiefPoints}
                />
              )}
            </div>
          </>
        )}
        {formMode === 'ENEMY' && (
          <div className="flex">
            <div className="flex-col es-ch-form__races flex-0-3">
              <div style={{ textAlign: 'center' }}>
                <h3>Racial Enemy</h3>
              </div>
              {!isLoading &&
                (skillTemplate?.racialEnemies ?? []).map((enemy: string, i: number) => (
                  <button
                    key={_.camelCase(enemy) + i}
                    className={cn('es-button es-button--primary es-ch-form__option-btn', {
                      'es-button--selected': enemy === selectedEnemy,
                    })}
                    id={`${_.camelCase(enemy)}Btn`}
                    type="button"
                    style={{ width: '22rem' }}
                    onClick={() => setEnemy(enemy)}>
                    {_.lowerCase(enemy)}
                  </button>
                ))}
            </div>
            <div
              className="flex-col flex-0-5"
              style={{
                backgroundColor: 'black',
                minWidth: '50rem',
                margin: '1rem',
                marginRight: '2rem',
                padding: '1rem',
              }}>
              <p style={{ fontSize: '1rem', padding: '1rem' }}>Need to add racial enemy descriptions here</p>
            </div>
          </div>
        )}
        {formMode === 'SPELLS' && spellFormMode && (
          <SpellForm
            skillTemplate={skillTemplate}
            spells={spells}
            kitName={kitName}
            className={className}
            modifySpells={(spells) => setSpells(spells)}
            formMode={spellFormMode}
          />
        )}
      </div>
      <div className="flex f-a-c flex-center">
        <button
          className="es-button es-button--primary es-ch-form__option-btn"
          id={'cancelCurrentStep'}
          type="button"
          onClick={handleCancel}>
          Cancel
        </button>
        <button
          className="es-button es-button--primary es-ch-form__option-btn"
          id={'saveAlignment'}
          type="button"
          onClick={_handleSave}>
          Done
        </button>
      </div>
    </div>
  );
}

function isRanger(className: string) {
  return className === 'RANGER' || className === 'CLERIC_RANGER';
}

function canCastSpells(template: ISkillTemplate) {
  const { canCastArcane, canCastDivine, canCastDruid } = template;
  return canCastArcane || canCastDivine || canCastDruid;
}
