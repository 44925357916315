import React, { useEffect, useRef } from 'react';
import cn from 'classnames';
import { useCallback } from 'react';
import useOnScreen from '../hooks/useOnScreen';

interface ESModalProps {
  id: string;
  title: string | React.ReactNode;
  footer?: string | React.ReactNode;
  content?: string | React.ReactNode;
  children?: React.ReactNode;
  isVisible: boolean;
  handleClose: (e: React.MouseEvent | React.KeyboardEvent | KeyboardEvent) => void;
  closeOnOutsideClick?: boolean;
  size?: 'small' | 'medium' | 'large' | 'full';
  afterClose?: () => void;
  hideCornerCloseButton?: boolean;
}
export default function ESModal(props: ESModalProps) {
  const {
    id,
    title,
    footer,
    content,
    isVisible,
    handleClose,
    closeOnOutsideClick,
    children,
    size,
    afterClose,
    hideCornerCloseButton,
  } = props;

  const ref = useRef<HTMLDivElement>(null);
  const onModalOnScreen = useOnScreen(ref);

  function handleOutsideClick(e: React.MouseEvent<HTMLDivElement>) {
    if (closeOnOutsideClick) {
      handleClose(e);
    }
  }

  if (!onModalOnScreen && typeof afterClose === 'function') {
    if (!isVisible) {
      afterClose();
    }
  }

  const handleKeyDown = useCallback(
    (e: KeyboardEvent | React.KeyboardEvent) => {
      if (e.keyCode === 27 || e.key === 'Escape') {
        handleClose(e);
      }
    },
    [handleClose]
  );

  const modalSize = size || 'medium';

  useEffect(() => {
    const container = document.getElementById('container');
    if (isVisible) {
      if (container && !container.getAttribute('data-modal')) {
        document.addEventListener('keydown', handleKeyDown, false);
        container.setAttribute('data-modal', 'true');
      }
    } else {
      if (container && container.getAttribute('data-modal')) {
        document.removeEventListener('keydown', handleKeyDown, false);
        container.removeAttribute('data-modal');
      }
    }
  }, [isVisible, handleKeyDown]);

  return (
    <div
      onClick={handleOutsideClick}
      onKeyDown={handleKeyDown}
      id={id}
      className={cn('es-modal', { 'es-modal--visible': isVisible })}>
      <div
        className={cn('es-modal__dialog', `es-modal__dialog--${modalSize}`)}
        onKeyDown={handleKeyDown}
        onClick={(e) => e.stopPropagation()}
        ref={ref}>
        <header className="es-modal__header">
          {title}
          {!hideCornerCloseButton && (
            <button id={`${id}Close`} type="button" onClick={handleClose} className="es-modal__close-btn" title="Close">
              <svg className="es-icon es-icon--md">
                <use href="images/sprite.svg#icon-cancel-circle"></use>
              </svg>
            </button>
          )}
        </header>
        <section className="es-modal__content">
          {content && typeof content === 'string' && content.split('\n').map((x, i) => <p key={i}>{x}</p>)}
          {content && typeof content !== 'string' && <p>{content}</p>}
          {children}
        </section>
        {footer && <footer className="es-modal__footer">{footer}</footer>}
      </div>
    </div>
  );
}
