import { Header } from './components/Header';
import './styles/style.scss';

import { Outlet } from '@tanstack/router';

function App() {
  return (
    <div id="container" className="container">
      <Header />
      <Outlet />
      <Footer />
    </div>
  );
}

export default App;

export const Footer = () => {
  return (
    <footer className="es-footer">
      <div className="es-footer__wrapper fw">
        <div className="flex-col flex-center flex-1 f-a-c">
          <label className="es-label">Contact</label>
          <a className="es-a" href="mailto:info@bgautochar.com">
            Email
          </a>
        </div>
      </div>
    </footer>
  );
};
