import React from "react";
import _ from "lodash";
import CharacterCard from "../CharacterCard/CharacterCard";
import { CharacterType } from "../Types/CharacterType";
import cn from "classnames";

interface CharacterCardListProps {
  party: CharacterType[];
  loading: boolean;
  error?: string;
  gameType: "bg" | "iwd";
}

const loadingCards: React.ReactNode[] = [];
_.times(6, () => {
  loadingCards.push(
    <div className="es-char-card flex-1 es-char-card--loading" />
  );
});

const placeHolder = [0,1,2,3,4,5,6];

export default function CharacterCardList(props: CharacterCardListProps) {
  const { party = [], loading, error, gameType } = props;

  return (
    <div
      className="es-card-list flex-col flex-1 flex-1-1-7">
      {party && Array.isArray(party) &&
        party.map((character: CharacterType, index) => {
          return <CharacterCard key={`char_${index}`} character={character} index={index} gameType={gameType} />;
        })}
      {(loading) &&
        placeHolder.map((_, i: number) => (
          <div
            key={`placeholder${i}`}
            className={cn("es-char-card__wrapper flex-1 ", {
              "es-char-card--loading": loading,
              "es-char-card--error": error
            })}
          />
        ))}
    </div>
  );
}
