import { useLayoutEffect } from "react";
import cn from "classnames";
import { GameTypeState } from "./CharacterGeneratorPage/CharacterGeneratorPage";

interface GameTypeBannerProps {
  gameType: GameTypeState;
}

export function GameTypeBanner(props: GameTypeBannerProps) {
  const { gameType } = props;

  useLayoutEffect(() => {
    const options: IntersectionObserverInit = {
      rootMargin: "0px",
      threshold: 0.98,
    };

    const observer = new IntersectionObserver(callback, options);
    const headerElements = document.getElementById("gameTypeBanner");
    if (headerElements) {
      observer.observe(headerElements);
    }
  }, []);

  function callback(entries: IntersectionObserverEntry[]) {
    entries.forEach((entry: IntersectionObserverEntry) => {
      if (entry.isIntersecting) {
        const header = document.getElementById("characterGeneratorHeader");
        if (header) {
          header.classList.remove("header--scrolled");
        }
      } else {
        const header = document.getElementById("characterGeneratorHeader");
        if (header) {
          header.classList.add("header--scrolled");
        }
      }
    });
  }

  return (
    <div className="flex align-end">
      <div
        id="gameTypeBanner"
        className={cn("es-banner", { "es-banner--flip": gameType === "iwd" })}
      >
        <div className="es-banner__inner">
          <div className="es-banner--bg">
            <img
              id="bgLogo"
              title="Baldur's Gate"
              className="es-img__logo"
              src={"./images/bg-logo.png"}
              alt="baldurs gate"
            />
          </div>
          <div className="es-banner--iwd">
            <img
              id="iwdLogo"
              title="Icewind Dale"
              className="es-img__logo"
              src={"./images/iwd-logo.png"}
              alt="Icewind Dale"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default GameTypeBanner;
