import { useState } from 'react';
import _ from 'lodash';
import ESModal from '../../common/ESModal';
import { spellDescriptions } from '../../modules/constants';

interface SpellSectionProps {
  spells?: string[] | null;
  id: string;
}
export const SpellSection = (props: SpellSectionProps) => {
  const [modalOpen, toggleModal] = useState<boolean>(false);
  const [spellForModal, setSpellForModal] = useState<string>('');
  const { spells, id } = props;

  function handleClick(spell: string) {
    setSpellForModal(spell);
    toggleModal(true);
  }

  function handleClose() {
    toggleModal(false);
  }

  if (!spells) {
    return null;
  }

  let screamingCaseSpell = spellForModal;
  if (screamingCaseSpell.includes('CLERIC')) {
    const lastIndex = screamingCaseSpell.lastIndexOf('_');
    screamingCaseSpell = screamingCaseSpell.slice(0, lastIndex);
  }
  const camelSpell: string = _.camelCase(screamingCaseSpell);

  const spellDesc: string = spellDescriptions[camelSpell];

  return (
    <section className="center-text">
      <h5>{_.startCase(id)} Spells</h5>
      <div className="es-char-card__section">
        {spells.map((spell, index) => {
          const spellImg = `./images/spells/${_.snakeCase(spell).trim()}.PNG`;

          return (
            <div
              className="es-pill es-pill--main-theme flex"
              key={`${id}${index}`}
              style={{ justifyContent: 'space-between' }}
              onClick={() => handleClick(spell)}
              tabIndex={0}>
              <img
                id="spell"
                className="es-char-card__spell"
                src={spellImg}
                alt=""
              />
              <span>{_.startCase(spell)}</span>
              <svg className="es-icon es-icon--sm es-pill__icon">
                <use href="images/sprite.svg#icon-info"></use>
              </svg>
            </div>
          );
        })}
      </div>
      <ESModal
        id="spellModal"
        title={_.startCase(spellForModal)}
        content={spellDesc}
        isVisible={modalOpen}
        closeOnOutsideClick={true}
        handleClose={handleClose}
        afterClose={() => setSpellForModal('')}
      />
    </section>
  );
};
