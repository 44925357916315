import React, { useState, useCallback, useEffect } from 'react';
import ActionPanel from '../ActionPanel/ActionPanel';
import CharacterCardList from '../CharacterCardList/CharacterCardList';
import { CharacterType } from '../Types/CharacterType';
import { LinkShare } from '../LinkShare/LinkShare';
import { useQuery, useQueryClient } from 'react-query';
import { fetchPartyByGameType, handleDownloadParty } from '../../api/apiUtil';
import ESModal from '../../common/ESModal';
import { InstructionToggleComponent } from '../InstructionToggleComponent/InstructionToggleComponent';

export const PARTY = 'PARTY';
export type GameTypeState = 'bg' | 'iwd';


export default function CharacterGeneratorPage() {
  const [gameType, setGameType] = useState<GameTypeState>('bg');
  const [errorModalOpen, toggleErrorModal] = useState<boolean>(false);

  const queryclient = useQueryClient();

  const clearCurrentParty = useCallback(() => {
    queryclient.removeQueries([PARTY, gameType]);
  }, [queryclient, gameType]);

  const fetchParty = useCallback(() => {
    return fetchPartyByGameType(gameType);
  }, [gameType]);

  const { data, isLoading, isFetching, isError, refetch } = useQuery<CharacterType[]>([PARTY, gameType], fetchParty, {
    keepPreviousData: false,
    staleTime: 1000 * 60 * 60 * 5,
    cacheTime: 1000 * 60 * 60 * 5
  });

  const loading = isLoading || isFetching;
  const errorMsg = isError ? 'Error fetching party' : undefined;

  function handleFetchPartyClick() {
    clearCurrentParty();
    refetch();
  }

  useEffect(() => {
    if (errorMsg) {
      toggleErrorModal(true);
    }
  }, [errorMsg]);

  function handleCloseModal() {
    toggleErrorModal(false);
  }

  function toggleGameType() {
    clearCurrentParty();
    if (gameType === 'bg') {
      setGameType('iwd');
    } else {
      setGameType('bg');
    }
  }

  async function downloadParty(e: React.MouseEvent) {
    e.stopPropagation();
    if (data && Array.isArray(data)) {
      await handleDownloadParty(data).catch((error) => {
        console.error(error);
      });
    }
  }

  return (
    <main className="main">
      <div className="content content--column">
        <ActionPanel
          gameType={gameType}
          toggleGameType={toggleGameType}
          fetchParty={handleFetchPartyClick}
          downloadParty={downloadParty}
        />
        <InstructionToggleComponent />
        <CharacterCardList party={data || []} loading={loading} error={errorMsg} gameType={gameType} />
      </div>
      <ESModal
        id="classModal"
        title={'Error'}
        content={errorMsg}
        isVisible={errorModalOpen}
        closeOnOutsideClick={true}
        handleClose={handleCloseModal}
      />
      <LinkShare />
    </main>
  );
}
