import { useMutation, useQueryClient } from 'react-query';
import { CharacterType } from '../components/Types/CharacterType';
import { reRollCharacter } from '../api/apiUtil';
import {
  GameTypeState,
  PARTY,
} from '../components/CharacterGeneratorPage/CharacterGeneratorPage';


/**
 * Mutation function to reroll a single character
 */
interface IReRollMutation {
  index: number;
  character: CharacterType;
  gameType: GameTypeState;
}
export function useReRollMutation() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (req: IReRollMutation) => reRollCharacter(req.character),
    onSuccess: (response: CharacterType, variables: IReRollMutation) => {
      const party = queryClient.getQueryData<CharacterType[]>([
        PARTY,
        variables.gameType,
      ]);
      if (Array.isArray(party)) {
        const { index } = variables;
        const newParty = [...party];
        newParty.splice(index, 1, response);
        queryClient.setQueryData([PARTY, variables.gameType], newParty);
      }
    },
    onError: error => {
        console.log("Error: ", error)
    }
  });
}
