import React from 'react';
import cn from "classnames";

export function InstructionToggleComponent() {
  const [showExpanded, toggleExpanded] = React.useState<boolean>(false);

  function clickHandler() {
    toggleExpanded(!showExpanded);
  }
  function handleKeyDown(e: React.KeyboardEvent) {
    if (e.key === 'Enter' || e.key === ' ') {
      toggleExpanded(!showExpanded);
    }
  }

  return (
    <React.Fragment>
      <div
        className={cn('flex-col es-char-card__wrapper')}
        onClick={clickHandler}
        tabIndex={0}
        style={{ cursor: 'pointer' }}
        onKeyDown={handleKeyDown}>
        <div style={{ margin: '.25rem .5rem' }}>
          <h4>How to use</h4>
        </div>
        <div
          className={cn('es-card__tray-icon', {
            'es-card__tray-icon--open': showExpanded,
          })}>
          <svg className={cn('es-icon es-icon--md es-icon--rd')}>
            <use href="images/sprite.svg#icon-circle-left"></use>
          </svg>
        </div>
      </div>
      <div
        className={cn('es-card__tray', {
          'es-card__tray--hidden': !showExpanded,
        })}>
        <section className="instructions" style={{ fontSize: '1rem' }}>
          <p>
            Generate and download the character .chr file for either Baldur's
            Gate or Icewind Dale. <br />
            Rename the .chr file so that it does not contain any illegal
            characters like '(' or ')'. <br /> Ensure the .chr filename is{' '}
            <strong>no longer than 8 characters. </strong>
            <br /> Locate your 'characters' folder for the respective game. If
            the directory does not exist, simply create it. <br /> Move the .chr
            file into this directory.
            <br /> The character should now be accessible when you click
            "import" on any character generator screen. <br />
            If the character does not appear it could mean you broke one of the
            naming rules. <br />
            If the character still does not appear, feel free to contact us for
            help. <br />
            Also please submit any bugs or issues to the contact email below!
          </p>
          <p>Thanks for using BG Auto Character Generator!</p>
        </section>
      </div>
    </React.Fragment>
  );
}
