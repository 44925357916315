import { useNavigate } from '@tanstack/router';

export function CharacterFormNav() {
  const navigate = useNavigate({ from: '/character-form' });
  return (
    <aside className="actions flex-col" style={{ flex: '1 1 15%' }}>
      <div className="es-form-control flex-row">
        <div className="es-button-group f-a-c">
          <button id="randomizerLink" className="es-button es-button--primary" onClick={() => navigate({ to: '/' })}>
            Back
          </button>
        </div>
      </div>
    </aside>
  );
}
