
import _ from "lodash";

interface ClassImageProps {
  classes: string[];
}

const ILLUSIONIST = "ILLUSIONIST";
const MAGE = "MAGE";

export const ClassImage = (props: ClassImageProps) => {
  const imgSrcs = props.classes.map((chClass: string) => {
    const classForLookup = chClass === ILLUSIONIST ? MAGE : chClass;
    return `./images/classIcon/${_.lowerCase(classForLookup).trim()}.jpeg`;
  });
  return (
    <div className="es-char-card__class-icon-group">
      {imgSrcs.map((src, i) => {
        return (
          <img
            key={`${src}Icon${i}`}
            id={`${src}Icon${i}`}
            className="es-char-card__class-icon"
            src={src}
            alt=""
          />
        );
      })}
    </div>
  );
};

export default ClassImage;
