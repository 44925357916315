import CharacterAvatar from '../CharacterCard/CharacterAvatar';
import { CharacterType } from '../Types/CharacterType';

interface ICharacterFormPortrait {
  character: Partial<CharacterType>;
}

export function CharacterFormPortrait(props: ICharacterFormPortrait) {
  const { character } = props;
  const { iwd } = character;

  return (
    <div className="flex-col f-a-c">
      {typeof character.portrait === 'string' && !!character.gender && (
        <CharacterAvatar
          iwd={character.iwd || false}
          portrait={character.portrait}
          className={character.className || ''}
          hp={character.hp}
          charName={character.charName}
          gender={character.gender}
          large
        />
      )}
      {renderGameLogo(iwd)}
    </div>
  );
}

function renderGameLogo(iwd?: boolean) {
  if (typeof iwd !== 'boolean') {
    return null;
  }
  if (iwd) {
    return (
      <img
        id="iwdLogo"
        title="Icewind Dale"
        className="es-img__logo"
        src={'./images/iwd-logo.png'}
        alt="Icewind Dale"
      />
    );
  } else {
    return (
      <img id="bgLogo" title="Baldur's Gate" className="es-img__logo" src={'./images/bg-logo.png'} alt="baldurs gate" />
    );
  }
}
