import { useState } from 'react';
import { ISkillTemplate, ISpellType } from '../Types/CharacterType';
import _ from 'lodash';
import { ISpellState, TSpellStateKey } from './SkillForm';
import { SPELL_DESCRIPTIONS } from '../../modules/descriptions';

interface ISpellForm {
  skillTemplate: ISkillTemplate;
  spells: ISpellState;
  modifySpells: (spells: ISpellState) => void;
  kitName: string;
  className: string;
  formMode: TSpellFormMode;
}

export type TSpellFormMode = 'ARCANE' | 'MEM_ARCANE' | 'DIVINE' | 'DRUID';

export function SpellForm(props: ISpellForm) {
  const { skillTemplate, spells, modifySpells, formMode, kitName, className } = props;
  const {
    availableArcaneSpells,
    availableDivineSpells,
    availableDruidSpells,
    numArcaneSpells = 0,
    numDivineSpells = 0,
    spellSchool,
  } = skillTemplate;
  const {
    knownArcaneSpells = [],
    memorizedArcaneSpells = [],
    memorizedDivineSpells = [],
    memorizedDruidSpells = [],
  } = spells;
  const [selectedSpell, setSelectedSpell] = useState<ISpellType>();

  const title = {
    ARCANE: 'Mage Book: Level 1',
    MEM_ARCANE: 'Memorize Mage Spells: Level 1',
    DIVINE: 'Memorize Priest Spells: Level 1',
    DRUID: 'Memorize Druid Spells: Level 1',
  };

  const isSpecialist = isMageSpecialist(className, kitName);

  const description = {
    ARCANE: (
      <span>
        You may choose {numArcaneSpells} spells to put in your spellbook. These will be the spells that your character
        currently has knowledge of at the beginning of the game. <br /> <br /> Specialist Mage spells that belong to
        their school have a green outline on them. You <strong>must</strong> pick one of these spells before continuing
        on.
      </span>
    ),
    MEM_ARCANE: getMemorizeSpellPageDescription((numArcaneSpells || 1) - 1),
    DIVINE: getMemorizeSpellPageDescription(numDivineSpells || 0),
    DRUID: getMemorizeSpellPageDescription(numDivineSpells || 0),
  };

  function getMemorizeSpellPageDescription(numSpells: number) {
    return (
      <span>
        You may memorize {numSpells} spells from the above list. Choose which spells your character will have memorized
        at the beginning of the game.
      </span>
    );
  }

  const hasSpecialistSpell = knownArcaneSpells.some((x) => x.spellSchool === spellSchool);

  const availableSpells =
    formMode === 'ARCANE'
      ? availableArcaneSpells
      : formMode === 'MEM_ARCANE'
      ? knownArcaneSpells
      : formMode === 'DIVINE'
      ? availableDivineSpells
      : availableDruidSpells;

  function handleAddSpell(spell: ISpellType, formMode: TSpellFormMode) {
    setSelectedSpell(spell);
    if (formMode === 'ARCANE' && !knownArcaneSpells.includes(spell)) {

      if (numArcaneSpells - knownArcaneSpells.length === 1 && isSpecialist) {
        if (hasSpecialistSpell || spell.spellSchool === spellSchool) {
          modifySpells({ ...spells, knownArcaneSpells: [...(knownArcaneSpells ?? []), spell] });
        } else {
          alert('Must pick at least one spell from your specialist school');
        }
      } else if (knownArcaneSpells.length < numArcaneSpells) {
        modifySpells({ ...spells, knownArcaneSpells: [...(knownArcaneSpells ?? []), spell] });
      }

    }
    if (formMode === 'MEM_ARCANE') {
      if (memorizedArcaneSpells.length < (numArcaneSpells || 1) - 1) {
        modifySpells({ ...spells, memorizedArcaneSpells: [...(memorizedArcaneSpells ?? []), spell] });
      }
    }
    if (formMode === 'DIVINE') {
      if (memorizedDivineSpells.length < (numDivineSpells || 1)) {
        modifySpells({ ...spells, memorizedDivineSpells: [...(memorizedDivineSpells ?? []), spell] });
      }
    }
    if (formMode === 'DRUID') {
      if (memorizedDruidSpells.length < (numDivineSpells || 1)) {
        modifySpells({ ...spells, memorizedDruidSpells: [...(memorizedDruidSpells ?? []), spell] });
      }
    }
  }

  function handleRemoveSpell(spell: ISpellType, formMode: TSpellFormMode) {
    setSelectedSpell(spell);

    const spellObject = getSpellObject(formMode);
    if (spellObject && spellObject.spells) {
      const { spells, key } = spellObject;
      if (Array.isArray(spells) && spells.includes(spell) && key) {
        const removedSpells = removeFirst(spells, spell);
        //@ts-ignore
        modifySpells({ ...spells, [key]: removedSpells });
      }
    }
  }

  function renderSpellNumber(spell: ISpellType, formMode: TSpellFormMode): number {
    const spellObject = getSpellObject(formMode);
    if (spellObject && spellObject.spells) {
      if (formMode === 'ARCANE') {
        return spellObject.spells.includes(spell) ? 1 : 0;
      }
      return spellObject.spells.filter((x) => x === spell).length;
    }
    return 0;
  }

  function getSpellObject(mode: TSpellFormMode): { spells: ISpellType[]; key: TSpellStateKey } | undefined {
    const spellObj: Record<TSpellFormMode, { spells: ISpellType[]; key: TSpellStateKey }> = {
      ARCANE: { spells: knownArcaneSpells, key: 'knownArcaneSpells' },
      MEM_ARCANE: { spells: memorizedArcaneSpells, key: 'memorizedArcaneSpells' },
      DIVINE: { spells: memorizedDivineSpells, key: 'memorizedDivineSpells' },
      DRUID: { spells: memorizedDruidSpells, key: 'memorizedDruidSpells' },
    };
    if (spellObj[mode]) {
      return spellObj[mode];
    }
    return undefined;
  }

  return (
    <div className="flex-col">
      <div style={{ textAlign: 'center' }}>
        <h3>{(formMode && title[formMode]) || 'Spell Form'}</h3>
      </div>
      <div className="es-ch-form__spell-form">
        <div className="flex-col es-ch-form__races es-ch-form__spell-list">
          {(availableSpells ?? []).map((spell: ISpellType) => {
            const spellName = spell.spellType;
            const spellImg = `./images/spells/${_.snakeCase(spell.spellType).trim()}.PNG`;
            return (
              <div key={spellName} className="flex flex-between f-a-c">
                <div
                  className="es-pill es-pill--main-theme flex flex-between flex-1-7"
                  style={spellSchool === spell.spellSchool ? { borderColor: 'yellow' } : undefined}>
                  <img id="spell" className="es-char-card__spell" src={spellImg} alt="" />
                  <div className="capital">{_.lowerCase(spellName)}</div>
                </div>

                <div
                  className="es-pill es-pill--main-theme flex flex-between"
                  style={{ width: '2.5rem', textAlign: 'center' }}>
                  <div>{renderSpellNumber(spell, formMode)}</div>
                </div>
                <div className="flex flex-center flex-1">
                  <button
                    style={{
                      height: '2rem',
                      width: '2rem',
                      margin: '0 5px',
                    }}
                    className="es-button es-button--primary es-button--small"
                    id={`${_.camelCase(spellName)}Add`}
                    type="button"
                    onClick={() => handleAddSpell(spell, formMode)}>
                    +
                  </button>
                  <button
                    style={{
                      height: '2rem',
                      width: '2rem',
                      margin: '0 0 0 5px',
                    }}
                    className="es-button es-button--primary es-button--small"
                    id={`${_.camelCase(spellName)}Subtract`}
                    type="button"
                    onClick={() => handleRemoveSpell(spell, formMode)}>
                    -
                  </button>
                </div>
              </div>
            );
          })}
          <div className="flex">
            <div className="es-pill es-pill--main-theme flex flex-between flex-1-7">
              <div className="capital">Spells Remaining :</div>
            </div>
            <div
              className="es-pill es-pill--main-theme flex flex-between"
              style={{ width: '2.5rem', textAlign: 'center' }}>
              <div>
                {formMode === 'ARCANE'
                  ? numArcaneSpells - knownArcaneSpells.length
                  : formMode === 'MEM_ARCANE'
                  ? knownArcaneSpells.length - 1 - memorizedArcaneSpells.length
                  : formMode === 'DIVINE'
                  ? numDivineSpells - memorizedDivineSpells.length
                  : numDivineSpells - memorizedDruidSpells.length}
              </div>
            </div>
          </div>
        </div>
        <div className="flex-col es-ch-form__spell-description">
          <p style={{ fontSize: '1rem', padding: '1rem' }}>
            {formMode ? description[formMode] : 'description here'}
            <br />
            <br />
            <hr />
            <br />
            {selectedSpell ? (
              <span className="capital">
                {_.lowerCase(selectedSpell.spellType)} <br />({_.lowerCase(selectedSpell.spellSchool)})
                <br />
                {SPELL_DESCRIPTIONS[_.camelCase(selectedSpell.spellType)]}
              </span>
            ) : (
              ''
            )}
          </p>
        </div>
      </div>
    </div>
  );
}

function removeFirst(array: object[], element: object) {
  const index = array.indexOf(element);
  if (index === -1) return array;
  return [...array.slice(0, index), ...array.slice(index + 1)];
}

function isMageSpecialist(className: string, kitName: string) {
  if (kitName === 'TRUE_CLASS') {
    return className.includes('ILLUSIONIST');
  }
  return true;
}
