import React, { useState, Fragment } from "react";
import ESModal from "../../common/ESModal";
import _ from "lodash";
import { RACE_DESCRIPTIONS } from "../../modules/descriptions";

interface RacePillProps {
  race: string;
}

export const RacePill = (props: RacePillProps) => {
  const [modalOpen, toggleModal] = useState<boolean>(false);
  const { race } = props;
  const raceDescriptionForModal: React.ReactNode = RACE_DESCRIPTIONS[race];

  function handleClick(e: React.MouseEvent) {
    e.stopPropagation();
    toggleModal(true);
  }

  function handleKeyDown(e: React.KeyboardEvent) {
    if (e.key === "Enter" || e.keyCode === 13) {
      e.stopPropagation();
      toggleModal(true);
    }
  }

  return (
    <Fragment>
      <div
        onClick={handleClick}
        className="es-pill es-pill--main-theme es-pill--btn col-span-2 grid-center-self"
        tabIndex={0}
        onKeyDown={handleKeyDown}
      >
        <p>{_.startCase(race)}</p>
        <svg className="es-icon es-icon--sm es-pill__icon">
          <use href="images/sprite.svg#icon-info"></use>
        </svg>
      </div>
      <ESModal
        id="classModal"
        title={_.startCase(race)}
        children={raceDescriptionForModal}
        isVisible={modalOpen}
        closeOnOutsideClick={true}
        handleClose={() => toggleModal(false)}
      />
    </Fragment>
  );
};
