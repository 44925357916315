export const ALIGNMENT_DESCRIPTIONS: Record<string, string | React.ReactNode> = {
  LAWFUL_GOOD:
    'Characters of this alignment believe that an orderly, strong society with a well-organized government can work to make life better for the majority of the people. To ensure the quality of life, laws must be created and obeyed. When people respect the laws and try to help one another, society as a whole prospers. Therefore, lawful good characters strive for those things that will bring the greatest benefit to the most people and cause the least harm. An honest and hard-working serf, a kindly and wise king, or a stern but forthright minister of justice are all examples of lawful good people',
  NEUTRAL_GOOD:
    'These characters believe that a balance of forces is important, but that the concners of law and chaos do not moderate the need for good. Since the universe is vast and contains many creatures striving for different goals, a determined pursuit of good will not upset the balance; it may even maintain it. If fostering good means supporting organized society, than that is what must be done. If good can only come about through the overthrow of existing social order, so be it.  Social structure itself has no innate value to them. A baron who violates the orders of his king to destroy something he sees as evil is an example of a neutral good character',
  CHAOTIC_GOOD:
    'Chaotic good characters are strong individualists marked by a streak of kindness and benevolence. They believe in all the virtues of goodness and right, but they have little use for laws and regulations. They have no use for people who “try to push folk around and tell them what to do.” Their actions are guided by their own moral compass which, although good, may not always be in perfect agreement with the rest of society. A brave frontiersman forever moving on as settlers follow in his wake is an example of a chaotic good character.',
  LAWFUL_NEUTRAL:
    'Order and organization are of paramount importance to characters of this alignment. They believe in a strong, well-ordered government, whether that government is a tyranny or benevolent democracy. The benefits of organization and regimentation outweigh any moral questions raised by their actions. An inquisitor determined to ferret out traitors at any cost or a soldier who never questions his orders are good examples of lawful neutral behavior.',
  TRUE_NEUTRAL: (
    <span>
      True neutral characters believe in the ultimate balance of forces, and they refuse to see actions as either good
      or evil. Since the majority of people in the world make judgments, true neutral characters are extremely rare.
      True neutrals do their best to avoid siding with the forces of either good or evil, law or chaos. It is their duty
      to see that all of these forces remain in balanced contention. <br /> <br />
      True neutral characters sometimes find themselves forced into rather peculiar alliances. To a great extent, they
      are compelled to side with the underdog in any given situation, sometimes even changing sides as the previous
      loser becomes the winner. A true neutral druid might join the local barony to put down a tribe of evil gnolls,
      only to drop out or switch sides when the gnolls were brought to the brink of destruction. He would seek to
      prevent either side from becoming too powerful. Clearly there are very few true neutral characters in the world.{' '}
    </span>
  ),
  CHAOTIC_NEUTRAL:
    'Chaotic neutral characters believe that there is no order to anything, including their own actions. With this as a guiding principle, they tend to follow whatever whim strikes them at the moment. Good and evil are irrelevant when making a decision. Chaotic neutral characters are extremely difficult to deal with. Such characters have been known to cheerfully and for no apparent purpose gamble away everything they have on the roll of a single die. They are almost totally unreliable. In fact, the only reliable thing about them is that they cannot be relied upon! This alignment is perhaps the most difficult to play. Lunatics and madmen tend toward chaotic neutral behavior.',
  LAWFUL_EVIL:
    'These characters believe in using society and its laws to benefit themselves. Structure and organization elevate those who deserve to rule as well as provide a clearly defined hierarchy between master and servant. To this end, lawful evil characters support laws and societies that protect their own concerns. If someone is hurt or suffers because of a law that benefits lawful evil characters, too bad. Lawful evil characters obey laws out of fear of punishment. Because they may be forced to honor an unfavorable contract or oath they have made, lawful evil characters are usually very careful about giving their word. Once given, they break their word only if they can find a way to do it legally, within the laws of the society. An iron-fisted tyrant and a devious, greedy merchant are examples of lawful evil beings.',
  NEUTRAL_EVIL:
    'Neutral Evil characters are primarily concerned with themselves and their own advancement. They have no particular objection to working with others or, for that matter, going it on their own. Their only interest is in getting ahead. If there is a quick and easy way to gain a profit, whether it be legal, questionable, or obviously illegal, they take advantage of it. Although neutral evil characters do not have the every-man-for-himself attitude of chaotic characters, they have no qualms about betraying their friends and companions for personal gain. They typically base their allegiance on power and money, which makes them quite receptive to bribes. An unscrupulous mercenary, a common thief, and a double-crossing informer who betrays people to the authorities to protect and advance himself are typical examples of neutral evil characters.',
  CHAOTIC_EVIL:
    'These characters are the bane of all that is good and organized. Chaotic evil characters are motivated by the desire for personal gain and pleasure. They see absolutely nothing wrong with taking whatever they want by whatever means possible. Laws and government are the tools of weaklings unable to fend for themselves. The strong have the right to take what they want, and the weak are there to be exploited. When chaotic evil characters band together, they are not motivated by a desire to cooperate, but rather to oppose powerful enemies. Such a group can be held together only by a strong leader capable of bullying his underlings into obedience. Since leadership is based on raw power, a leader is likely to be replaced at the first sign of weakness by anyone who can take his position away from him by any method. Bloodthirsty buccaneers and monsters of low Intelligence are find examples of chaotic evil personalities.',
};

export const SPELL_DESCRIPTIONS: Record<string, string | React.ReactNode> = {
  armor: (
    <span>
      Level: 1 <br />
      Range: 0 <br />
      Duration: 9 hours <br />
      Casting Time: 9 <br />
      Area of Effect: The caster <br />
      Saving Throw: None <br />
      <br />
      <br />
      By means of this spell, the wizard creates a magical field of force that serves as if it were scale mail armor
      (Armor Class: 6). It is cumulative with Dexterity and, in the case of Fighter/Mages, with the shield bonus. The
      Armor spell does not hinder movement, adds no weight or encumbrance, and does not prevent spellcasting. It lasts
      until successfully dispelled or until the duration runs out.
    </span>
  ),
  armorOfFaith: (
    <span>
      Level: 1 <br />
      Sphere: Protection <br />
      Range: 0 <br />
      Duration: 3 rounds + 1 round/level <br />
      Casting Time: 1 <br />
      Area of Effect: The caster <br />
      Saving Throw: None <br />
      <br />
      <br />
      "The caster of the Armor of Faith receives significant protection against melee and magical attacks. This magical
      armor is a force of energy that absorbs a portion of the damage intended for the caster. At 1st level, the
      protection is 5%, and every 5 levels of the caster improves this by another 5%: For example, a 20th-level priest
      would have 25% of his damage 'absorbed' if protected by this spell.",
    </span>
  ),
  bless: (
    <span>
      Level: 1 <br />
      Sphere: All <br />
      Range: 40 ft. <br />
      Duration: 6 rounds
      <br />
      Casting Time: 9 <br />
      Area of Effect: 15-ft. radius <br />
      Saving Throw: None <br />
      <br />
      <br />
      Upon uttering the Bless spell, the caster raises the morale of friendly creatures by +1. Furthermore, it raises
      their attack and damage rolls by +1. The caster determines at what range (up to 40 ft.) the spell will be cast. At
      the instant the spell is completed, it affects all creatures in a 15-ft. radius centered on the point selected by
      the caster (thus, affected creatures leaving the area are still subject to the spell's effects; those entering the
      area after the casting is completed are not).
    </span>
  ),
  blindness: (
    <span>
      Level: 1 <br />
      Range: 40 ft. <br />
      Duration: 2 hours <br />
      Casting Time: 2 <br />
      Area of Effect: 1 creature <br />
      Saving Throw: Neg. <br />
      <br />
      <br />
      This 1st-level spell temporarily blinds its target. A Saving Throw is allowed and, if successful, there are no
      harmful effects. If a victim is blinded, he receives a -4 penalty to his attack rolls and Armor Class.
    </span>
  ),
  burningHands: (
    <span>
      Level: 1 <br />
      Range: 0 <br />
      Duration: Instant <br />
      Casting Time: 1 <br />
      Area of Effect: 5-ft cone with 120-deg arc
      <br />
      Saving Throw: 1/2 <br />
      <br />
      <br />
      When the wizard casts this spell, a jet of searing flame shoots from his fingertips. The wizard's hands must be
      held so as to send forth a fanlike sheet of flames: his thumbs must touch each other and the fingers must be
      spread. The burning hands send out flame jets of 5 ft. length in a horizontal arc of about 120 degrees in front of
      the wizard. The target suffers 1d3 points of damage, +2 points for each level of the caster, to a maximum of
      1d3+20 points of fire damage. A creature that successfully saves vs. Spell receives half damage.
    </span>
  ),
  causeLightWounds: (
    <span>
      Level: 1 <br />
      Sphere: Healing <br />
      Range: Touch <br />
      Duration: Instant <br />
      Casting Time: 5 <br />
      Area of Effect: 1 creature
      <br />
      Saving Throw: 1/2 <br />
      <br />
      <br />
      By casting this spell and touching a creature, the priest deals 8 points of damage (save vs. Spell for half) to
      the creature's body. This spell has no effect on undead, constructs, or extraplanar creatures. It cannot be cast
      by good-aligned characters.
    </span>
  ),
  charmPerson: (
    <span>
      Level: 1 <br />
      Range: Visual range of the caster <br />
      Duration: 5 rounds <br />
      Casting Time: 1 <br />
      Area of Effect: 1 person
      <br />
      Saving Throw: Neg. <br />
      <br />
      <br />
      This spell affects any single person it is cast upon. The term person includes any bipedal human, demihuman, or
      humanoid of man-size or smaller, such as brownies, dryads, dwarves, elves, gnolls, gnomes, goblins, half-elves,
      halflings, half-orcs, hobgoblins, humans, kobolds, lizard men, nixies, orcs, pixies, sprites, troglodytes, and
      others. Thus, a 10th-level fighter could be charmed, but an ogre could not. The person is allowed a Saving Throw
      vs. Spell to negate the effect. <br />
      If the spell's recipient fails his Saving Throw (with a +3 modifier), he regards the caster as a trusted friend
      and ally to be heeded and protected. The caster may give him orders, and the charmed individual will carry them
      out as quickly as possible. <br />
      If the caster harms—or attempts to harm—the charmed person by some overt action, or if a Dispel Magic spell is
      cast upon the charmed person, the Charm spell is broken. If two or more Charms simultaneously affect a creature,
      the most recent Charm takes precedence. A formerly charmed subject is fully aware of what occurred while he was
      under the spell's influence. Note that a charmed creature cannot leave the area where he is charmed. <br />
    </span>
  ),
  chillTouch: (
    <span>
      Level: 1 <br />
      Range: 0 <br />
      Duration: 1 turn <br />
      Casting Time: 1 <br />
      Area of Effect: The creature
      <br />
      Saving Throw: Neg. <br />
      <br />
      <br />
      When the caster completes this spell, a blue glow encompasses the caster's hand. This energy attacks the life
      force of any living creature upon which the wizard makes a successful melee attack. (Nonliving creatures such as
      golems and undead are unaffected by this spell.) The creature is punched for 1d2 fist damage (Strength bonuses
      apply) and must make a Saving Throw vs. Spell or suffer 1d8 points of damage and receive a -2 THAC0 penalty for 5
      rounds.
    </span>
  ),
  chromaticOrb: (
    <span>
      Level: 1 <br />
      Range: 90 ft <br />
      Duration: Special <br />
      Casting Time: 1 <br />
      Area of Effect: 1 creature
      <br />
      Saving Throw: Special <br />
      <br />
      <br />
      This spell causes a 2-ft. diameter sphere to appear in the caster's hand. When thrown, the sphere heads unerringly
      to its target. The effect the orb has upon the target varies with the level of the caster. Each orb will do damage
      to the target against which there is no save and an effect against which the target must save vs. Spell with a +6
      bonus: <br />
      1st Level: 1d4 damage and blinds the target for 1 round. <br />
      2nd Level: 1d4 damage and inflicts pain (-1 penalty to Strength, Dexterity, AC, and THAC0) upon the victim. <br />
      3rd Level: 1d6 damage and burns the victim for an additional 1d8 damage. <br />
      4th Level: 1d6 damage and blinds the target for 1 turn. <br />
      5th Level: 1d8 damage and stuns the target for 3 rounds. <br />
      6th Level: 1d8 damage and causes weakness (-4 penalty to THAC0) in the victim. <br />
      7th Level: 1d10 damage and paralyzes the victim for 2 turns. <br />
      10th Level: 1d12 acid damage and turns the victim to stone. <br />
      12th Level: 2d8 acid damage and instantly kills the victim. <br />
      NOTE: The victim saves vs. Spell with a +6 bonus against all the effects and gets no save against the damage.
      <br />
    </span>
  ),
  colorSpray: (
    <span>
      Level: 1 <br />
      Range: 50 ft <br />
      Duration: Instant <br />
      Casting Time: 1 <br />
      Area of Effect: 90-degree arc
      <br />
      Saving Throw: Special <br />
      <br />
      <br />
      Upon casting this spell, the wizard causes a vivid, fan-shaped spray of clashing colors to spring forth from the
      caster's hand. 1d6 creatures within the area are affected in order of increasing distance from the wizard. All
      creatures in the area of effect that have 4 Hit Dice or less must make a successful Saving Throw or be rendered
      unconscious for 5 rounds.
    </span>
  ),
  command: (
    <span>
      Level: 1 <br />
      Sphere: Charm <br />
      Range: 90 ft <br />
      Duration: 1 round <br />
      Casting Time: 1<br />
      Area of Effect: 1 creature
      <br />
      Saving Throw: Special <br />
      <br />
      <br />
      This spell enables the priest to command another creature to "die" (sleep) for a single round. At the end of the
      round, the creature awakens and is unharmed. Creatures with 6 or more Hit Dice (or experience levels) are entitled
      to a Saving Throw vs. Spell to ignore the command.
    </span>
  ),
  cureLightWounds: (
    <span>
      Level: 1 <br />
      Sphere: Healing <br />
      Range: Touch <br />
      Duration: Permanent <br />
      Casting Time: 5<br />
      Area of Effect: 1 creature
      <br />
      Saving Throw: None <br />
      <br />
      <br />
      By casting this spell and laying [their] hand upon a creature, the priest causes 8 points of wound or other injury
      damage to the creature's body to be healed. This healing cannot affect creatures without corporeal bodies, nor can
      it cure wounds of creatures not living or of extraplanar origin. <br /> Curing is permanent only insofar as the
      creature does not sustain further damage; caused wounds will heal—or can be cured—just as any normal injury.
    </span>
  ),
  curse: (
    <span>
      Level: 1 <br />
      Sphere: All <br />
      Range: Visual range of the caster <br />
      Duration: 6 rounds <br />
      Casting Time: 9<br />
      Area of Effect: 15-ft radius
      <br />
      Saving Throw: None <br />
      <br />
      <br />
      When uttering the Curse spell, the caster lowers the morale of enemy creatures and any Saving Throws and attack
      rolls they make by 1. At the instant the spell is completed, it affects all creatures in a 15-ft. radius centered
      on the point selected by the caster. Affected creatures leaving the area are still subject to the spell's effect;
      those entering the area after the casting is completed are not.
    </span>
  ),
  detectEvil: (
    <span>
      Level: 1 <br />
      Sphere: Divination <br />
      Range: 0 <br />
      Duration: Instant <br />
      Casting Time: 2<br />
      Area of Effect: 30-ft radius
      <br />
      Saving Throw: None <br />
      <br />
      <br />
      This spell discovers emanations of evil from any creature. Any evil creature within the range of the spell will
      glow red briefly.
    </span>
  ),
  doom: (
    <span>
      Level: 1 <br />
      Sphere: Charm <br />
      Range: 25 ft. <br />
      Duration: 1 turn <br />
      Casting Time: 9<br />
      Area of Effect: 1 creature
      <br />
      Saving Throw: None <br />
      <br />
      <br />
      This spell causes a feeling of doom to overwhelm the target. For the duration of the spell, the target receives a
      +2 penalty to all their rolls, including THAC0 and Saving Throws. There is no Saving Throw for this spell.
    </span>
  ),
  entangle: (
    <span>
      Level: 1 <br />
      Sphere: Plant <br />
      Range: Visual range of the caster <br />
      Duration: 1 turn <br />
      Casting Time: 15-ft radius
      <br />
      Saving Throw: Neg. <br />
      <br />
      <br />
      By means of this spell, the caster is able to cause plants in the area of effect to entangle creatures within the
      area. The grasses, weeds, bushes, and even trees wrap, twist, and entwine about the creatures, holding them fast.
      Once per round, a creature can attempt to save vs. Spell with a +3 bonus to avoid entanglement. An entangled
      creature can still attack, but receives a -2 penalty to its Armor Class.
    </span>
  ),
  findFamiliar: (
    <span>
      Level: 1 <br />
      Range: n/a <br />
      Duration: Special <br />
      Casting Time: 9 <br />
      Area of Effect: 1 familiar
      <br />
      Saving Throw: None <br />
      <br />
      <br />
      This spell enables the caster to attempt to summon a familiar to act as his aide and companion. Familiars are
      typically small creatures. A creature acting as a familiar can benefit a wizard, conveying its sensory powers to
      its master, conversing with him, and serving as a guard/scout/spy as well. A wizard can have only one familiar at
      a time, and he has no control over what sort of creature answers the summoning, if any at all come. <br />
      (Note: This spell may only be cast by the protagonist.) <br />
      The creature is always more intelligent than others of its type (typically by 2 or 3 Intelligence points), and its
      bond with the wizard confers upon it an exceptionally long life. The wizard has an empathic link with the familiar
      and can issue it mental commands. The caster receives half the familiar's total Hit Points (rounded down) as bonus
      Hit Points. However, the caster must take care to treat his familiar well, for if the familiar should die, the
      caster loses the bonus Hit Points and half the familiar's Hit Points again as damage. Also when a familiar dies,
      the caster loses 1 point of Constitution PERMANENTLY. <br />
      Example: A Mage has 12 Hit Points and casts Find Familiar. The imp summoned has 9 Hit Points, so the caster gets a
      bonus of 4 Hit Points. The caster now has 16 Hit Points. If the familiar dies, the caster loses those 4 Hit Points
      (putting him back at 12), loses 1 point of Constitution permanently (which may cause additional Hit Point loss),
      and takes 4 damage.
    </span>
  ),
  friends: (
    <span>
      Level: 1 <br />
      Range: 0 <br />
      Duration: 1d4 rounds + 1 round/level <br />
      Casting Time: 1 <br />
      Area of Effect: The caster
      <br />
      Saving Throw: None <br />
      <br />
      <br />A Friends spell causes the wizard to temporarily gain 6 points of Charisma. Those who view the caster tend
      to be very impressed with the spellcaster and make an effort to be his friend and help him, as appropriate to the
      situation. Officious bureaucrats might decide to become helpful; surly gate guards might wax informative;
      attacking orcs might spare the caster's life, taking him captive instead.
    </span>
  ),
  grease: (
    <span>
      Level: 1 <br />
      Range: 30 ft. <br />
      Duration: 3 rounds + 1 round/level <br />
      Casting Time: 1 <br />
      Area of Effect: 15-ft radius
      <br />
      Saving Throw: Special <br />
      <br />
      <br />A Grease spell covers a material surface with a slippery layer of a fatty, greasy nature. Any creature
      entering the area or caught in it when the spell is cast must save vs. Spell at +2 or slip and slide, unable to
      move effectively. Those who successfully save can move, albeit slowly, for the rest of the round (but will need to
      save vs. Spell again the following round). Those who remain in the area are allowed a Saving Throw each round
      until they escape the area.
    </span>
  ),
  identify: (
    <span>
      Level: 1 <br />
      Range: 0 <br />
      Duration: Instant <br />
      Casting Time: Special <br />
      Area of Effect: 1 item
      <br />
      Saving Throw: None <br />
      <br />
      <br />
      With this spell memorized, go to the description of an unidentified item and press the Identify button. The chance
      of identifying the item is 100%. The spell identifies the item's name, what it does, and if it is cursed.
    </span>
  ),
  infravision: (
    <span>
      Level: 1 <br />
      Range: 30 ft. <br />
      Duration: 2 hours <br />
      Casting Time: 1 <br />
      Area of Effect: 1 creature
      <br />
      Saving Throw: None <br />
      <br />
      <br />
      Upon the casting of this spell the recipient gains the ability to see with infravision, just as an elf or a dwarf
      would. This effect lasts for the duration of the spell or until dispelled.
    </span>
  ),
  larlochsMinorDrain: (
    <span>
      Level: 1 <br />
      Range: 30 ft. <br />
      Duration: Special <br />
      Casting Time: 1 <br />
      Area of Effect: 1 creature
      <br />
      Saving Throw: None <br />
      <br />
      <br />
      With this spell, the wizard drains the life force from a target and adds it to his own. The target creature
      suffers 4 damage, while the Mage gains 4 Hit Points. If the Mage goes over his maximum Hit Point total with this
      spell, he loses any extra Hit Points after 1 turn.
    </span>
  ),
  magicMissile: (
    <span>
      Level: 1 <br />
      Range: Visual range of the caster <br />
      Duration: Instant <br />
      Casting Time: 1 <br />
      Area of Effect: 1 creature
      <br />
      Saving Throw: None <br />
      <br />
      <br />
      Use of the Magic Missile spell, one of the most popular 1st-level spells, creates up to five missiles of magical
      energy that dart forth from the wizard's fingertip and unerringly strike their target, which must be a creature of
      some sort. Each missile inflicts 1d4+1 points of damage. for every 2 extra levels of experience, the wizard gains
      an additional missile––he has 2 at 3rd level, 3 at 5th level, 4 at 7th level, up to a total of 5 at 9th level.
    </span>
  ),
  magicalStone: (
    <span>
      Level: 1 <br />
      Sphere: Combat <br />
      Range: 50 ft. <br />
      Duration: Instant <br />
      Casting Time: 1 <br />
      Area of Effect: 1 creature
      <br />
      Saving Throw: None <br />
      <br />
      <br />
      By using this spell, the priest can create a small magical pebble, which then flies out and hits a target
      opponent. The stone deals 1d4 points of damage to whomever it hits. The stones are considered +1 weapons for
      determining if a creature can be struck (those struck only by magical weapons, for instance), although they do not
      have an attack or damage bonus.
    </span>
  ),

  removeFear: (
    <span>
      Level: 1 <br />
      Sphere: Charm <br />
      Range: 30 ft. <br />
      Duration: 1 hour <br />
      Casting Time: 1 <br />
      Area of Effect: 15-ft. radius
      <br />
      Saving Throw: None <br />
      <br />
      <br />
      The priest instills courage in the spell recipients, raising their morale to its highest. The recipients morale
      will gradually reset to normal as the duration runs out. If the recipients are affected by magical fear, this is
      negated.
    </span>
  ),
  reflectedImage: (
    <span>
      Level: 1 <br />
      Range: 0 <br />
      Duration: 3 rounds/level + 1 round/level <br />
      Casting Time: 1 <br />
      Area of Effect: The caster
      <br />
      Saving Throw: None <br />
      <br />
      <br />
      Upon casting this spell, the wizard creates 1 image of himself that will travel alongside him. The image will
      perform all of the actions that the wizard does so that if any enemies are trying to attack the wizard, they will
      not know which one is real. There is a 50% chance that the attacker will attack the image and a 50% chance that he
      will attack the caster. The image will disappear with a successful Dispel Magic, when attacked, or when the
      spell's duration expires.
    </span>
  ),
  sanctuary: (
    <span>
      Level: 1 <br />
      Sphere: Protection <br />
      Range: 0 <br />
      Duration: 1 turn <br />
      Casting Time: 4 <br />
      Area of Effect: The caster
      <br />
      Saving Throw: None <br />
      <br />
      <br />
      When the priest casts a Sanctuary spell, it causes all of [their] opponents to ignore [their] existence as if
      [they] were invisible. While protected by this spell, the subject cannot take direct offensive action without
      breaking the spell, but may use non-attack spells or otherwise act in any way that does not violate the
      prohibition against offensive action. This allows a warded priest to heal wounds, for example, or to Bless
      [themselves]. The priest may not cast spells on other creatures without ending the spell.
    </span>
  ),
  shield: (
    <span>
      Level: 1 <br />
      Range: 0 <br />
      Duration: 1 hour <br />
      Casting Time: 1 <br />
      Area of Effect: The caster
      <br />
      Saving Throw: None <br />
      <br />
      <br />
      When this spell is cast, an invisible barrier comes into being in front of the wizard. It sets the wizard's Armor
      Class to 4 against all melee weapons, and 2 against missile weapons. It also grants the wizard immunity from the
      spell Magic Missile. The effect lasts for the duration of the spell or until dispelled.
    </span>
  ),
  shillelagh: (
    <span>
      Level: 1 <br />
      Sphere: Combat, Plant Range: 0 <br />
      Duration: 4 rounds + 1 round/level
      <br />
      Casting Time: 2 <br />
      Area of Effect: Special
      <br />
      Saving Throw: None <br />
      <br />
      <br />
      'This spell enables the caster to create a magical cudgel that has a +1 bonus to its attack roll and inflicts 2d4
      points of damage on opponents.',
    </span>
  ),
  shockingGrasp: (
    <span>
      Level: 1 <br />
      Range: Touch <br />
      Duration: Special <br />
      Casting Time: 1 <br />
      Area of Effect: 1 creature
      <br />
      Saving Throw: None <br />
      <br />
      <br />
      When the wizard touches a creature while this spell is in effect, an electrical charge will deal 1d8 points of
      damage plus 1 per level of the caster to the creature touched. In addition, the grasp itself does 1d2 fist damage
      (Strength bonuses apply). The wizard only has one charge, and once an opponent has been touched, the spell's
      energies have been used. If the wizard misses, the spell is wasted. The wizard has 1 round per level to touch the
      target creature.
    </span>
  ),
  sleep: (
    <span>
      Level: 1 <br />
      Range: 60 ft. <br />
      Duration: 5 rounds/level <br />
      Casting Time: 1 <br />
      Area of Effect: 15-ft. radius
      <br />
      Saving Throw: Neg. <br />
      <br />
      <br />
      When a wizard casts a Sleep spell, he causes a comatose slumber to come upon one or more enemies (other than
      undead and certain other creatures specifically excluded from the spell's effects). All creatures to be affected
      by the Sleep spell must be within a 30-ft. radius. Creatures in the area of effect must make a Saving Throw vs.
      Death with a -3 penalty or fall asleep. Monsters with 5 Hit Dice or more are unaffected. Attacks against sleeping
      opponents never miss.
    </span>
  ),
  protectionFromEvilCleric: (
    <span>
      Level: 1 <br />
      Sphere: Protection <br />
      Range: Touch <br />
      Duration: 2 rounds/level <br />
      Casting Time: 1 <br />
      Area of Effect: 1 creature
      <br />
      Saving Throw: None <br />
      <br />
      <br />
      When this spell is cast, it creates a magical barrier around the recipient at a distance of one foot. The barrier
      moves with the recipient and has two major effects: first, all attacks made by evil creatures against the
      protected creature receive a -2 penalty to their attack roll, and second, any saving throws caused by such attacks
      are made by the protected creature with a +2 bonus.
    </span>
  ),
  protectionFromEvil: (
    <span>
      Level: 1 <br />
      Range: Touch <br />
      Duration: 2 rounds/level <br />
      Casting Time: 1 <br />
      Area of Effect: 1 creature
      <br />
      Saving Throw: None <br />
      <br />
      <br />
      When this spell is cast, it creates a magical barrier around the recipient at a distance of one foot. The barrier
      moves with the recipient and has two major effects: first, all attacks made by evil creatures against the
      protected creature receive a -2 penalty to their attack roll, and second, any saving throws caused by such attacks
      are made by the protected creature with a +2 bonus.
    </span>
  ),
  protectionFromPetrification: (
    <span>
      Level: 1 <br />
      Range: Touch <br />
      Duration: 1 hour <br />
      Casting Time: 1 <br />
      Area of Effect: 1 creature <br />
      Saving Throw: None <br />
      <br />
      <br />
      This spell grants the recipient immunity to all petrification attacks. This includes basilisk and medusa gaze,
      cursed scrolls of petrification, and so on.
    </span>
  ),
  nahalsRecklessDweomer: (
    <span>
      Level: 1 <br />
      Range: Special <br />
      Duration: Special <br />
      Casting Time: Special <br />
      Area of Effect: Special <br />
      Saving Throw: Special <br />
      <br />
      <br />
      This spell is the Wild Mage's ultimate last-resort spell. When cast, the Mage releases a sudden flood of wild
      magical energy in the hope of seizing and shaping that energy into a desired spell effect. The attempt usually
      fails, but something almost always occurs in the process. <br /> To use Nahal's Reckless Dweomer, simply cast it
      and then choose from your list of known spells. A burst of magical energy is released, which the Wild Mage will
      try to manipulate into the desired form. The actual effect of the spell is determined randomly by a dice roll on
      the Wild Surge Table. <br /> Because the release of energy is planned by the Mage, his level is added to the dice
      roll. If the result indicates success, the Mage has shaped the magical energy into the desired effect. More often
      than not, the effect is completely unexpected. The result might be beneficial to the Mage, or it might be
      completely disastrous: This is the risk the Mage takes in casting Nahal's Reckless Dweomer.
    </span>
  ),
  spook: (
    <span>
      Level: 1 <br />
      Range: 30 ft. <br />
      Duration: 3 rounds <br />
      Casting Time: 1 <br />
      Area of Effect: 1 creature <br />
      Saving Throw: Neg. <br />
      <br />
      <br />A Spook spell enables the wizard to play upon natural fears to cause the target creature to perceive the
      caster as someone or something inimical, which then appears to advance upon it in a threatening manner. If the
      creature does not make a successful Saving Throw vs. Spell, it turns and flees at maximum speed as far from the
      wizard as possible. The creature suffers a Saving Throw penalty of -1 every 2 levels of the caster, up to a
      maximum of -6 at 12th level. Although the caster does not actually pursue the fleeing creature, a phantasm from
      its own mind does. Undead are completely unaffected.
    </span>
  ),
  sunscorch: (
    <span>
      Level: 1 <br />
      Sphere: Sun <br />
      Range: Visual range of the caster <br />
      Duration: Instant <br />
      Casting Time: 4 <br />
      Area of Effect: 1 creature <br />
      Saving Throw: Special <br />
      <br />
      <br />
      "When this spell is cast, a brilliant ray of scorching heat slants down from the sky and strikes one target of the
      caster's choice. Any creature struck by the ray takes 1d6 points of damage, plus 1 point per level of the caster;
      however, if the target is an undead creature or a monster vulnerable to bright light, the damage is 1d6, plus 2
      points per level of the caster. In addition to sustaining damage, victims are also blinded for 3 rounds. The
      victim is allowed a Save vs. Spell for half damage and to avoid the blindness.",
    </span>
  ),
};

export const RACE_DESCRIPTIONS: Record<string, string | React.ReactNode> = {
  HUMAN: (
    <span>
      Humans are the predominant race in Faerûn. Humans rule most of the significant empires and kingdoms in the
      Forgotten Realms. They are the most social and tolerant of races, excepting perhaps the halflings. The only
      special ability that human possess is that they may advance as any class. Humans are also the only race that can
      dual-class. Humans may not multi-class. Humans have the following traits: <br />
      <br />
      <ul>
        <li>+10% Open Locks</li>
        <li>+5% Find Traps</li>
        <li>+15% Pick Pockets</li>
        <li>+10% Move Silently</li>
        <li>+5% Hide in Shadows</li>
      </ul>
    </span>
  ),
  ELF: (
    <span>
      Elves tend to be shorter and slimmer than humans. Their features are finely chiseled and delicate, and they speak
      in melodic tones. Elves are looked upon as being frivolous and aloof. They concern themselves with natural beauty,
      dancing, frolicking, and other similar pursuits. Their humor is clever, as are their songs and poetry. There are
      six racial divisions of elves within the Realms: gold elves, moon elves, wild elves, sea elves, dark elves (drow),
      and winged elves (avariel). Elves have the following traits: <br />
      <br />
      <ul>
        <li>90% resistance against charm and sleep magics.</li>
        <li>Infravision</li>
        <li>+1 THAC0 bonus with bows, short swords, and long swords.</li>
        <li>+5% Open Locks, +5% Find Traps, +20% Pick Pockets, +15% Move Silently, +15% Hide in Shadows.</li>
        <li>+1 Dexterity, -1 Constitution.</li>
      </ul>
    </span>
  ),
  DWARF: (
    <span>
      Dwarves are short and stocky, easily identifiable by their size and shape. They have ruddy cheeks, dark eyes, and
      dark hair. Dwarves tend to be dour and taciturn. They are given to hard work, and care little for most humor. They
      enjoy beer, ale, mead, but most of all, they love gold. There are four racial divisions of dwarves within the
      Realms: shield dwarves, gold dwarves, wild dwarves, and duergar. Dwarves have the following traits: <br />
      <br />
      <ul>
        <li>
          +2 bonus to Saving Throws vs. Paralysis/Poison/Death, vs. Rod/Staff/Wand, and vs. Spell, with additional
          bonuses based on Constitution.
        </li>
        <li>Infravision</li>
        <li>
          +20% Open Locks, +20% Find Traps, +15% Pick Pockets, +10% Move Silently, +5% Hide in Shadows, +5% Detect
          Illusion, +10% Set Traps.
        </li>
        <li>+1 Constitution, -1 Dexterity, -2 Charisma.</li>
      </ul>
    </span>
  ),
  HALF_ELF: (
    <span>
      Half-elves are a mix of human and elven blood. They are handsome folk, with good features from each of their
      parent races. A half-elf has the curiosity, inventiveness, and ambition of their human ancestors and the refined
      senses, love of nature, and artistic tastes of their elven ancestors. Half-elves have the following traits:
      <br />
      <br />
      <ul>
        <li>30% resistance against charm and sleep magics.</li>
        <li>Infravision</li>
        <li>+10% Pick Pockets, +5% Hide in Shadows.</li>
      </ul>
    </span>
  ),
  GNOME: (
    <span>
      Kin to dwarves, gnomes are noticeably smaller than their distant cousins. Gnomes, as they proudly maintain, are
      also less rotund than dwarves. Most have dark tan or brown skin, white hair, and rather large noses. Gnomes have
      lively and sly senses of humor, especially for practical jokes. They have a love for nature that is only matched
      by their love for gems and jewelry. There are two racial divisions of gnomes within the Realms: rock gnomes and
      deep gnomes (svirfneblin). Gnomes have the following traits: <br />
      <br />
      <ul>
        <li>
          +2 bonus to Saving Throws vs. Rod/Staff/Wand and vs. Spell, with additional bonuses based on Constitution.
        </li>
        <li>Infravision</li>
        <li>
          +15% Open Locks, +15% Find Traps, +15% Pick Pockets, +15% Move Silently, +10% Hide in Shadows, +10% Detect
          Illusion, +5% Set Traps.
        </li>
        <li>+1 Intelligence, -1 Wisdom.</li>
      </ul>
    </span>
  ),
  HALFLING: (
    <span>
      Halflings are short, generally plump people, very much like small humans. Their faces are round and broad, and
      often quite florid. Their hair is typically curly, and the tops of their feet are covered with coarse hair.
      Overall they prefer the comforts of home to dangerous adventuring. They enjoy good living, rough humor, and
      homespun tales. There are three racial divisions of halflings within the Realms: the hairfoot, tallfellow, and
      stout. Halflings have the following traits: <br />
      <br />
      <ul>
        <li>
          +2 bonus to Saving Throws vs. Paralysis/Poison/Death, vs. Rod/Staff/Wand, and vs. Spell, with additional
          bonuses based on Constitution.
        </li>
        <li>+1 THAC0 bonus with slings.</li>
        <li>+5% Open Locks, +5% Find Traps, +5% Pick Pockets, +10% Move Silently, +15% Hide in Shadows.</li>
        <li>+1 Dexterity, -1 Strength, -1 Wisdom.</li>
      </ul>
    </span>
  ),
  HALF_ORC: (
    <span>
      Half-orcs are born from the union of human and orc parents. They are as tall as humans, but a little heavier due
      to their muscular builds. Their greenish pigmentation, sloping forehead, jutting jaw, prominent teeth, and coarse
      body hair make their lineage plain for all to see. In the Sword Coast, half-orcs are tolerated, as unlike in the
      north the local people haven't had centuries of warfare with orc kind. Half-orcs are known for their great
      strength. Half-orcs have the following traits: <br />
      <br />
      <ul>
        <li>+1 Strength, +1 Constitution, -2 Intelligence.</li>
      </ul>
    </span>
  ),
};

export const CLASS_DESCRIPTIONS: Record<string, string | React.ReactNode> = {
  FIGHTER: (
    <span>
      The Fighter is a champion, swordsman, swordswoman, soldier, and brawler who lives or dies by the knowledge of
      weapons and tactics. Fighters can be found at the front of any battle, contesting toe-to-toe with monsters and
      villains. A good Fighter needs to be strong and healthy if they hope to survive. <br />
      Class features: <br />
      May wear helmets <br />
      May wear any armor and use any weapon <br />
      May achieve Grand Mastery (five slots) with any weapon class <br />
      May achieve Specialization (two slots) in any fighting style and allocate three slots in Two-Weapon Style <br />
      Hit Die: d10 <br />
      Prime Requisites for Dual-Classing: Strength
    </span>
  ),
  BERSERKER: (
    <span>
      This is a warrior who is in tune with animalistic side and, during combat, can achieve an ecstatic state of mind
      that will enable one to fight longer, harder, and more savagely than any person has a right to. Berserkers tend to
      be barbarian-like in nature, but not always. Sometimes it is a conscious choice that a warrior in training makes.
      Regardless, opponents on the battlefield will be unsettled when they see the savage and inhuman elements of the
      Berserker's personality. This class is common amongst dwarves, known to them as the Battlerager.
      <br />
      Advantages:
      <br />
      May use Enrage ability once per day. Gains one use at level 1 and an additional use every 4 levels thereafter. The
      enraged state lasts for 1 turn. While enraged, a Berserker gains a bonus of -2 bonus to THAC0 and +2 to damage
      rolls as well as a -2 bonus to Armor Class, and becomes immune to charm, confusion, fear, feeblemind, hold,
      imprisonment, Level drain, maze, stun, and sleep. The Berserker also gains 15 temporary Hit Points which are taken
      away at the end of berserk spree, possibly knocking unconscious
      <br />
      Disadvantages:
      <br />
      Becomes winded after berserking: +2 penalty to Armor Class, THAC0, and -2 penalty to damage rolls. This lasts for
      5 rounds and Enrage cannot be used while winded. <br />
      May not Specialize in ranged weapons <br />
      Alignment restricted to any non-lawful <br />
    </span>
  ),
  WIZARD_SLAYER: (
    <span>
      This warrior has been specially trained by one's sect to excel in hunting and combating spellcasters of all kinds.
      <br />
      Advantages:
      <br />
      Each successful hit bestows a 25% cumulative chance of arcane spell failure on the target for 5 rounds (bypass MR,
      no save), new hits refresh the duration +1% Magic Resistance per level through level 19. Then starting at level
      20, they gain +5% every even level and +1% every odd level, up to 84% MR max at level 40 <br />
      Disadvantages:
      <br />
      May not use any magic items apart from weapons, helmets, body armor, boots, shields, healing potions and antidotes
    </span>
  ),
  KENSAI: (
    <span>
      This class's name, which means "sword saint" in the common tongue, refers to a warrior who has been specially
      trained to be one with his weapon(s). The kensai is deadly, fast, and trained to fight without the protection of
      armor. Despite its common name, a kensai may use any melee weapon.
      <br />
      Advantages: <br />
      -2 bonus to Armor Class <br />
      -1 bonus to THAC0 and +1 bonus to damage rolls every 3 levels <br />
      -1 bonus to Speed Factor every 4 levels, to a maximum of -10 bonus at level 36 <br />
      Has access to the Kai ability. Starts with one use per day at level 1 and gains an additional use every 4 levels
      thereafter. All successful attacks within the next 10 seconds deal maximum damage. <br />
      Disadvantages: <br />
      May not wear any body armor, helmets, shields or bracers <br />
      May not use missile weapons other than throwing weapons with a melee component (dagger, axe, hammer). <br />
      Alignment restricted to any non-chaotic <br />
    </span>
  ),
  BARBARIAN: (
    <span>
      A Barbarian can be an excellent warrior. While not as disciplined or as skilled as Fighters, the Barbarians can
      willingly throw themselves into a berserker rage, becoming tougher and stronger opponents.
      <br />
      Class Features:
      <br />
      May not wear armor heavier than splint mail <br />
      May not exceed Specialization (two slots) with any weapon class <br />
      May achieve Specialization (two slots) in any fighting style and allocate three slots in Two-Weapon Style
      <br />
      Moves 2 points faster than other non-monk characters
      <br />
      Immune to backstab
      <br />
      May use the Barbarian Rage ability once per day. Gains one use at level 1 and an additional use every 4 levels
      thereafter. The enraged status lasts for 5 rounds with a +2 penalty to Armor Class, but provides a +4 bonus to
      Strength and Constitution, and a -2 bonus to saving throws vs. Spell, as well as immunity to all charm, hold,
      fear, maze, stun (except mind flayer's Psionic Blast in classic versions [Confirmation Needed]), sleep, confusion,
      and Level drain spells <br />
      11th level: Gains +10% resistance to crushing, slashing, piercing, and missile damage. An additional +5% is gained
      at levels 15 and 19
      <br />
      Hit Die: d12
      <br />
      May not dual-class
      <br />
    </span>
  ),
  DWARVEN_DEFENDER: (
    <span>
      The Dwarven Defender is a formidable warrior that is reputed to be worth two soldiers of any other race. Trained
      extensively in the art of dwarven warfare, a handful of these stout fighters can render a defensive line all but
      unbreakable.
      <br />
      Advantages: <br />
      May use Defensive Stance once per day. Gains one use at level 1, one use at level 4, and an additional use every 4
      levels thereafter. For 1 turn, the Dwarven Defender gains +50% resistance to all forms of physical damage, a -2
      bonus to saving throws, and a 50% movement rate penalty. Does not stack with Hardiness high-level ability. Gains
      +5% resistance to crushing, slashing, piercing, and missile damage every 5 levels to a maximum of 20% at level 20{' '}
      <br />
      Hit Die: d12 <br />
      Disadvantages: <br />
      Race restricted to dwarf. May not dual-class due to race restriction <br />
      May not exceed High Mastery (four slots) in axes and war hammers <br />
      May not exceed Specialization (two slots) in any other weapon <br />
    </span>
  ),
  RANGER: (
    <span>
      The ranger is a warrior and a woodsman who is skilled with weapons and is knowledgeable in the ways of the forest.
      The Ranger often protects and guides lost travelers and honest peasant-folk. A Ranger needs to be strong and wise
      to the ways of nature to live a full life.
      <br />
      Class features:
      <br />
      <br />
      May wear helmets
      <br />
      May wear any armor and use any weapon
      <br />
      May not exceed Specialization (two slots) in any weapon class
      <br />
      May achieve Specialized (two slots) in any fighting style
      <br />
      Begins Specialized (two slots) in Two-Weapon Style and may place a third slot into it
      <br />
      May select a racial enemy, which grants a -4 THAC0 bonus to and +4 damage rolls bonus against the selected enemy
      race
      <br />
      May use the Charm Animal ability once per day. Gains one use at level 1 and an additional use every 2 levels
      thereafter
      <br />
      May use Stealth ability
      <br />
      May cast druidic spells starting at level 8, up to spell level 3<br />
      Alignment restricted to good
      <br />
      Hit Die: d10
      <br />
      Prime Requisites For Dual-Classing: Strength, Dexterity, Wisdom
      <br />
    </span>
  ),
  ARCHER: (
    <span>
      The Archer is the epitome of skill with the bow - the ultimate marksman, able to make almost any shot, no matter
      how difficult. To become so skilled with the bow, the Archer has had to sacrifice some of the proficiency with
      melee weapons and armor.
      <br />
      Advantages: <br />
      -1 bonus to hit and +1 bonus to damage with any missile weapon every 3 levels, including thrown arsenals <br />
      May achieve Grand Mastery (five slots) in longbows, shortbows, and crossbows, may Specialize (two slots) in slings
      and darts <br />
      Called Shot: Gains one use at level 4 and an additional use every 4 levels thereafter. All successful ranged
      attacks made by the Archer within the next 10 seconds acquire the following effects besides normal damage: <br />
      Effect available from the start: +1 penalty to target's THAC0 for 20 seconds <br />
      Effect unlocked at level 8: +1 penalty to target's save vs Spell for 15 seconds <br />
      Effect unlocked at level 12: -1 penalty to target's Strength for 10 seconds <br />
      Effect unlocked at level 16: +2 Piercing damage <br />
      There is no save against these effects and they ignore magic resistance. They are also cumulative, i. e., hitting
      an enemy several times while this ability is active applies its effects multiple times. <br />
      Disadvantages: <br />
      May not wear any metallic armor (Classic)/May only wear leather, studded leather, and hide armor <br />
      May only put one dot in melee weapon proficiencies, one dot in Two-Handed Weapon Style, Sword and Shield Style,
      Single-Weapon Style <br />
      May not use the Charm Animal ability <br />
    </span>
  ),
  STALKER: (
    <span>
      Stalkers serve as covert intelligence-gatherers, comfortable in both wilderness and urban settings. They are
      spies, informants, and interrogators, and their mastery of stealth makes them deadly opponents.
      <br />
      Advantages: <br />
      <br />
      +20 to Move Silently and Hide In Shadows <br />
      May use Backstab ability, although for a lower damage multiplier than thieves:
      <br />
      Level 1-8: x2
      <br />
      Level 9-16: x3
      <br />
      Level 17+: x4
      <br />
      Melee weapon restrictions still apply for their backstabbing: long swords, short swords, katanas,
      scimitar/wakizashi/ninjato, daggers, clubs, or quarterstaffs. Dual-classed clerics may only use clubs or
      quarterstaffs to backstab. <br />
      12th level: May memorize 3 Mage spells: Haste, Protection From Normal Missiles, and Minor Spell Deflection, which
      are selectable from the Stalker's level 3 spellbook <br />
      Disadvantages: <br />
      May only wear leather, studded leather, and hide armor.
      <br />
    </span>
  ),
  BEAST_MASTER: (
    <span>
      Beast Masters are wanderers and are not comfortable in civilized lands. Therefore, they maintain a natural
      affinity for animals; who are the Beast Masters' friends and comrades-in-arms, and the Beast Masters have a
      limited form of telepathic communication with the animals.
      <br />
      Advantages: <br />
      +15 to Move Silently and Hide In Shadows. <br />
      May use Find Familiar as a special ability to summon a pseudo dragon (if lawful or neutral good) or fairy dragon
      (if chaotic good) companion <br />
      8th level: May cast Animal Summoning I (level 4 spell treated as a level 1 spell) <br />
      10th level: May cast Animal Summoning II (level 5 spell treated as a level 2 spell) <br />
      12th level: May cast Animal Summoning III (level 6 spell treated as a level 3 spell) <br />
      Disadvantages: <br />
      May not use any metal weapons (for example: swords, halberds, war hammers, or morning stars) <br />
      May only put proficiency points in clubs, quarterstaffs, crossbows, longbows, shortbows, darts and slings <br />
      May only wear leather, studded leather, and hide armor.
      <br />
    </span>
  ),
  PALADIN: (
    <span>
      A Paladin is a warrior bold and pure, the exemplar of everything good and true. Like the Fighter, the Paladin is a
      person of action and combat. However, the Paladins live for the ideals of righteousness, justice, honesty, piety,
      and chivalry and strive to be a living example of these virtues so that others may learn from them as well as gain
      by their actions
      <br />
      Class features: <br />
      May wear helmets <br />
      May wear any armor and use any weapon <br />
      May not exceed Specialization (two slots) in any fighting style and allocate three slots in Two-Weapon Style{' '}
      <br />
      May use Lay On Hands ability once per day to heal a target for 2 Hit Points per level of the Paladin <br />
      May cast Detect Evil once per day per level (starts at 1st level with 4 uses) <br />
      May cast Protection From Evil once per day per level (starts at 1st level with one use) <br />
      May Turn Undead as a Cleric two levels lower, starting at level 3 <br />
      May cast cleric spells starting at level 9 <br />
      Receives a -2 bonus to all Saving Throws <br />
      Alignment restricted to lawful good <br />
      Hit Dice: d10 <br />
    </span>
  ),
  CAVALIER: (
    <span>
      This class represents the most common picture of the knight: the chivalrous warrior who epitomizes honor, courage,
      and loyalty. The cavalier is specialized in battling "classical" evil monsters such as demons and dragons.
      <br />
      Advantages:
      <br />
      -3 bonus to THAC0 and +3 to damage against the demon and dragon races <br />
      May cast Remove Fear as an innate ability once every day for every level <br />
      Immune to charm, poison, fear and morale failure <br />
      20% resistance to fire and acid <br />
      Disadvantages: <br />
      May not use missile weapon except throwing axes, throwing daggers
      <br />
    </span>
  ),
  INQUISITOR: (
    <span>
      The Inquisitors have dedicated their life to finding and eliminating practitioners of evil magic and defeating the
      forces of darkness, and their god has provided them with special abilities toward that end..
      <br />
      Advantages: <br />
      May use the spell Dispel Magic as an innate ability. Gains one use at level 1 and an additional use every 4 levels
      thereafter. <br />
      May use the spell True Sight as an innate ability. Gains one use at level 1 and an additional use every 4 levels
      thereafter <br />
      Immune to Hold creature, Paralyze and Charm Creature <br />
      Disadvantages: <br />
      May not Turn Undead <br />
      May not Lay On Hands <br />
      May not cast cleric spells, but may still learn Summon Deva HLA as an innate ability <br />
    </span>
  ),
  UNDEAD_HUNTER: (
    <span>
      These holy avengers have honed their abilities toward the destruction of the undead and other unnatural creatures
      and is immune to many of the creatures' most devastating abilities
      <br />
      Advantages:
      <br />
      -3 bonus to THAC0 and +3 to damage against undead creatures <br />
      Immune to Level drain, Paralyze, Hold <br />
      Disadvantages: <br />
      May not Lay On Hands
      <br />
    </span>
  ),
  BLACKGUARD: (
    <span>
      The Blackguard epitomizes evil and is nothing short of a mortal fiend. The quintessential black knight, this
      villain carries a reputation of the foulest sort that is very well deserved. Consorting with demons and devils and
      serving dark deities, the Blackguard is hated and feared by all. Some people call these villains "anti-paladins"
      due to their completely evil nature.
      <br />
      Advantages:
      <br />
      Immune to fear and Level drain
      <br />
      May turn undead as a Paladin of the same level
      <br />
      May use the Absorb Health ability once per day. Deals 2 points of damage per level to an enemy, healing the
      Blackguard the same number of Hit Points
      <br />
      May use the Poison Weapon ability once per day. Gains one use at level 1, one use at level 5, and an additional
      use every 5 levels thereafter
      <br />
      3rd level: May use the Aura of Despair ability once per day. When this ability is activated, all enemies within 30
      feet of the Blackguard suffer ill effects based on the Blackguard's level:
      <br />
      3rd - All enemies suffer a +1 penalty to THAC0 and -1 to damage rolls and a +2 penalty to Armor Class for 1 turn
      <br />
      6th - All enemies suffer a +2 penalty to THAC0 and -2 to damage rolls and a +2 penalty to Armor Class for 1 turn
      <br />
      15th - All enemies suffer a +4 penalty to THAC0 and -4 to damage rolls and a +4 penalty to Armor Class for 1 turn;
      enemies with 8 or fewer Hit Dice are panicked as well
      <br />
      20th - All enemies suffer a +4 penalty to THAC0 and -4 to damage rolls and a +4 penalty to Armor Class for 1 turn;
      enemies with 18 or fewer Hit Dice are panicked as well
      <br />
    </span>
  ),
  CLERIC: (
    <span>
      The Cleric is a generic priest (of any mythos) who tends to the spiritual needs of a community. Though Clerics are
      both protectors and healers, they are not purely defensive - when evil threatens, the Clerics are well suited to
      seek it out on its own ground and destroy it. The opposite is true of evil Clerics as well, and there are also
      many variations in between.
      <br />
      Class features:
      <br />
      May wear helmets
      <br />
      May wear any armor
      <br />
      May only use non-bladed, non-piercing weapons (war hammer, club, flail, mace, quarterstaff, sling)
      <br />
      May only become Proficient (one slot) in any weapon class
      <br />
      May only become Proficient (one slot) in any fighting style
      <br />
      May Turn Undead
      <br />
      May cast priest spells
      <br />
      Hit Die: d8
      <br />
      Prime Requisites For Dual-Classing: Wisdom
      <br />
    </span>
  ),
  PRIEST_HELM: (
    <span>
      Followers of Helm, the neutral god of watchers and protectors are warriors in their own right and are often seen
      as defenders of the innocent.
      <br />
      Advantages
      <br />
      May innately cast True Sight (innate). Gains one use at level 1 and an additional use every 5 levels thereafter.
      The casting speed of this ability is noticeably quicker than the Arcane or Priest spell versions. Otherwise, it
      isn't much different than the True Sight spell.
      <br />
      May innately cast Seeking Sword. Gains one use at level 1 and an additional use every 10 levels thereafter. This
      spell creates a sword in the Cleric's hand that cannot be dropped or unequipped. The sword is enchanted as a +4
      weapon and provides a -4 THAC0 bonus, but no damage bonus. It deals 2d4 damage to any target it hits. The weapon
      sets the Cleric's number of attacks per round to 3 and lasts for 1 round per level. While it is equipped, the
      wielder cannot cast further spells.
    </span>
  ),
  PRIEST_LATHANDER: (
    <span>
      Lathander is the good god of renewal, creativity, and youth, and is celebrated nearly everywhere. His followers
      are very popular throughout the realms, and there are numerous wealthy temples devoted to him.
      <br />
      Advantages <br />
      May innately cast Hold Undead once per day. Gains one use at level 1 and an additional use every 5 levels
      thereafter. The only difference from the Cleric spell version and the Lathander Kit version are the classification
      as an Innate Spell. In terms of gameplay, the innate ability can avoid Miscast Magic or Silence effects.
      <br />
      May innately cast Boon of Lathander once per day. Gains one use at level 1 and an additional use every 10 levels
      thereafter. This spell lasts 1 round per level of the caster. It gives the caster a -1 bonus to THAC0 and +1 bonus
      to damage roll, a -1 bonus to all saving throws, and 1 extra attacks per round. It also protects the recipient
      from Level drain. The effects are cumulative.
    </span>
  ),
  PRIEST_TALOS: (
    <span>
      Talos is the evil god of storms, destruction, and rebellion. Clerics of the Stormlord warn that Talos must be
      appeased, or he will rain destruction upon the land.
      <br />
      Advantages <br />
      May cast Lightning Bolt once per day. Gains one use at level 1 and an additional use every 5 levels thereafter.{' '}
      <br />
      May cast Storm Shield once per day. Gains one use at level 1 and an additional use every 10 levels thereafter. At
      level 1, this ability lasts 1 turn. From level 10 onwards, it lasts 1 round per level of the caster. It protects
      the caster from lightning, fire, cold, and normal missiles.
    </span>
  ),
  PRIEST_TEMPUS: (
    <span>
      Tempus, also known as the Lord of Battles or the Foehammer, is random in his favors, yet his chaotic nature favors
      all sides equally. Lord Tempus may be on an army's side one day and against them the next; such is the nature of
      war. Tempuran clergy can be found on both sides of a conflict, as none can ever truly know whom the war god will
      favor.
      <br />
      Advantages <br />
      May cast Holy Power once per day. Gains one use at level 1 and an additional use every 5 levels thereafter. <br />
      May cast Chaos of Battle once per day. Chaos of Battle applies bonuses to all allies and penalties to all enemies
      within a 30-ft. radius of the caster. The spell lasts 1 turn and will randomly affect the target's Armor Class,
      Hit Points, THAC0, saves, or luck. The magnitude of the effect starts at 1 (5 for Hit Points) at level 1 and will
      improve by 1 (5 for Hit Points) every 6 levels of the caster.
    </span>
  ),
  PRIEST_TYR: (
    <span>
      Tyr is the deity of law and justice and is primarily concerned with the punishment of wrongdoers and the general
      furthering of law and good in the world. The belief in justice through (benevolent) force, or at least armed
      vigilance, is the reason for Tyr's existence. Tyr holds great prominence in the pantheon due to his position as
      leader of The Triad, a trio of lawful good gods that are collectively devoted to the concepts of courage, justice,
      perseverance, relief of suffering, duty, obedience, honor, and to some extent, righteous martyrdom. <br />
      Advantages
      <br />
      May cast Exaltation once per day every 5 levels of the caster (starts at 1st level with one use). This spell
      enables a priest to aid and protect any one being other than themselves. By touch, the caster removes the effects
      of fear, sleep, Feeblemindedness, unconsciousness, and Intoxication, as well as berserk and confused states of
      mind. In addition, the recipient is protected against spells and other attacks that cause these effects for 1
      turn.
      <br />
      May cast Divine Favor once per day every 10 levels of the caster (starts at 1st level with one use). This spell
      grants the caster a +1 bonus to THAC0 and +1 bonus to damage rolls every 3 levels of the caster. The spell lasts
      for 2 rounds.
    </span>
  ),
  BARD: (
    <span>
      The Bard is a rogue, but is very different from the Thief. The Bards' strength is their pleasant and charming
      personality. With it and their wits, they make their way through the world. Bards are talented musicians and
      walking storehouses of gossip, tall tales, and lore, they learn a little bit about everything that crosses their
      paths and are jack-of -all-trades but master of none. While many Bards are scoundrels, their stories and songs are
      welcome almost everywhere.
      <br />
      Class features:
      <br />
      May not wear armor heavier than chain mail (Spells cannot be cast while wearing armor)
      <br />
      May not equip shields larger than bucklers
      <br />
      May only become Proficient (one slot) in any weapon class
      <br />
      May only become Proficient (one slot) in any fighting style
      <br />
      Thieving abilities: Pick Pockets
      <br />
      Increased Lore score
      <br />
      May cast wizard spells starting at 2nd level
      <br />
      May use Bard Song ability. While active, the Bard Song has the following effects: Restore morale to its average
      value, remove fear, protection from fear. Up to +3 Luck bonus in EE version 2.0+
      <br />
      Alignment restricted to any neutral
      <br />
      Hit Die: d6
    </span>
  ),
  BLADE: (
    <span>
      The Blades are the expert fighters and adventurers whose bardic acting abilities make them appear more
      intimidating and fearsome. This Bard's fighting style is flashy and entertaining, but is also lethally dangerous.
      <br />
      Advantages:
      <br />
      May place 3 slots in Two-Weapon Style
      <br />
      May use the Offensive Spin and Defensive Spin abilities once per day. Gains one use each at level 1 and an
      additional use each every 4 levels thereafter.
      <br />
      Disadvantages:
      <br />
      Only has one half the normal Lore value
      <br />
      Only has one half the normal Pick Pockets skill, the dex modifier to this skill is also halved. Blades will reach
      up to 57 in pickpocketing at lv16, and with 25 dex, they'll have the combined score of 80
      <br />
      Bard Song does not become better with levels
    </span>
  ),
  JESTER: (
    <span>
      Jesters are well versed in the arts of ridicule and hilarity, and use their abilities to distract and confuse
      their enemies, cavorting madly during combat. Do not mistake them for true fools, however, as they can also be
      quite deadly.
      <br />
      Advantages:
      <br />
      The Jester's song does not help allies, it causes confusion, slow and sleep on enemies when they fail their saves.
    </span>
  ),
  SKALD: (
    <span>
      Skalds are Nordic bards who are also warriors of great strength, skill, and virtue; their songs are inspiring
      sagas of battle and valor, and the Skalds devote their lives to those pursuits.
      <br />
      Advantages: <br />
      -1 bonus to THAC0 and +1 to damage rolls with all weapons <br />
      The Skald's song is different from the typical Bard's and varies with level: <br />
      1st level: Grants allies a -2 bonus to THAC0, +2 bonus to damage rolls, and a -2 bonus to AC <br />
      15th level: Grants allies a -4 bonus to THAC0, +4 bonus to damage rolls, -4 bonus to AC and immunity to fear
      <br />
      20th level: Grants allies a -4 bonus to THAC0, +4 bonus to damage rolls, -4 bonus to AC, immunity to fear, stun,
      and confusion <br />
      Disadvantage:
      <br />
      Only has one quarter the normal Pick Pockets score, and one-fourth of the dex modifier to the score. At lv16
      skalds will reach up to 28 in pickpocketing, and with 25 dex, they’ll have the combined score of 40.
    </span>
  ),
  THIEF: (
    <span>
      To accomplish one's goals, for good or ill, the Thief is a skilled pilferer. Cunning, nimbleness, and stealth are
      a thief's hallmarks. Whether a thief turns talent against innocent passersby and wealthy merchants or oppressors
      and monsters is a choice for the Thief to make.
      <br />
      <br />
      Class features:
      <br />
      - May not wear armor heavier than studded leather
      <br />
      - May not equip shields larger than bucklers
      <br />
      - May only use the following weapons: long sword, short sword, katana, scimitar, dagger, club, quarterstaff, light
      crossbow, shortbow, dart, sling
      <br />
      - May only become Proficient (one slot) in any weapon class
      <br />
      - May only become Proficient (one slot) in any fighting style
      <br />
      - May distribute 25 points per level (40 at level 1) in thieving abilities: Open Locks, Pick Pockets, Find Traps,
      Move Silently, Hide In Shadows, Detect Illusion, Set Traps
      <br />
      - May backstab for increased damage: Lv1-4:x2 Lv5-8:x3 Lv9-12:x4 Lv13+:x5
      <br />
      - May use the Set Snare ability once per day. Gains one use at level 1 and an additional use every 5 levels
      thereafter. Set a trap in the chosen location when no hostile creatures are in sight. Traps grow more powerful
      with the Thief's level and can only be triggered by enemies.
      <br />
      - 1st level: Deals 2d8+5 missile damage
      <br />
      - 11th level: Deals 2d8+5 missile damage and additionally deals 2d6 poison damage per round for the next 3 rounds
      <br />
      - 16th level: Deals 3d8+5 missile damage and 4d8+2 fire damage
      <br />
      - 21st level: Deals 3d8+5 missile damage and 20 poison damage with no save, slays target if a Save vs. Death with
      a +4 bonus is failed
      <br />
      - Alignment restricted to any except lawful good
      <br />
      - Hit Die: d6
      <br />- Prime Requisites For Dual-Classing: Dexterity
    </span>
  ),
  ASSASSIN: (
    <span>
      Assassins are killers trained in discreet and efficient murder, relying on anonymity and surprise to perform their
      tasks.
      <br />
      <br />
      Advantages:
      <br />
      - 1 bonus to THAC0 and +1 bonus to damage rolls
      <br />
      - Backstab ability reaches x6 (at Lv17) and x7 (at Lv21) multiplier instead of capping at x5
      <br />
      - May use the Poison Weapon ability. Gains one use at level 1 and an additional use every 4 levels thereafter
      <br />
      <br />
      Disadvantages:
      <br />- May only distribute 15 skill points per level among thieving skills
    </span>
  ),
  BOUNTYHUNTER: (
    <span>
      This is a hunter of men, skilled in tracking quarry and bringing them back alive - whether for lawful authorities
      or underworld masters. Bounty Hunters are specially trained at their task and make fearsome opponents. They have
      honed their trap-making abilities well beyond that of the average Thief.
      <br />
      <br />
      Advantages:
      <br />
      - +15 bonus to Set Traps
      <br />
      - May use the Set Special Snare ability once per day in addition to the normal Thief's Set Snare. Gains one use at
      level 1 and an additional use every 5 levels thereafter. Set a trap in the chosen location when no hostile
      creatures are in sight. Traps grow more powerful with the Bounty Hunter's level and can only be triggered by
      enemies
      <br />
      - 1st level: Deals 3d8+5 missile damage and slows target for 5 rounds if a Save vs. Spell with a -4 penalty is
      failed
      <br />
      - 11th level: Deals 4d8+5 missile damage and holds target for 5 rounds if a Save vs. Spell with a -1 penalty is
      failed
      <br />
      - 16th level: Erects an Otiluke's Resilient Sphere around the target for 7 rounds if a Save vs. Spell is failed
      <br />
      - 21st level: Mazes the target, no save
      <br />
      <br />
      Disadvantages:
      <br />- May only distribute 20 skill points per level among thieving skills
    </span>
  ),
  SWASHBUCKLER: (
    <span>
      Swashbucklers are part acrobat, part swordsman, and part wit: the epitome of charm and grace.
      <br />
      <br />
      Advantages:
      <br />
      -1 bonus to Armor Class at 1st level, plus an additional -1 bonus every 5 levels
      <br />
      -1 bonus to THAC0 and +1 bonus to damage rolls every 5 levels
      <br />
      - May Specialize (two slots, -1 THAC0 bonus, +2 damage rolls bonus, but does not gain extra 1/2 APR) in any melee
      weapons available to Thieves
      <br />
      - May place 3 slots into Two-Weapon style
      <br />
      - Gains the Whirlwind Attack HLA from the warrior pool in place of Assassination
      <br />
      <br />
      Disadvantages:
      <br />- May not use Backstab ability
    </span>
  ),
  SHADOW_DANCER: (
    <span>
      Shadowdancers can harness the power of magic to increase their stealth abilities. Enigmatic and dangerous, these
      uniquely skilled Thieves are able to blend seamlessly into the shadows in ways that a normal Thief cannot,
      striking without warning - sometimes with supernatural speed.
      <br />
      <br />
      Advantages:
      <br />
      - +10 bonus to Hide In Shadows and Move Silently
      <br />
      - Hide In Plain Sight: A Shadowdancer may hide in shadows even while being observed
      <br />
      - May cast Shadowstep once per day. Gains one use at level 5 and an additional use every 5 levels thereafter. Step
      into the Shadow Plane and move for 7 seconds while others are frozen in time. The Shadowdancer cannot attack, cast
      spells, or use items while in the Shadow Plane and cannot use thief skills except Find Traps/Detect Illusion, Hide
      In Shadows. Items stolen from chests while shadowstepping will not raise any alarms.
      <br />
      - Slippery Mind: -1 bonus to saving throws
      <br />
      - Shadow Form (High Level Ability): Temporarily transposes some of the user's tissue with shadow-material, making
      the subject partially incorporeal. For the next 5 rounds, all physical attacks directed against the Shadowdancer
      only deal 50% of their normal damage. During this time, the Shadowdancer is also under the effects of Improved
      Invisibility and cannot be directly targeted by spells
      <br />
      - Shadow Maze (High Level Ability): Opens a temporary gateway to a small pocket within the Plane of Shadow. All
      enemies within 10 feet of the Shadowdancer must make a Save vs. Spell at a +4 penalty or become trapped inside a
      shadowy labyrinth as per the Maze spell
      <br />
      - Shadow Twin (High Level Ability): Creates an almost exact duplicate of the Shadowdancer for 2 turns. The
      duplicate is created at 60% of the level at which the Shadowdancer is currently on. It has all the abilities that
      the Shadowdancer would have at that level. It also has 60% of the Hit Points of the Shadowdancer. The duplicate is
      fully under the control of the Shadowdancer
      <br />
      <br />
      Disadvantages:
      <br />
      - Alignment restricted to any non-lawful
      <br />
      - Backstab multiplier is one less than an unkitted Thief, cannot backstab until level 5: Lv1-4:x1 Lv5-8:x2
      Lv9-12:x3 Lv13+:x4
      <br />
      - May only distribute 20 skill points per level (30 at level 1) among thieving skills
      <br />
      - May not use Set Snare ability, may not use Set Spike Trap, Set Exploding Trap, Set Time Trap HLAs
      <br />- Has three Prime Requisites for Dual-Classing: Strength, Dexterity (already has a min. requirement of 15)
      and Charisma
    </span>
  ),
  MAGE: (
    <span>
      The wizard strives to be a master of magical energies, shaping them and casting them as spells. To do so, they
      study strange tongues and obscure facts and devote much of their time to magical research. A wizard must rely on
      knowledge and wit to survive. Wizards are rarely seen adventuring without a retinue of fighters and men-at-arms.
      Because there are different types (or schools) of magic, there are different types of wizards. The Mages study all
      types of magic and learn a wide variety of spells; their broad range makes them well suited to the demands of
      adventuring.
      <br />
      <br />
      Class features:
      <br />
      - May only wear robes in the armor slot
      <br />
      - Cannot wear helmets
      <br />
      - May only use the following weapons: Daggers, Quarterstaves, Darts, Slings
      <br />
      - May only become Proficient (one slot) in any weapon class
      <br />
      - May not place any slots in any fighting style
      <br />
      - May cast arcane spells
      <br />
      - Hit Die: d4
      <br />
      - Prime requisites for dual-classing: Intelligence
      <br />
    </span>
  ),
  ABJURER: (
    <span>
      Abjurer: A mage who specializes in protective magics
      <br />
      <br />
      Advantages:
      <br />
      - May cast one additional arcane spell per spell level
      <br />
      - Receives a +15% learning chance bonus when scribing scrolls from the Abjuration school
      <br />
      - Receives a +2 bonus when making saving throws against spells from the Abjuration school
      <br />
      - Targets suffer a -2 penalty when making Saving Throws against spells from the Abjuration school
      <br />
      <br />
      Disadvantages:
      <br />- May not learn, use arcane scrolls, or cast any arcane spells of the Alteration school (BG)
      <br />- May not learn, use arcane scrolls, or cast any arcane spells of the Alteration or Illusion schools (IWD)
      <br />- Receives a -15% learning chance penalty when scribing scrolls from other schools
      <br />
      <br /> Requires at least 15 Wisdom
    </span>
  ),
  CONJURER: (
    <span>
      Conjurer: A mage who specializes in creating creatures and objects for assistance
      <br />
      <br />
      Advantages:
      <br />
      - May cast one additional arcane spell per spell level
      <br />
      - Receives a +15% learning chance bonus when scribing scrolls from the Conjuration school
      <br />
      - Receives a +2 bonus when making saving throws against spells from the Conjuration school
      <br />
      - Targets suffer a -2 penalty when making Saving Throws against spells from the Conjuration school
      <br />
      <br />
      Disadvantages:
      <br />- May not learn, use arcane scrolls, or cast any arcane spells of the Divination school (BG)
      <br />- May not learn, use arcane scrolls, or cast any arcane spells of the Invocation school (IWD)
      <br />- Receives a -15% learning chance penalty when scribing scrolls from other schools
      <br />
      <br /> Requires at least 15 Constitution
    </span>
  ),
  DIVINER: (
    <span>
      Diviner: A mage who specializes in detection and divining magics
      <br />
      <br />
      Advantages:
      <br />
      - May cast one additional arcane spell per spell level
      <br />
      - Receives a +15% learning chance bonus when scribing scrolls from the Divination school
      <br />
      - Receives a +2 bonus when making saving throws against spells from the Divination school
      <br />
      - Targets suffer a -2 penalty when making Saving Throws against spells from the Divination school
      <br />
      <br />
      Disadvantages:
      <br />
      - May not learn, use arcane scrolls, or cast any arcane spells of the Conjuration school (BG & IWD)
      <br />- Receives a -15% learning chance penalty when scribing scrolls from other schools
      <br />
      <br /> Requires at least 16 Wisdom
    </span>
  ),
  ENCHANTER: (
    <span>
      Enchanter: A mage who specializes in manipulating the minds of sentient beings
      <br />
      <br />
      Advantages:
      <br />
      - May cast one additional arcane spell per spell level
      <br />
      - Receives a +15% learning chance bonus when scribing scrolls from the Enchantment school
      <br />
      - Receives a +2 bonus when making saving throws against spells from the Enchantment school
      <br />
      - Targets suffer a -2 penalty when making Saving Throws against spells from the Enchantment school
      <br />
      <br />
      Disadvantages:
      <br />
      - May not learn, use arcane scrolls, or cast any arcane spells of the Invocation school (BG & IWD)
      <br />- Receives a -15% learning chance penalty when scribing scrolls from other schools
      <br />
      <br /> Requires at least 16 Charisma
    </span>
  ),
  ILLUSIONIST: (
    <span>
      Illusionist: A mage who specializes in creating illusions to confuse and mislead
      <br />
      <br />
      Advantages:
      <br />
      - May cast one additional arcane spell per spell level
      <br />
      - Receives a +15% learning chance bonus when scribing scrolls from the Illusion school
      <br />
      - Receives a +2 bonus when making saving throws against spells from the Illusion school
      <br />
      - Targets suffer a -2 penalty when making Saving Throws against spells from the Illusion school
      <br />
      <br />
      Disadvantages:
      <br />- May not learn, use arcane scrolls, or cast any arcane spells of the Necromancy school (BG)
      <br />- May not learn, use arcane scrolls, or cast any arcane spells of the Necromancy or Abjuration schools (IWD)
      <br />- Receives a -15% learning chance penalty when scribing scrolls from other schools
      <br />
      <br /> Requires at least 16 Dexterity
    </span>
  ),
  INVOKER: (
    <span>
      Invoker: A mage who specializes in the manipulation of raw and elemental energies
      <br />
      <br />
      Advantages:
      <br />
      - May cast one additional arcane spell per spell level
      <br />
      - Receives a +15% learning chance bonus when scribing scrolls from the Invocation school
      <br />
      - Receives a +2 bonus when making saving throws against spells from the Invocation school
      <br />
      - Targets suffer a -2 penalty when making Saving Throws against spells from the Invocation school
      <br />
      <br />
      Disadvantages:
      <br />- May not learn, use arcane scrolls, or cast any arcane spells of the Enchantment school (BG)
      <br />- May not learn, use arcane scrolls, or cast any arcane spells of the Divination or Conjuration schools
      (IWD)
      <br />- Receives a -15% learning chance penalty when scribing scrolls from other schools
      <br />
      <br /> Requires at least 16 Constitution
    </span>
  ),
  NECROMANCER: (
    <span>
      Invoker: A mage who specializes in magic dealing with death.
      <br />
      <br />
      Advantages:
      <br />
      - May cast one additional arcane spell per spell level
      <br />
      - Receives a +15% learning chance bonus when scribing scrolls from the Necromancy school
      <br />
      - Receives a +2 bonus when making saving throws against spells from the Necromancy school
      <br />
      - Targets suffer a -2 penalty when making Saving Throws against spells from the Necromancy school
      <br />
      <br />
      Disadvantages:
      <br />- May not learn, use arcane scrolls, or cast any arcane spells of the Illusion school (BG)
      <br />- May not learn, use arcane scrolls, or cast any arcane spells of the Illusion or Enchantment schools (IWD)
      <br />- Receives a -15% learning chance penalty when scribing scrolls from other schools
      <br />
      <br /> Requires at least 16 Wisdom
    </span>
  ),
  TRANSMUTER: (
    <span>
      Invoker: A mage who specializes in magic that alters physical realty.
      <br />
      <br />
      Advantages:
      <br />
      - May cast one additional arcane spell per spell level
      <br />
      - Receives a +15% learning chance bonus when scribing scrolls from the Alteration school
      <br />
      - Receives a +2 bonus when making saving throws against spells from the Alteration school
      <br />
      - Targets suffer a -2 penalty when making Saving Throws against spells from the Alteration school
      <br />
      <br />
      Disadvantages:
      <br /> - May not learn, use arcane scrolls, or cast any arcane spells of the Abjuration school (BG)
      <br /> - May not learn, use arcane scrolls, or cast any arcane spells of the Abjuration or Necromancy schools
      (IWD)
      <br />- Receives a -15% learning chance penalty when scribing scrolls from other schools
      <br />
      <br /> Requires at least 15 Dexterity
    </span>
  ),
  WILD_MAGE: (
    <span>
      Wild Mages are wizards who specialize in the study of wild magic. They have access to spells to protect themselves
      from wild magic and bend it to their wills. Wild magic is extremely unpredictable and should be used with caution.
      <br />
      <br />
      Wild Magic is a new type of magic that is characterized by powerful and dangerous surges of unpredictable magic.
      Generally considered to be an unfortunate byproduct of the Time of Troubles, wild magic has recently begun to
      attract the attention of many a curious or scholarly wizard.
      <br />
      <br />
      Class features:
      <br />
      - May memorize one additional arcane spell per spell level
      <br />
      - May cast the 1st-level spell Nahal's Reckless Dweomer, 2nd-level spell Chaos Shield and 7th-level spell Improved
      Chaos Shield, all included in the spellbook automatically
      <br />
      - Upon casting a spell, including casting from scrolls, there is a 5% chance of incurring a Wild Surge
      <br /> - WILD SURGE: A wiold surge generates a completely random magical effect from the spell being cast. Its
      effects may be either beneficial or detrimental to the Wild Mage and allies
      <br />
      - Casting level varies somewhat whenever a Wild Mage casts a spell - anywhere between 5 levels lower and levels
      higher than the Wild Mage's true level
      <br />- May not dual class
    </span>
  ),
  SORCERER: (
    <span>
      Sorcerers are practitioners of magic who are born with the innate ability to cast spells. It is thought that the
      blood of some powerful creature flows through their veins; perhaps they are the spawn of the gods themselves, or
      even dragons walking in humanoid form. Regardless, the Sorcerer's magic is intuitive rather than logical. They
      know fewer spells than Mages, and acquire spells more slowly, but they can cast spells more often and have no need
      to select and prepare spells ahead of time. Sorcerers cannot specialize in magic the way Mages do. Other than
      these differences, a Sorcerer is very similar to a Mage.
      <br />
      <br />
      Class Features:
      <br />
      - May not wear any armor
      <br />
      - May only use the following weapons: dagger, quarterstaff, dart, sling
      <br />
      - May only become Proficient (one slot) in any weapon class
      <br />
      - May not place any slots in any fighting style
      <br />
      - May cast arcane spells
      <br />
      - May not scribe spells into their spellbooks as Mages do. Instead, Sorcerers learn a small number of spells at
      each level, which they can cast daily without memorization
      <br />- Hit Die: d4
    </span>
  ),
  DRAGON_DISCIPLE: (
    <span>
      Dragon Disciples are powerful Sorcerers with dragons' blood somewhere in their lineage. Their natural magical
      talents bring out their draconic heritage, allowing them to cast powerful magical spells and exhibit dragon-like
      abilities.
      <br />
      <br />
      Advantages:
      <br />
      - 1st level: -1 bonus to Armor Class
      <br />
      - 3rd level: May use Breath Weapon once per day: The Dragon Disciple breathes a gout of flame up to 30 ft. long,
      inflicting 3d8 points of fire damage on all creatures caught within the 140-degree cone, it bypasses magic
      resistance but not fire resistance
      <br />
      - 4th level: Gains 25% innate Fire Resistance
      <br />
      - 5th level: -1 bonus to AC and +1 to Constitution
      <br />
      - 6th level: Breath Weapon damage increases to 4d8
      <br />
      - 8th level: Innate Fire Resistance rises to 50%
      <br />
      - 9th level: Breath Weapon damage increases to 5d8
      <br />
      - 10th level: -1 bonus to AC
      <br />
      - 12th level: Breath Weapon damage increases to 6d8
      <br />
      - 12th level: Innate Fire Resistance rises to 75%
      <br />
      - 15th level: Breath Weapon damage increases to 7d8
      <br />
      - 15th level: -1 bonus to AC and +1 to Constitution
      <br />
      - 16th level: Innate Fire Resistance rises to 100%
      <br />
      - 18th level: Breath Weapon damage increases to 8d8
      <br />
      - 20th level: -1 bonus to AC
      <br />
      - Hit Die: d6
      <br />
      - A total of 5 AC & 2 Constitution gained at level 20.
      <br />
      <br />
      Disadvantage:
      <br />- May cast one fewer spell per level per day
    </span>
  ),
  MONK: (
    <span>
      Monks are warriors who pursue perfection through contemplation as well as action. They are versatile fighters,
      especially skilled in combat without weapons or armor. Though Monks cannot cast spells, they have a unique magic
      of their own: they channel a subtle energy, called ki, which allows them to perform amazing feats. The Monk's
      best-known feat is their ability to stun an opponent with an unarmed blow.
      <br />
      <br />
      Class features:
      <br />
      - May not wear any armor
      <br />
      - May only use weapons available to the Thief class (except two-handed)
      <br />
      - May only become Proficient (one slot) in any weapon class
      <br />
      - May only become Proficient (one slot) in Single-Weapon Style and may not put slots into any other style
      <br />
      - Moves 2 points faster than other non-barbarian characters. Movement rate further improves by 1 every 5 levels
      <br />
      - May make 1 unarmed attack per round. An additional 1/2 attack per round is gained every 3 levels up to level 18.
      Damage dealt by unarmed attacks increases with level as follows: <br />
      - Level 1-2: 1d6
      <br />
      - Level 3-5: 1d8
      <br />
      - Level 6-8: 1d10
      <br />
      - Level 9-14: 1d12
      <br />
      - Level 15+: 1d20
      <br />
      - Unarmed attacks have the extended range of two-handed weapons, and at level 9, they're treated as +1 magical
      weapon and gain -1 THAC0 bonus and +1 damage rolls bonus. This enchantment improves to +2 at level 12, +3 at level
      15, and +4 at level 25
      <br />
      - Receives a -2 bonus to saving throws vs. spell
      <br />
      - Deflect Missiles: -1 bonus to AC vs. missile attacks every 3 levels
      <br />
      - Starts with a base Armor Class of 9 at 1st level and gains an additional -1 bonus every 2 levels
      <br />
      - May use the Stunning Blow ability once per day. Gains one use at level 1 and an additional use every 4 levels
      thereafter.
      <br />
      - 5th level: Acquires immunity to Disease, Slow, and Haste effects [1]
      <br />
      - 7th level: May use the Lay On Hands ability
      <br />
      - 8th level: Gains a -1 bonus to Speed Factor
      <br />
      - 9th level: Gains a -1 bonus to all Saving Throws and becomes immune to charm
      <br />
      - 11th level: Becomes immune to poison
      <br />
      - 12th level: Gains another -1 bonus to Speed Factor
      <br />
      - 13th level: May use the Quivering Palm ability once per day
      <br />
      - 14th level: Gains 3% Magic Resistance per level (starting with 42%) up to a maximum of 78% at the 26th level.
      <br />
      - 20th level: Becomes immune to non-magical weapons
      <br />
      - Alignment restricted to lawful
      <br />
      - Hit Die: d8
      <br />- May not Dual-class
    </span>
  ),
  DARK_MOON_MONK: (
    <span>
      The Order of the Dark Moon is a secretive monastic order that follows the teachings of the dark goddess Shar.
      Monks of the Dark Moon use physical prowess, dark magic, and many different forms of deception to mask their
      activities and strike with deadly precision.
      <br />
      <br />
      Advantages:
      <br />
      - +10 to Detect Illusion
      <br />
      - May invest points into the Detect Illusion skill
      <br />
      - May use the Frozen Fist ability once per day. Gains one use at level 1 and an additional use every 4 levels
      thereafter. When this ability is activated, the Dark Moon Monk's fists are wreathed in a frosty shell. For 1 turn,
      the Dark Moon Monk's unarmed attacks deal an additional 3 points of cold damage on each successful hit. Frozen
      Fist can be stacked several times for increased cold damage.
      <br />
      - 1st level: May cast Blindness once per day
      <br />
      - 3rd level: May cast Blur once per day
      <br />
      - 7th level: May cast Vampiric Touch once per day
      <br />
      - 11th level: May cast Mirror Image once per day
      <br />
      <br />
      Disadvantages:
      <br />
      - Alignment restricted to lawful evil
      <br />
      - May not use the Lay On Hands ability
      <br />- May not use the Stunning Blow ability
    </span>
  ),
  SUN_SOUL_MONK: (
    <span>
      Sun Soul Monks were once worshippers of Amaunator in the time of Netheril, but today their worship is divided
      between Lathander, Selûne, and Sune. Sun Soul Monks adhere strictly to the law, using their martial arts and
      magical abilities to drive out darkness and corruption wherever they find it.
      <br />
      <br />
      Class Features:
      <br />
      - 2nd level: May cast Sun Soulray once per day. The Sun Soul Monk projects a blast of light from an open palm,
      dealing 1d8 fire damage for every 2 caster levels (up to a maximum of 5d8 damage) to a single target. Undead
      creatures take an additional 6 points of damage
      <br />
      - 5th level: May cast Flaming Fists once per day
      <br />
      - 6th level: Gains an additional use of Sun Soulray
      <br />
      - 8th level: May cast Greater Sun once per day. The Sun Soul Monk wreathes in flames that act as a Fireshield
      (Red), granting the Monk 50% Fire Resistance and protecting from attacks made within a 5-ft. radius. An opponent
      that hits the Monk with any weapons or spells within this radius suffers 1d8+2 points of fire damage
      <br />
      - 10th level: Gains an additional use of Sun Soulray
      <br />
      - 13th level: May cast Sun Soulbeam once per day. The Sun Soul Monk emits a dazzling burst of light that strikes
      at all other creatures within a 30-ft. radius. The Sun Soulbeam does not automatically hit all targets but makes a
      melee attack using the Monk's current THAC0 (-3 bonus vs. undead). Struck creatures suffer 9d6 points of damage
      (9d6+3 if undead), unless they save vs. Spell for half. In addition, all creatures except the Monk must save vs.
      Spell or be blinded for 2 in-game hours
      <br />
      - 15th level: Gains an additional use of Sun Soulray
      <br />
      <br />
      Disadvantages:
      <br />
      - Alignment restricted to lawful good
      <br />
      - May not use the Stunning Blow ability
      <br />- May not use the Quivering Palm ability
    </span>
  ),
  SHAMAN: (
    <span>
      Shamans forge a divine connection with the natural world, similar to the way Druids commune with nature, but
      Shamans are more spiritual than primal. They directly tap into the energies that flow through the earth and air,
      through plants and animals, and even through the dead, and shape these energies to obey their will. Nowhere is
      this more evident than in their ability to summon powerful spirits as guardians and defenders. A being of two
      worlds, Shamans value balance over dominance.
      <br />
      <br />
      Class features:
      <br />
      - May only wear leather, studded leather, and hide armor
      <br />
      - May not equip shields larger than bucklers
      <br />
      - May only use the following weapons: dagger, club, spear, axe, quarterstaff, dart, sling, and shortbow
      <br />
      - May only become Proficient (one slot) in any weapon class
      <br />
      - May only become Proficient (one slot) in any fighting style
      <br />
      - May cast druidic spells
      <br />
      - Does not automatically learn all spells of a given level as Druids do. Instead, Shamans select new spells to
      learn at each level, which they can cast daily without memorization
      <br />
      - Gains immediate access to a number of Shaman-specific spells, which can be cast like any other: Spirit Ward,
      Writhing Fog, Spiritual Clarity, Spirit Fire, Recall Spirit, Spiritual Lock, and Ether Gate
      <br />
      - Gains use of Detect Illusion skill with 20% points as a base, with an additional 4 points per level, up to a
      maximum of 100% at level 20
      <br />
      - May use Shamanic Dance at will. Shamans can summon spirit allies by performing a ritualistic dance. While
      dancing, the Shaman takes a +4 penalty to Armor Class and cannot move, attack, cast spells, use items and special
      abilities, or perform any other activity. Each round while the Shaman dances, there is a 35% base chance plus 2%
      for each level of the Shaman that a spirit will answer the call, up to a maximum of 75% at level 20. Any summoned
      spirits will disappear if the Shaman moves, stops dancing, or if the spirits leave visual range. The spirits grow
      in power as the Shaman gains additional levels:
      <br />
      - Level 1: Minor animal spirits (snake, fox, hound), up to 2 spirits at the same time
      <br />
      - Level 6: Major animal spirits (bear, panther, boar), up to 3 spirits at the same time
      <br />
      - Level 12: Minor nature spirits (lesser air spirit, lesser earth spirit, lesser fire spirit), up to 4 spirits at
      the same time
      <br />
      - Level 18: Major nature spirits (air spirit, earth spirit, fire spirit), up to 5 spirits at the same time
      <br />
      - The type of the summoned spirit is randomly determined from all the spirits available at the Shaman's level. For
      example, a level 12 Shaman will summon either a minor nature spirit, a major animal spirit, or a minor animal
      spirit, but cannot choose which one appears. Spirits will do their best to protect the Shaman but are not
      controlled directly.
      <br />
      - Does not gain bonus spells per day for high Wisdom
      <br />
      - Alignment restricted to neutral good, true neutral, and neutral evil
      <br />
      - Ineligible for any stronghold (Affects only the Shadows of Amn campaign in Shadows of Amn LOGOSOA00001 Icon
      BG2EE)
      <br />
      - Hit Die: d8
      <br />- May not Dual-class
    </span>
  ),
  DRUID: (
    <span>
      The Druids serve the cause of nature and neutrality, the wilderness is their community, and they use their special
      powers to protect it and to preserve balance in the world.
      <br />
      <br />
      Class features:
      <br />
      - May only wear non-metallic armor. (May wear any leather, studded leather or hide armors. May wear heavier armors
      as long as they are non-metallic, such as Ankheg Plate Mail.)
      <br />
      - May only equip bucklers and non-metallic shields
      <br />
      - May only use the following weapons: scimitar, dagger, club, spear, quarterstaff, dart, sling
      <br />
      - May only become Proficient (one slot) in any weapon class
      <br />
      - May only become Proficient (one slot) in any fighting style
      <br />
      - May cast druidic spells
      <br />
      - 7th level: May Shapeshift into a wolf, black bear, or brown bear once per day
      <br />
      - 15th level: Becomes immune to poison
      <br />
      - 18th level: Gains 10% resistance to cold, fire, electricity, and acid, gains a further 10% resistance at levels
      21 and 24
      <br />
      - Alignment restricted to true neutral
      <br />
      - Hit Die: d8
      <br />- Prime Requisites For Dual-Classing: Wisdom, Charisma
    </span>
  ),
  TOTEMIC: (
    <span>
      The Totemic Druids closely identify with a particular animal, an animal that they feel represents their individual
      spirits. This grants the Totemic Druids a special connection to the animal kingdom, and they're able to call upon
      their spirits to aid them.
      <br />
      <br />
      Advantages:
      <br />
      - May use the Summon Spirit Animal ability once per day. Gains one use at level 1 and an additional use every 5
      levels thereafter. The Totemic Druids summon a special spirit animal (spirit bear, spirit wolf, spirit lion, or
      spirit snake) to aid them for 6 turns. The spirit animal has 1 HD at level 1, 3 HD at level 3, 5 HD at level 5, 7
      HD at level 7, and 10 HD at level 10.
      <br />
      <br />
      Disadvantage:
      <br />- Cannot use shapeshift
    </span>
  ),
  SHAPESHIFTER: (
    <span>
      The Shapeshifters are not called Shapeshifter because they have access to a great variety of forms, but rather
      because of their complete dedication to a single alternate form. Shapeshifters have willingly allowed themselves
      to become infected with lycanthropy, but due to intense study and training they have the ability to control their
      afflictions. The creatures they become are that of werewolves, the most famous of the lycanthrope shape changers.
      <br />
      <br />
      Advantages
      <br />
      - May shapeshift into the form of a werewolf once per day. Gains one use at level 1 and an additional use every 2
      levels thereafter up to level 11.
      <br />
      - 13th level: May shapeshift into a Greater Werewolf once per day. Gains one use at level 13 and an additional use
      every 2 levels thereafter.
      <br />
      <br />
      Disadvantages:
      <br />
      - Cannot wear any body armor, although spells such as Barkskin and Iron Skins may still be used.
      <br />- No other shapeshifting abilities due to the effort required in maintaining balance in primary forms.
    </span>
  ),
  AVENGER: (
    <span>
      A member of a special sect within the druidic order, a Druid of this type is dedicated to fighting those who would
      defile nature. Avengers have powers the average Druid does not - additional abilities that have been earned
      through extensive rituals, a process that is very physically draining.
      <br />
      <br />
      Advantages:
      <br />
      - May Shapeshift into the form of a sword spider, baby wyvern, and fire salamander in addition to the normal
      shapeshifting abilities.
      <br />
      - 19th level: Gains an additional use of Shapeshift.
      <br />
      - They have six wizard spells available, up to level 6. The spells are: Chromatic Orb, Web, Lightning Bolt,
      Improved Invisibility, Chaos, and Chain Lightning.
      <br />
      <br />
      Disadvantages:
      <br />
      - May only wear leather armor.
      <br />- It receives a -2 penalty to Strength and Constitution (max 16 at character creation).
    </span>
  ),
  FIGHTER_THIEF:
    'This character can use the abilities of a Fighter and a Thief, though they cannot use their thieving skills while wearing more than studded leather armor. They may Specialize in, but not Master, any weapon they can use.',
  CLERIC_MAGE:
    'This character can use the abilities of a Cleric and a Mage, though weapons are restricted to only those allowed by the Clerics ethos, and Mage spells cannot be cast while wearing armor. Gnomes can choose this multi-class but become Cleric/Illusionists by default. Gnomes are the only race that can combine a specialist Mage class in a multi-class.',
  CLERIC_ILLUSIONIST:
    'This character can use the abilities of a Cleric and a Mage, though weapons are restricted to only those allowed by the Clerics ethos, and Mage spells cannot be cast while wearing armor. Gnomes can choose this multi-class but become Cleric/Illusionists by default. Gnomes are the only race that can combine a specialist Mage class in a multi-class.',

  CLERIC_RANGER:
    'This character can use the abilities of a Cleric and a Ranger, though weapons are restricted to only those allowed by the Clerics ethos. This character may Specialize in, but not Master, any weapon they can use.',
  CLERIC_THIEF:
    'This character can use the abilities of a Cleric and a Thief, though weapons are restricted to only those allowed by the Clerics ethos. Thieving skills cannot be used while wearing more than studded leather armor.',
  FIGHTER_DRUID:
    'This character can use the abilities of a Fighter and a Druid, though weapons are restricted to only those allowed by the Druids ethos. This character may Specialize in, but not Master, any weapon they can use. ',
  FIGHTER_ILLUSIONIST:
    'This character can use the abilities of a Fighter and a Mage, though they cannot cast spells while wearing armor. This character may Specialize in, but not Master, any weapon they can use. Gnomes can choose this multi-class but become Fighter/Illusionists by default. Gnomes are the only race that can combine a specialist Mage class in a multi-class.',
  FIGHTER_MAGE:
    'This character can use the abilities of a Fighter and a Mage, though they cannot cast spells while wearing armor. This character may Specialize in, but not Master, any weapon they can use. Gnomes can choose this multi-class but become Fighter/Illusionists by default. Gnomes are the only race that can combine a specialist Mage class in a multi-class.',
  FIGHTER_MAGE_CLERIC:
    'This character can use the abilities of a Fighter, Mage, and Cleric, though they are restricted to weapons allowed by the Clerics ethos and cannot cast Mage spells while wearing armor. This character may Specialize in, but not Master, any weapon they can use.',
  FIGHTER_MAGE_THIEF:
    'This character can use the abilities of a Fighter, Mage, and Thief, though they cannot use their thieving skills while wearing more than studded leather armor and cannot cast spells while wearing any armor. This character may Specialize in, but not Master, any weapon they can use.',
  FIGHTER_CLERIC:
    "This character can use the abilities of a Fighter and a Cleric, though weapons are restricted to only those allowed by the Cleric's ethos. This character may Specialize in, but not Master, any weapon they can use.",
  ILLUSIONIST_THIEF:
    'This character can use the abilities of a Mage and a Thief, though Mage spells cannot be cast while wearing armor. Gnomes can choose this multi-class but become Illusionist/Thieves by default. Gnomes are the only race that can combine a specialist Mage class in a multi-class.',

  MAGE_THIEF:
    'This character can use the abilities of a Mage and a Thief, though Mage spells cannot be cast while wearing armor. Gnomes can choose this multi-class but become Illusionist/Thieves by default. Gnomes are the only race that can combine a specialist Mage class in a multi-class.',
};
