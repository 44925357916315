import { CharacterType } from '../Types/CharacterType';

interface IGameTypeForm {
  handleClick: (char: Partial<CharacterType>) => void;
  handleClose: () => void;
}
export function GameTypeForm(props: IGameTypeForm) {
  const { handleClick, handleClose } = props;
  return (
    <div className="flex-col flex-between">
      <div className="es-button-group">
        <button
          className="es-button es-button--primary m-1r"
          id={'baldursGateBtn'}
          type="button"
          onClick={() => handleClick({ iwd: false })}>
          <img
            id="bgLogo"
            title="Baldur's Gate"
            className="es-img__logo"
            src={'./images/bg-logo.png'}
            alt="baldurs gate"
          />
        </button>
        <button
          className="es-button es-button--primary m-1r"
          id={'icewindDaleBtn'}
          type="button"
          onClick={() => handleClick({ iwd: true })}>
          <img
            id="iwdLogo"
            title="Icewind Dale"
            className="es-img__logo"
            src={'./images/iwd-logo.png'}
            alt="Icewind Dale"
          />
        </button>
      </div>
      <div className="flex flex-center">
        <button
          className="es-button es-button--primary es-ch-form__option-btn"
          id={'cancelCurrentStep'}
          type="button"
          onClick={handleClose}>
          Cancel
        </button>
      </div>
    </div>
  );
}
