import React, { useState, Fragment } from "react";
import _ from "lodash";
import ESModal from "../../common/ESModal";
import cn from "classnames";
import { CLASS_DESCRIPTIONS } from "../../modules/descriptions";

interface ClassPillProps {
  chClass: string;
  className?: string;
}

export const ClassPill = (props: ClassPillProps) => {
  const [modalOpen, toggleModal] = useState<boolean>(false);
  const { chClass } = props;
  const charClasses = chClass.split("_");

  let classColorCssVariables: React.CSSProperties = {};
  charClasses.forEach((chClass, i) => {
    const classForLookup = chClass === "ILLUSIONIST" ? "MAGE" : chClass;

    i += 1;
    const color = classForLookup.toLowerCase().trim();
    classColorCssVariables = {
      ...classColorCssVariables,
      [`--pill-color-${i}`]: `var(--color-${color})`,
    };
  });
  //should only be between 1 - 3
  // const count = charClasses.length;

  const classDescriptionForModal =
    CLASS_DESCRIPTIONS[chClass];

  function handleClick(e: React.MouseEvent) {
    e.stopPropagation();
    toggleModal(true);
  }

  function handleClose(
    e: React.MouseEvent | KeyboardEvent | React.KeyboardEvent
  ) {
    if (e && e.stopPropagation) {
      e.stopPropagation();
    }
    toggleModal(false);
  }

  // const pillCount = `es-pill-${count}`;
  
  function handleKeyDown(e: React.KeyboardEvent){
    e.stopPropagation();
    toggleModal(true);
  }


  return (
    <Fragment>
      <div
        style={classColorCssVariables}
        className={cn("es-pill es-pill--main-theme es-pill--btn")}
        onClick={handleClick}
        onKeyDown={handleKeyDown}

        tabIndex={0}
      >
        <p>{_.upperCase(chClass)}</p>
        <svg className="es-icon es-icon--sm es-pill__icon">
          <use href="images/sprite.svg#icon-info"></use>
        </svg>
      </div>
      <ESModal
        id="classModal"
        title={_.startCase(chClass)}
        children={classDescriptionForModal}
        isVisible={modalOpen}
        closeOnOutsideClick={true}
        handleClose={handleClose}
      />
    </Fragment>
  );
};
