import { WeaponType } from '../Types/CharacterType';
import { Fragment } from 'react';
import _ from "lodash";

interface WeaponSkillTableProps {
  weaponSkills?: Record<WeaponType, number>;
}
export function WeaponSkillTable(props: WeaponSkillTableProps) {
  const { weaponSkills } = props;
  if (!weaponSkills) {
    return null;
  }
  return (
    <div className="es-char-card__section es-char-card__section--weapons">
      {Object.entries(weaponSkills).map((entry, index) => {
        const skillAmount = entry[1];
        const skillPts = new Array(skillAmount).fill('');

        return (
          <Fragment key={`weapon-${index}`}>
            <div className="es-char-card__stat-block">
              <div className="es-pill es-pill--main-theme flex">
                <span className="es-char-card__skill-name">{_.startCase(entry[0])}: </span>

                <span className="es-char-card__point">
                  {skillPts.map((_, i) => (
                    <svg key={`icon${i}_${entry[0]}`} className="es-icon es-icon__weapon">
                      <use href="images/sprite.svg#icon-sword"></use>
                    </svg>
                  ))}
                </span>
              </div>
            </div>
          </Fragment>
        );
      })}
    </div>
  );
}
