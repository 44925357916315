import { Fragment } from "react";
import _ from "lodash";

interface SavingThrowsProps {
  savingThrows: Record<string, number>;
}

export default function SavingThrows(props: SavingThrowsProps) {
  const { savingThrows } = props;
  return (
    <section className="center-text">
      <h5>Saving Throws</h5>
      <div className="es-char-card__section--saving-throws">
        {Object.keys(savingThrows).map((key, index) => {
          return (
            <Fragment key={`savingThrows-${index}`}>
              <div className="es-pill es-pill--main-theme flex">
                <span>{_.startCase(key)}: </span>
                <span className="es-char-card__stat">{savingThrows[key]}</span>
              </div>
            </Fragment>
          );
        })}
      </div>
    </section>
  );
}
