import { useState } from 'react';
import { CharacterType } from '../Types/CharacterType';
import { useQuery } from 'react-query';
import { fetchAllPortraitsByGameAndGender } from '../../api/apiUtil';

interface IGenderForm {
  handleSave: (char: Partial<CharacterType>) => void;
  gameType: 'BG' | 'IWD';
  handleClose: () => void;
}

export function GenderForm(props: IGenderForm) {
  const { handleSave, gameType, handleClose } = props;
  const [gender, setGender] = useState<'MALE' | 'FEMALE'>();
  const [portraitIndex, setPortraitIndex] = useState<number>(0);

  const {
    data: portraits = [],
    isLoading,
    isError,
    isFetching,
  } = useQuery(['PORTRAITS', gameType, gender], fetchAllPortraits, {
    staleTime: 30 * 60 * 60 * 1000,
    enabled: !!gender && !!gameType,
  });

  function fetchAllPortraits() {
    if (gender && gameType) {
      return fetchAllPortraitsByGameAndGender(gameType, gender);
    }
  }

  function handleAccept() {
    if (gender && portrait) {
      handleSave({ gender, portrait });
    }
  }
  function handlePreviousPortrait() {
    if (portraitIndex === 0) {
      setPortraitIndex(portraits.length - 1);
    } else {
      const prevIndex = portraitIndex - 1;
      setPortraitIndex(prevIndex);
    }
  }

  function handleNextPortrait() {
    if (portraitIndex === portraits.length - 1) {
      setPortraitIndex(0);
    } else {
      const nextIndex = portraitIndex + 1;
      setPortraitIndex(nextIndex);
    }
  }

  let src = './images/portraits/';
  const portrait = Array.isArray(portraits) ? portraits[portraitIndex] : '';
  if (typeof portrait === 'string') {
    if (gameType === 'IWD') {
      src += `IWDPortraits/${gender}/${portrait.trim()}.BMP`;
    } else {
      src += `BGPortraits/${gender}/${portrait.trim()}.bmp`;
    }
  }

  if (!gender) {
    return (
      <div className="flex-col flex-center">
        <div className="flex flex-center">
          <button
            className="es-button es-button--primary m-1r"
            id={'femaleBtn'}
            type="button"
            title="female"
            onClick={() => setGender('FEMALE')}>
            <svg className="es-icon es-icon--large es-icon--animated">
              <use href="images/sprite.svg#icon-female"></use>
            </svg>
          </button>
          <button
            className="es-button es-button--primary m-1r"
            id={'maleBtn'}
            type="button"
            title="male"
            onClick={() => setGender('MALE')}>
            <svg className="es-icon es-icon--large es-icon--animated">
              <use href="images/sprite.svg#icon-male"></use>
            </svg>
          </button>
        </div>
        <div className="flex flex-center f-a-c">
          <button
            className="es-button es-button--primary es-ch-form__option-btn"
            id={'cancelCurrentStep'}
            type="button"
            onClick={handleClose}>
            Cancel
          </button>
        </div>
      </div>
    );
  }
  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      {isLoading || isFetching ? (
        'Loading...'
      ) : (
        <div className="flex flex-center f-a-c">
          <button
            className="es-button es-button--primary"
            id={'previousPortraitBtn'}
            type="button"
            onClick={handlePreviousPortrait}>
            {'←'}
          </button>
          <div className="es-char-card__img-container es-char-card__img-container--large">
            <div
              id="charPortrait"
              className="es-char-card__img es-char-card__img--large"
              data-img={`${src}`}
              style={{ backgroundImage: `url(${src})` }}
            />
          </div>

          <button
            className="es-button es-button--primary"
            id={'nextPortraitBtn'}
            type="button"
            onClick={handleNextPortrait}>
            {'→'}
          </button>
        </div>
      )}
      <div className="flex f-a-c flex-center es-button-group">
        <button
          className="es-button es-button--primary"
          id={'backBtn'}
          type="button"
          disabled={gender === undefined}
          onClick={() => setGender(undefined)}>
          {'Back'}
        </button>
        <button
          className="es-button es-button--primary"
          id={'portraitAccept'}
          type="button"
          disabled={isLoading || isError || typeof portrait !== 'string'}
          onClick={handleAccept}>
          {'Accept'}
        </button>
        <button className="es-button es-button--primary" id={'cancelCurrentStep'} type="button" onClick={handleClose}>
          Cancel
        </button>
      </div>
    </div>
  );
}
