import { CharacterType } from '../Types/CharacterType';
import _ from 'lodash';
import { RacePill } from '../CharacterCard/RacePill';
import { ClassPill } from '../CharacterCard/ClassPill';
import CharacterStats from '../CharacterCard/CharacterStats';
import ThiefSkills from '../CharacterCard/ThiefSkills';
import { SpellSection } from '../CharacterCard/SpellSection';
import SavingThrows from '../CharacterCard/SavingThrows';
import { getThiefSkillsToDisplay } from '../../modules/utility';
import { WeaponSkillTable } from '../CharacterCard/WeaponSkillTable';

interface ICharacterFormResult {
  character: Partial<CharacterType>;
}
export function CharacterFormResult(props: ICharacterFormResult) {
  const { character } = props;

  let thiefSkills: Record<string, number> | undefined = undefined;
  if (character.thiefSkills) {
    thiefSkills = getThiefSkillsToDisplay(character.thiefSkills, character.thiefSkills.bonusSkillMap);
  }

  return (
    <div className="es-character-form__result">
      {typeof character.iwd === 'boolean' && (
        <div className="es-pill es-pill--main-theme col-span-2 grid-center-self">
          <p>{character.iwd ? 'ICEWIND DALE' : "BALDUR'S GATE"}</p>
        </div>
      )}
      {typeof character.gender === 'string' && (
        <div className="es-pill es-pill--main-theme col-span-2 grid-center-self">
          <p>{_.startCase(character.gender)}</p>
        </div>
      )}
      {typeof character.raceName === 'string' && <RacePill race={character.raceName} />}
      {typeof character.className === 'string' && <ClassPill chClass={character.className} />}
      {typeof character.classKit === 'string' && character.classKit !== "TRUE_CLASS" && <ClassPill chClass={character.classKit} />}
      {typeof character.alignment === 'string' && (
        <div className="es-pill es-pill--main-theme col-span-2 grid-center-self">
          <p>{_.startCase(character.alignment)}</p>
        </div>
      )}
      {typeof character.abilityScores === 'object' && <CharacterStats abilityScores={character.abilityScores} />}
      {typeof character.weaponSkills === 'object' && <WeaponSkillTable weaponSkills={character.weaponSkills} />}
      {typeof character.thiefSkills === 'object' &&
        character.isThief &&
        Object.keys(character.thiefSkills).length > 0 &&
        character.thiefSkills &&
        thiefSkills && (
          <div>
            <ThiefSkills thiefSkills={thiefSkills} />
          </div>
        )}
      {typeof character.racialEnemy === 'string' && (
        <div className="es-pill es-pill--main-theme col-span-2 grid-center-self ">
          <p className="flex flex-between">
            <span>Racial Enemy: &nbsp;</span>
            <span className="capital es-char-card__stat">{_.lowerCase(character.racialEnemy)}</span>
          </p>
        </div>
      )}
      {Array.isArray(character.knownArcaneSpells) && character.knownArcaneSpells.length > 0 && (
        <SpellSection spells={character.knownArcaneSpells} id="knownArcane" />
      )}
      {Array.isArray(character.memorizedArcaneSpells) && character.memorizedArcaneSpells.length > 0 && (
        <SpellSection spells={character.memorizedArcaneSpells} id="memorizedArcane" />
      )}
      {Array.isArray(character.memorizedDivineSpells) && character.memorizedDivineSpells.length > 0 && (
        <SpellSection spells={character.memorizedDivineSpells} id="memorizedDivine" />
      )}
      {Array.isArray(character.memorizedDruidSpells) && character.memorizedDruidSpells.length > 0 && (
        <SpellSection spells={character.memorizedDruidSpells} id="memorizedDruid" />
      )}
      {character.savingThrows && typeof character.savingThrows === 'object' && (
        <SavingThrows savingThrows={character.savingThrows} />
      )}
    </div>
  );
}
