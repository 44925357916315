import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App.tsx';
import { QueryClient, QueryClientProvider } from 'react-query';
import * as Sentry from '@sentry/react';
import { ReactQueryDevtools } from 'react-query/devtools';
import { RouterProvider, Router, Route, RootRoute, createHashHistory } from '@tanstack/router';
import CharacterGeneratorPage from './components/CharacterGeneratorPage/CharacterGeneratorPage.tsx';
import { CharacterForm } from './components/CharacterForm/CharacterForm.tsx';
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

Sentry.init({
  dsn: 'https://d90b479d29624e99b887a01736cb4f57@o4505596202385408.ingest.sentry.io/4505596204482560',
  integrations: [
    new Sentry.BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ['www.bgautochar.com', 'https:bgautochar.com'],
    }),
    new Sentry.Replay(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const rootRoute = new RootRoute({
  component: App,
});
const randomizerRoute = new Route({
  getParentRoute: () => rootRoute,
  path: '/',
  component: CharacterGeneratorPage,
});

const formRoute = new Route({
  getParentRoute: () => rootRoute,
  path: '/character-form',
  component: CharacterForm,
});

const routeTree = rootRoute.addChildren([randomizerRoute, formRoute]);
const hashHistory = createHashHistory()
const router = new Router({ routeTree, history: hashHistory });

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <RouterProvider router={router} />
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  </React.StrictMode>
);
