import { useState } from 'react';
import { useQuery } from 'react-query';
import { CharacterType } from '../Types/CharacterType';
import { fetchAlignmentsByClassAndKit } from '../../api/apiUtil';
import _ from 'lodash';
import cn from 'classnames';
import { ALIGNMENT_DESCRIPTIONS } from '../../modules/descriptions';

interface IAlignmentForm {
  handleSave: (char: Partial<CharacterType>) => void;
  handleClose: () => void;
  classType: string;
  classKit: string;
}
export function AlignmentForm(props: IAlignmentForm) {
  const { handleSave, handleClose, classType, classKit } = props;
  const [selectedAlignment, setAlignment] = useState<string>();
  const {
    data: alignments = [],
    isLoading,
    isError,
  } = useQuery(['ALIGNMENTS', classType, classKit], fetchAlignments, {
    staleTime: 30 * 60 * 60 * 1000,
    enabled: !!classType && !!classKit,
  });

  function fetchAlignments() {
    if (classType && classKit) {
      return fetchAlignmentsByClassAndKit(classType, classKit);
    }
  }

  function _handleSave() {
    if (selectedAlignment) {
      handleSave({ alignment: selectedAlignment });
    }
  }

  return (
    <div className="flex-col flex-between">
      <div className="flex flex-center">
        <div className="flex-col es-ch-form__races">
          {!isLoading &&
            alignments.map((alignment: string, i: number) => (
              <button
                key={_.camelCase(alignment) + i}
                className={cn('es-button es-button--primary es-ch-form__option-btn', {
                  'es-button--selected': alignment === selectedAlignment,
                })}
                id={`${_.camelCase(alignment)}Btn`}
                type="button"
                onClick={() => setAlignment(alignment)}>
                {_.lowerCase(alignment)}
              </button>
            ))}
        </div>
        <div className="flex-col  es-ch-form__description">
          <p style={{ fontSize: '1rem', padding: '1rem' }}>
            {selectedAlignment
              ? (ALIGNMENT_DESCRIPTIONS[selectedAlignment] as React.ReactNode)
              : 'You are to choose your alignment. Your alignment will determine how your character should interact with his environment. Read over each carefully before choosing. If you stray severely from your alignment during the course of the game, there will be consequences.'}
          </p>
          {isError && <span>Error fetching alignments</span>}
        </div>
      </div>
      <div className="flex f-a-c flex-center">
        <button
          className="es-button es-button--primary es-ch-form__option-btn"
          id={'cancelCurrentStep'}
          type="button"
          onClick={handleClose}>
          Cancel
        </button>
        <button
          className="es-button es-button--primary es-ch-form__option-btn"
          id={'saveAlignment'}
          type="button"
          onClick={_handleSave}>
          Done
        </button>
      </div>
    </div>
  );
}
