import { useState } from 'react';
import ESTextInput from '../../common/form/ESTextInput';
import { CharacterType } from '../Types/CharacterType';
const VALID_CHAR = /[\p{Letter}\p{Mark}\s-]+/gu;

interface ICharacterNameForm {
  handleSave: (char: Partial<CharacterType>) => void;
  handleClose: () => void;
}
export function CharacterNameForm(props: ICharacterNameForm) {
  const { handleSave, handleClose } = props;
  const [name, setName] = useState<string>('');

  function _handleSave() {
    handleSave({ charName: name });
  }

  function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    const text = e.target.value;
    if (!text) {
      setName(text);
    } else {
      if (typeof text === 'string' && text.match(VALID_CHAR) && text.length < 65) {
        setName(text);
      }
    }
  }

  return (
    <div className="flex-col f-a-c justify-center">
      <ESTextInput
        id="name"
        className="flex p-1r fs-1-5 es-ch-form__name-input"
        value={name}
        handleChange={handleChange}
        name="characterName"
        label="Name"
      />
      <div className="flex f-a-c flex-center">
        <button
          className="es-button es-button--primary es-ch-form__option-btn"
          id={'cancelCurrentStep'}
          type="button"
          onClick={handleClose}>
          Cancel
        </button>
        <button
          className="es-button es-button--primary es-ch-form__option-btn"
          id={'saveName'}
          type="button"
          onClick={_handleSave}>
          Done
        </button>
      </div>
    </div>
  );
}
