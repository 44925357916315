export const Header = () => {
  return (
    <header id="characterGeneratorHeader" className="header">
      <img
        src={"./images/Character Generator.png"}
        className=" bg-header__img"
        alt="BG Auto Char Logo"
      />
    </header>
  );
};
