import _ from "lodash";
import { IThiefSkillMap, TThiefSkill } from "../components/Types/CharacterType";

export function getThiefSkillsToDisplay(
  baseSkills: IThiefSkillMap,
  bonusSkills: Record<TThiefSkill, number>
): Record<string, number> {
  if (typeof baseSkills === 'object') {
    return Object.entries(baseSkills).reduce((obj, entry) => {
      const baseSkillValue = entry[1] as number;
      const bonusSkillkey = `${_.snakeCase(entry[0])}`.toUpperCase() as TThiefSkill;
      const bonusSkillValue = bonusSkills[bonusSkillkey] || 0;
      return { ...obj, [entry[0]]: baseSkillValue + bonusSkillValue };
    }, {});
  } else {
    return {};
  }
}
