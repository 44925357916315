import React, { Fragment, useState, useRef, useEffect } from 'react';
import _ from 'lodash';
import ThiefSkills from './ThiefSkills';
import CharacterStats from './CharacterStats';
import CharacterAvatar from './CharacterAvatar';
import { RacePill } from './RacePill';
import { ClassPill } from './ClassPill';
import { SpellSection } from './SpellSection';
import cn from 'classnames';
import { CharacterType } from '../Types/CharacterType';
import SavingThrows from './SavingThrows';
import { useReRollMutation } from '../../hooks/apiHooks';
import ESModal from '../../common/ESModal';
import { handleDownloadSingleCharacter } from '../../api/apiUtil';
import { WeaponSkillTable } from './WeaponSkillTable';
import { hasArcaneSpells, hasDivineSpells, hasDruidSpells } from '../../utils';

interface CharacterCardProps {
  character: CharacterType;
  index: number;
  gameType: 'bg' | 'iwd';
}

export default function CharacterCard(props: CharacterCardProps) {
  const [showExpanded, setExpanded] = useState<boolean>(false);

  const [errorModalOpen, toggleErrorModal] = useState<boolean>(false);
  const {
    mutate: reRollMutation,
    isLoading: isReRollLoading,
    isError: isReRollError,
    reset: resetReRoll,
  } = useReRollMutation();

  const { character, index, gameType } = props;
  const { raceName, className, classKit, alignment } = character;

  const charClasses = className?.split('_') || [];

  let classColorCssVariables: React.CSSProperties = {};
  charClasses.forEach((chClass, i) => {
    const classForLookup = chClass === 'ILLUSIONIST' ? 'MAGE' : chClass;

    i += 1;
    const color = classForLookup.toLowerCase().trim();
    classColorCssVariables = {
      ...classColorCssVariables,
      [`--card-color-${i}`]: `var(--color-${color})`,
    };
  });

  //should only be between 1 - 3
  const count = charClasses.length;

  const cardRef = useRef<HTMLDivElement>(null);
  const castArcane: boolean = hasArcaneSpells(character);
  const castDivine: boolean = hasDivineSpells(character);
  const castDruid: boolean = hasDruidSpells(character);

  const isThief: boolean = className.includes('THIEF');

  async function handleDownloadClick(e: React.MouseEvent) {
    e.stopPropagation();
    await handleDownloadSingleCharacter(character)
      .catch((error) => {
        console.error(error);
      });
  }
  useEffect(() => {
    if (isReRollError) {
      toggleErrorModal(true);
    }
  }, [isReRollError]);

  async function handleReRoll(e: React.MouseEvent) {
    e.stopPropagation();
    reRollMutation({ index, character, gameType });
  }

  function toggleExpanded() {
    setExpanded(!showExpanded);
  }

  function clickHandler() {
    toggleExpanded();
  }

  function handleKeyDown(e: React.KeyboardEvent<HTMLDivElement>) {
    if (e.key === "Enter" || e.key === " ") {
      toggleExpanded();
    }
  }

  function handleCloseModal() {
    toggleErrorModal(false);
    resetReRoll();
  }

  return (
    <Fragment>
      <div
        className={cn(
          'flex-col es-char-card__wrapper',
          `es-char-card--${count}`,
          {
            'es-char-card--loading': isReRollLoading,
          }
        )}
        style={classColorCssVariables}
        onClick={clickHandler}
        ref={cardRef}
        tabIndex={0}
        onKeyDown={handleKeyDown}>
        <div className={cn('es-char-card flex-1')}>
          <CharacterAvatar {...character} />
          <div className="es-char-card__section es-char-card__profile flex-col">
            <RacePill race={raceName} />
            <ClassPill chClass={className} />
            {classKit !== 'true class' && <ClassPill chClass={classKit} />}
            <div className="es-pill es-pill--main-theme col-span-2 grid-center-self">
              <p>{_.startCase(alignment)}</p>
            </div>
          </div>
          <CharacterStats abilityScores={character.abilityScores} />
          <WeaponSkillTable weaponSkills={character.weaponSkills} />
        </div>
        <div className="es-char-card__section es-char-card__actions">
          <div className="es-char-card__button es-char-card__button--1">
            <button
              className="es-button es-button--primary"
              type="button"
              onClick={handleDownloadClick}
              title="Download single character">
              <svg className="es-icon es-icon--small es-icon--animated">
                <use href="images/sprite.svg#icon-download3"></use>
              </svg>
            </button>
          </div>
          <div className="es-char-card__button es-char-card__button--2">
            <button
              className="es-button es-button--primary"
              type="button"
              onClick={handleReRoll}
              title={'Re Roll Character Stats'}>
              <svg className="es-icon es-icon--small es-icon--animated">
                <use href="images/sprite.svg#icon-dice"></use>
              </svg>
            </button>
          </div>
        </div>
      </div>

      <div
        className={cn('es-card__tray', {
          'es-card__tray--hidden': !showExpanded,
        })}>
        <section className="es-card__tray-section">
          {isThief && <ThiefSkills thiefSkills={character.thiefSkills} />}
          {castArcane && (
            <Fragment>
              <SpellSection
                spells={character.memorizedArcaneSpells}
                id="memorizedArcane"
              />
              <SpellSection
                spells={character.knownArcaneSpells}
                id="knownArcane"
              />
            </Fragment>
          )}

          {castDivine && (
            <SpellSection
              spells={character.memorizedDivineSpells}
              id="memorizedDivine"
            />
          )}
          {castDruid && (
            <SpellSection
              spells={character.memorizedDruidSpells}
              id="memorizedDruid"
            />
          )}
          <SavingThrows savingThrows={character.savingThrows} />
        </section>
      </div>
      {isReRollError && (
        <ESModal
          id="classModal"
          title={'Error'}
          content={'Error rerolling character'}
          isVisible={errorModalOpen}
          closeOnOutsideClick={true}
          handleClose={handleCloseModal}
        />
      )}
    </Fragment>
  );
}
